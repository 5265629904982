import { ObjectId } from 'bson';
import _ from 'underscore';

import { ClusterDescription } from '@packages/types/nds/clusterDescription';
import { DeploymentType } from '@packages/types/nds/clusterEditor';
import {
  AzureDiskType,
  BackingCloudProvider,
  BackingCloudProviderName,
  CloudProvider,
  CrossCloudProviderOptionsView,
  Instance,
  InstanceSize,
  ProviderFeature,
  RegionProviderFeatures,
  UserFacingCloudProvider,
} from '@packages/types/nds/provider';
import { RegionName } from '@packages/types/nds/region';
import {
  AutoIndex,
  AutoScaling,
  DEFAULT_REGION_VIEW,
  HardwareSpec,
  INSTANCE_TIERS_WITHOUT_PROVISIONED_IOPS_SUPPORT,
  ProviderOptions,
  RegionConfig,
  RegionPair,
  RegionView,
  ReplicationSpec,
  ReplicationSpecList,
  VolumeType,
} from '@packages/types/nds/replicationSpec';

import * as clusterDescriptionUtils from '@packages/common/utils/clusterDescription';
import { VOLUME_TYPE } from '@packages/common/schemas/ndsClusterForm';
import { isBackingProvider, toBackingCloudProvider, toCloudProvider } from '@packages/common/utils/cloudProvider';
import deepClone from '@packages/common/utils/deepClone';
import distanceUtils from '@packages/common/utils/distance';
import instanceSizeUtils from '@packages/common/utils/instanceSize';
import replicationSpecListUtils from '@packages/common/utils/replicationSpecList';

const MAX_TOTAL_NODES = 50;
const VALID_ELECTABLE_NODES = [3, 5, 7];
const VALID_ZONE_NAME_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-_ ]*$/;

const AWS_REGIONS_WITHOUT_IO2 = [
  'AF_SOUTH_1',
  'SA_EAST_1',
  'AP_SOUTHEAST_3',
  'EU_WEST_3',
  'AP_NORTHEAST_3',
  'EU_SOUTH_1',
  'US_GOV_WEST_1',
  'US_GOV_EAST_1',
];

export enum NodeType {
  ELECTABLE = 'ELECTABLE',
  READ_ONLY = 'READ_ONLY',
  ANALYTICS = 'ANALYTICS',
}

export enum Tier {
  BASE_TIER = 'Base Tier',
  ANALYTICS_TIER = 'Analytics Tier',
  CLUSTER_TIER = 'Cluster Tier',
}

function assertNever(val: never): never {
  throw new Error(`Unexpected value: ${val as any}`);
}

function getEmptyRegionView(provider: BackingCloudProviderName): RegionView {
  const regionView = { ...DEFAULT_REGION_VIEW };
  regionView.provider = provider;
  return regionView;
}

function getEmptyRegionConfig(
  regionView: RegionView,
  provider: CloudProvider,
  existingHardwareSpec: HardwareSpec,
  existingAutoScaling: AutoScaling,
  existingAnalyticsInstanceSize: InstanceSize,
  existingAnalyticsAutoScaling: AutoScaling | null,
  defaultReplicationSpec: ReplicationSpec
): RegionConfig {
  const defaultHardwareSpec: HardwareSpec = defaultReplicationSpec.regionConfigs[0].electableSpecs;
  const emptyHardwareSpec: HardwareSpec = {
    nodeCount: 0,
    instanceSize: existingHardwareSpec.instanceSize,
    diskIOPS: existingHardwareSpec.diskIOPS,
  };
  switch (provider) {
    case CloudProvider.AWS:
      emptyHardwareSpec.volumeType = existingHardwareSpec.volumeType
        ? existingHardwareSpec.volumeType
        : defaultHardwareSpec.volumeType;
      emptyHardwareSpec.encryptEBSVolume = existingHardwareSpec.encryptEBSVolume
        ? existingHardwareSpec.encryptEBSVolume
        : defaultHardwareSpec.encryptEBSVolume;
      break;
    case CloudProvider.AZURE:
      emptyHardwareSpec.diskType = existingHardwareSpec.diskType
        ? existingHardwareSpec.diskType
        : defaultHardwareSpec.diskType;
      break;
  }

  const analyticsHardwareSpec = deepClone(emptyHardwareSpec);
  analyticsHardwareSpec.instanceSize = existingAnalyticsInstanceSize;
  return {
    regionName: regionView.key,
    cloudProvider: provider,
    autoScaling: existingAutoScaling,
    analyticsAutoScaling: existingAnalyticsAutoScaling,
    priority: 0,
    electableSpecs: deepClone(emptyHardwareSpec),
    readOnlySpecs: deepClone(emptyHardwareSpec),
    analyticsSpecs: analyticsHardwareSpec,
    regionView,
  };
}

function _ensurePrioritiesConsecutive(replicationSpec: ReplicationSpec): ReplicationSpec {
  const regionsWithNonZeroPrioritiesDescending: Array<RegionConfig> = replicationSpec.regionConfigs
    .filter((regionConfig) => regionConfig.priority > 0)
    .sort((a, b) => {
      return b.priority - a.priority;
    });

  let priority = 7;
  regionsWithNonZeroPrioritiesDescending.forEach((regionConfig) => {
    regionConfig.priority = priority;
    priority--;
  });

  return replicationSpec;
}

function _removeEmptyRegions(replicationSpec: ReplicationSpec): ReplicationSpec {
  const updatedReplicationSpec: ReplicationSpec = deepClone(replicationSpec);
  updatedReplicationSpec.regionConfigs = updatedReplicationSpec.regionConfigs.filter(
    (regionConfig) =>
      regionConfig.electableSpecs.nodeCount !== 0 ||
      regionConfig.readOnlySpecs.nodeCount !== 0 ||
      regionConfig.analyticsSpecs.nodeCount !== 0
  );

  return updatedReplicationSpec;
}

function _removeUnspecifiedRegion(replicationSpec: ReplicationSpec): ReplicationSpec {
  const updatedReplicationSpec: ReplicationSpec = deepClone(replicationSpec);

  // select regions with specified region name
  const newRegions = updatedReplicationSpec.regionConfigs.filter((regionConfig) => regionConfig.regionName !== '');

  // make sure we have at least one region with Electable nodes
  const regionsWithElectableNodes = newRegions.filter((regionConfig) => regionConfig.electableSpecs.nodeCount > 0);
  if (regionsWithElectableNodes.length === 0) {
    // add one region with unspecified region name if exists
    const electableNodesWithUnspecifiedRegion = updatedReplicationSpec.regionConfigs.filter((regionConfig) => {
      return regionConfig.regionName === '' && regionConfig.electableSpecs.nodeCount > 0;
    });
    if (electableNodesWithUnspecifiedRegion.length > 0) {
      newRegions.push(electableNodesWithUnspecifiedRegion[0]);
    }
  }

  updatedReplicationSpec.regionConfigs = newRegions.sort((a, b) => b.priority - a.priority);
  return updatedReplicationSpec;
}

export default {
  MAX_PRIORITY: 7,
  MAX_NUM_SHARDS: 70,

  isTenantCluster(replicationSpecs: ReplicationSpecList): boolean {
    return replicationSpecs.length == 1 && replicationSpecs[0].regionConfigs[0].cloudProvider == 'FREE';
  },

  isFlexCluster(replicationSpecs: ReplicationSpecList): boolean {
    const cloudProvider = replicationSpecs[0].regionConfigs[0].cloudProvider;
    return replicationSpecs.length == 1 && cloudProvider === 'FLEX';
  },

  isServerlessCluster(replicationSpecs: ReplicationSpecList): boolean {
    return replicationSpecs.length == 1 && replicationSpecs[0].regionConfigs[0].cloudProvider == 'SERVERLESS';
  },

  getTenantOrServerlessRegionName(replicationSpecs: ReplicationSpecList): RegionName | undefined {
    if (
      replicationSpecs.length == 1 &&
      (this.isTenantCluster(replicationSpecs) ||
        this.isServerlessCluster(replicationSpecs) ||
        this.isFlexCluster(replicationSpecs))
    ) {
      return replicationSpecs[0].regionConfigs[0].regionName;
    } else {
      return undefined;
    }
  },

  getTenantOrServerlessRegionView(replicationSpecs: ReplicationSpecList): RegionView | undefined {
    if (
      replicationSpecs.length == 1 &&
      (this.isTenantCluster(replicationSpecs) ||
        this.isServerlessCluster(replicationSpecs) ||
        this.isFlexCluster(replicationSpecs))
    ) {
      return replicationSpecs[0].regionConfigs[0].regionView;
    } else {
      return undefined;
    }
  },

  getTenantOrServerlessBackingProvider(replicationSpecs: ReplicationSpecList): BackingCloudProvider | undefined {
    const backingProviders = this.getBackingCloudProviders(replicationSpecs);
    if (
      replicationSpecs.length === 1 &&
      (this.isTenantCluster(replicationSpecs) ||
        this.isServerlessCluster(replicationSpecs) ||
        this.isFlexCluster(replicationSpecs))
    ) {
      return backingProviders[0];
    } else {
      return undefined;
    }
  },

  // Get the deployment type of a cluster description
  getDeploymentType(deployment: ClusterDescription): DeploymentType {
    if (this.isServerlessCluster(deployment.replicationSpecList)) {
      return DeploymentType.SERVERLESS;
    } else if (this.isTenantCluster(deployment.replicationSpecList)) {
      return DeploymentType.SHARED;
    } else if (this.isFlexCluster(deployment.replicationSpecList)) {
      return DeploymentType.FLEX;
    } else {
      return DeploymentType.DEDICATED;
    }
  },

  hasAllCorrectlyComposedZoneNames(replicationSpecs: ReplicationSpecList) {
    return replicationSpecs.every((spec) => this.isZoneNameComposedCorrectly(spec.zoneName));
  },

  getTotalElectableNodes(replicationSpec: ReplicationSpec): number {
    return replicationSpec.regionConfigs.reduce((accum, curr) => accum + curr.electableSpecs.nodeCount, 0);
  },

  getTotalAnalyticsNodes(replicationSpec: ReplicationSpec): number {
    return replicationSpec.regionConfigs.reduce((accum, curr) => accum + curr.analyticsSpecs.nodeCount, 0);
  },

  getTotalReadOnlyNodes(replicationSpec: ReplicationSpec): number {
    return replicationSpec.regionConfigs.reduce((accum, curr) => accum + curr.readOnlySpecs.nodeCount, 0);
  },

  getTotalNodes(replicationSpec: ReplicationSpec): number {
    return replicationSpec.regionConfigs.reduce(
      (accum, curr) =>
        accum + curr.electableSpecs.nodeCount + curr.analyticsSpecs.nodeCount + curr.readOnlySpecs.nodeCount,
      0
    );
  },

  getElectableRegions(replicationSpec: ReplicationSpec): Array<RegionConfig> {
    return replicationSpec.regionConfigs.filter((r) => r.electableSpecs.nodeCount > 0);
  },

  getAnalyticsRegions(replicationSpec: ReplicationSpec): Array<RegionConfig> {
    return replicationSpec.regionConfigs.filter((r) => r.analyticsSpecs.nodeCount > 0);
  },

  getReadOnlyRegions(replicationSpec: ReplicationSpec): Array<RegionConfig> {
    return replicationSpec.regionConfigs.filter((r) => r.readOnlySpecs.nodeCount > 0);
  },
  // returns a list of all unique region location names present in a replicationSpecList
  getAllRegionLocations(replicationSpecs: ReplicationSpecList): Array<string> {
    return [
      ...new Set(
        replicationSpecs.flatMap((spec) => spec.regionConfigs).map((regionConfig) => regionConfig.regionView.location)
      ),
    ];
  }, // returns a list of all unique region display names present in a replicationSpecList
  getAllRegionDisplayTexts(replicationSpecs: ReplicationSpecList): Array<string> {
    return [
      ...new Set(
        replicationSpecs
          .flatMap((spec) => spec.regionConfigs)
          .map((regionConfig) => `${regionConfig.regionView.location} (${regionConfig.regionView.name})`)
      ),
    ];
  },
  getTotalShards(replicationSpecs: ReplicationSpecList): number {
    return replicationSpecs.reduce((accum, curr) => accum + curr.numShards, 0);
  },

  getRegionByName(
    replicationSpec: ReplicationSpec,
    cloudProvider: CloudProvider,
    regionName: string
  ): RegionConfig | undefined {
    return replicationSpec.regionConfigs.find(
      (regionConfig) => regionConfig.cloudProvider === cloudProvider && regionConfig.regionName === regionName
    );
  },

  getPreferredRegion(replicationSpec: ReplicationSpec): RegionConfig {
    return replicationSpec.regionConfigs.filter((regionConfig) => regionConfig.priority === this.MAX_PRIORITY)[0];
  },

  getPreferredRegionName(replicationSpec: ReplicationSpec): RegionName {
    return this.getPreferredRegion(replicationSpec).regionName;
  },

  getPreferredRegionProvider(replicationSpec: ReplicationSpec) {
    return this.getPreferredRegion(replicationSpec).regionView.provider;
  },

  getPreferredRegionNameReadable(replicationSpec: ReplicationSpec) {
    return this.getPreferredRegion(replicationSpec).regionView.location;
  },

  getPreferredRegionDisplayText(replicationSpec: ReplicationSpec) {
    const name = this.getPreferredRegion(replicationSpec).regionView.name;
    return `${this.getPreferredRegionNameReadable(replicationSpec)} (${name})`;
  },

  getMostUsedAnalyticsRegion(replicationSpec: ReplicationSpec): RegionConfig {
    return replicationSpec.regionConfigs.reduce((previousRegion, currentRegion) =>
      previousRegion.analyticsSpecs.nodeCount > currentRegion.analyticsSpecs.nodeCount ? previousRegion : currentRegion
    );
  },

  getFirstInstanceSize(replicationSpecs: ReplicationSpecList): InstanceSize {
    const areReplicationSpecsPresent = replicationSpecs && replicationSpecs.length > 0;
    const preferredRegion = areReplicationSpecsPresent ? this.getPreferredRegion(replicationSpecs[0]) : null;
    return preferredRegion && preferredRegion.electableSpecs.instanceSize;
  },

  getBaseInstances(replicationSpecs: ReplicationSpecList): Set<InstanceSize> {
    return new Set(
      replicationSpecs
        .flatMap((spec) => spec.regionConfigs)
        .map((regionConfig) => regionConfig.electableSpecs.instanceSize)
        .sort((a, b) => instanceSizeUtils.compare(a, b))
    );
  },

  getAnalyticsInstances(replicationSpecs: ReplicationSpecList): Set<InstanceSize> {
    return new Set(
      replicationSpecs
        .flatMap((spec) => spec.regionConfigs)
        .map((regionConfig) => regionConfig.analyticsSpecs.instanceSize)
    );
  },

  isAnalyticsAsymmetric(replicationSpecs: ReplicationSpecList): boolean {
    return (
      replicationSpecs
        .flatMap((spec) => spec.regionConfigs)
        .filter((regionConfig) => regionConfig.analyticsSpecs.instanceSize !== regionConfig.electableSpecs.instanceSize)
        .length > 0
    );
  },

  getFirstAnalyticsInstanceSize(replicationSpecs: ReplicationSpecList): InstanceSize {
    const areReplicationSpecsPresent = replicationSpecs && replicationSpecs.length > 0;
    const preferredRegion = areReplicationSpecsPresent ? this.getPreferredRegion(replicationSpecs[0]) : null;
    return preferredRegion && preferredRegion.analyticsSpecs.instanceSize;
  },

  getBaseInstanceSize(replicationSpec: ReplicationSpec): InstanceSize {
    const preferredRegion = replicationSpec ? this.getPreferredRegion(replicationSpec) : null;
    return preferredRegion && preferredRegion.electableSpecs.instanceSize;
  },

  getAnalyticsInstanceSize(replicationSpec: ReplicationSpec): InstanceSize {
    const preferredRegion = replicationSpec ? this.getPreferredRegion(replicationSpec) : null;
    return (
      preferredRegion && preferredRegion.analyticsSpecs.nodeCount > 0 && preferredRegion.analyticsSpecs.instanceSize
    );
  },

  isAnalyticsInstanceTwoOrMoreSizesBelowBaseInstance(
    replicationSpecs: ReplicationSpecList,
    crossCloudProviderOptions: CrossCloudProviderOptionsView
  ): boolean {
    if (
      !replicationSpecs ||
      !replicationSpecs.length ||
      !crossCloudProviderOptions ||
      !Object.keys(crossCloudProviderOptions).length
    ) {
      return false;
    }

    const analyticsInstanceSize: InstanceSize | null = this.getFirstAnalyticsInstanceSize(replicationSpecs);
    const baseInstanceSize: InstanceSize | null = this.getFirstInstanceSize(replicationSpecs);

    // probably unnecessary, but handle null case
    if (analyticsInstanceSize === null || baseInstanceSize === null) {
      return false;
    }

    return this.isAnalyticsInstanceTwoOrMoreSizesBelowBaseInstance_includeInstanceArray(
      analyticsInstanceSize,
      baseInstanceSize,
      Object.values(crossCloudProviderOptions.instanceSizes).map((instance: Instance) => instance.name)
    );
  },

  isAnalyticsInstanceTwoOrMoreSizesBelowBaseInstance_includeInstanceArray(
    analyticsInstanceSize: InstanceSize,
    baseInstanceSize: InstanceSize,
    instanceSizeOptions: Array<InstanceSize>
  ): boolean {
    const instanceTiers: Array<number> = instanceSizeOptions
      .map((instanceSize) => this.instanceSizeTier(instanceSize))
      .sort((a, b) => a - b);
    //project to map
    let sizeToTier: { [instanceTier: number]: number | undefined } = {};
    let currentOrdinal = 0;
    for (const duplicatedSize of instanceTiers) {
      if (!(duplicatedSize in sizeToTier)) {
        sizeToTier[duplicatedSize] = currentOrdinal;
        currentOrdinal += 1;
      }
    }

    const baseOrdinal = sizeToTier[this.instanceSizeTier(baseInstanceSize)];
    const analyticsOrdinal = sizeToTier[this.instanceSizeTier(analyticsInstanceSize)];

    // if tiers found, compare
    if (baseOrdinal !== undefined && analyticsOrdinal !== undefined) {
      return baseOrdinal - analyticsOrdinal >= 2;
    }

    return false;
  },

  // This method does not work for serverless instance sizes.
  instanceSizeTier(instanceSize: InstanceSize): number | undefined {
    const instanceSizeString = instanceSize.toString();
    const InstanceTierGroupName = 'InstanceTier';
    const instanceTierRegex = `(?<InstanceFamily>\\D+)(?<${InstanceTierGroupName}>\\d+)(?<NVMEStatus>_\\D+)?`;
    const instanceMatch = instanceSizeString.match(instanceTierRegex);
    if (!instanceMatch?.groups) {
      return undefined;
    }
    return parseInt(instanceMatch!.groups![InstanceTierGroupName]);
  },

  getPreferredRegionReadable(replicationSpec: ReplicationSpec): string {
    const preferredRegionConfig = this.getPreferredRegion(replicationSpec);
    const region = preferredRegionConfig.regionView;
    const regionDisplayText = `${region.location} (${region.name})`;

    return region && `${UserFacingCloudProvider[region.provider]} / ${regionDisplayText}`;
  },

  getFirstAWSDiskIOPS(replicationSpecs: ReplicationSpecList): number | undefined {
    for (const spec of replicationSpecs) {
      for (const config of spec.regionConfigs) {
        if (config.cloudProvider === CloudProvider.AWS) {
          return config.electableSpecs.diskIOPS;
        }
      }
    }
    return undefined;
  },

  getFirstAWSVolumeType(replicationSpecs: ReplicationSpecList): VolumeType | undefined {
    for (const spec of replicationSpecs) {
      for (const config of spec.regionConfigs) {
        if (config.cloudProvider === CloudProvider.AWS) {
          return config.electableSpecs.volumeType;
        }
      }
    }
    return undefined;
  },

  getFirstAzureDiskIOPS(replicationSpecs: ReplicationSpecList): number | undefined {
    for (const spec of replicationSpecs) {
      for (const config of spec.regionConfigs) {
        if (config.cloudProvider === CloudProvider.AZURE) {
          return config.electableSpecs.diskIOPS;
        }
      }
    }
    return undefined;
  },

  getAllAzureDiskRegions(replicationSpecs: ReplicationSpecList): Set<RegionName> {
    const regionNameSet = new Set<RegionName>();
    for (const spec of replicationSpecs) {
      for (const config of spec.regionConfigs) {
        if (config.cloudProvider === CloudProvider.AZURE) {
          regionNameSet.add(config.regionName);
        }
      }
    }
    return regionNameSet;
  },

  getReplicationSpecListWithStandardIOPSForAwsGp3(
    replicationSpecs: ReplicationSpecList,
    providerOptions: ProviderOptions,
    diskSizeGB: number
  ): ReplicationSpecList {
    let replicationSpecsCopy = deepClone(replicationSpecs);
    const volumeType = replicationSpecListUtils.getFirstAWSVolumeType(replicationSpecsCopy);
    const cloudProviders = replicationSpecListUtils.getBackingCloudProviders(replicationSpecsCopy).map(toCloudProvider);

    if (cloudProviders.includes(CloudProvider.AWS) && volumeType === VolumeType.gp3) {
      const instanceSizeName = replicationSpecListUtils.getFirstInstanceSize(replicationSpecsCopy);
      const awsCurrentInstanceSize = providerOptions[cloudProviders[0]].instanceSizes[instanceSizeName]!;

      const standardDiskIOPS = clusterDescriptionUtils.getStandardIOPSForGP3(awsCurrentInstanceSize, diskSizeGB);
      replicationSpecsCopy = replicationSpecListUtils.updateAWSIOPS(standardDiskIOPS, undefined, replicationSpecsCopy);
    }

    return replicationSpecsCopy;
  },

  getIOPSToDisplay(
    providerOptions: ProviderOptions,
    diskSizeGB: number,
    replicationSpecList: ReplicationSpecList,
    originalCluster?: ClusterDescription
  ) {
    const providers = this.getCloudProviders(replicationSpecList);
    const instanceSizeName = this.getFirstInstanceSize(replicationSpecList);
    if (providers.includes('FREE')) {
      return 0;
    }

    // For cross cloud clusters, we must return the lowest of the three.
    // So for each provider present we calculate a value, and return the lowest

    let awsIOPS: number | undefined;
    let gcpIOPS: number | undefined;
    let azureIOPS: number | undefined;

    if (providers.includes('AWS')) {
      const awsInstanceSize = providerOptions.AWS.instanceSizes[instanceSizeName];
      if (awsInstanceSize.isNVMe) {
        awsIOPS = awsInstanceSize.maxSSDReadIOPS + awsInstanceSize.maxSSDWriteIOPS;
      } else {
        const maxIOPSForInstance = awsInstanceSize.maxIOPS || awsInstanceSize.maxStandardIOPS;
        awsIOPS = Math.min(this.getFirstAWSDiskIOPS(replicationSpecList), maxIOPSForInstance);
      }
    }

    if (providers.includes('GCP')) {
      const gcpInstanceSize = providerOptions.GCP.instanceSizes[instanceSizeName];
      const maxDiskIOPS = gcpInstanceSize.maxReadIOPS;
      const iopsPerGB = gcpInstanceSize.iopsPerGB;
      gcpIOPS = Math.min(diskSizeGB * iopsPerGB, maxDiskIOPS);
    }

    if (providers.includes('AZURE')) {
      const azureInstanceSize = providerOptions.AZURE.instanceSizes[instanceSizeName];
      let maxIOPS: number;
      if (azureInstanceSize.isNVMe) {
        azureIOPS = azureInstanceSize.maxIOPS;
      } else {
        const azureDiskType = this.getAzureDiskType(replicationSpecList, originalCluster);

        if (azureDiskType === AzureDiskType.V2) {
          maxIOPS = clusterDescriptionUtils.getMaxIOPSForAzureSsdV2(azureInstanceSize, diskSizeGB);
          azureIOPS = Math.min(this.getFirstAzureDiskIOPS(replicationSpecList), maxIOPS);
        } else {
          maxIOPS = azureInstanceSize.maxIOPS;
          const diskIOPS = providerOptions.AZURE.diskSizes[diskSizeGB]?.iops;
          azureIOPS = (maxIOPS && Math.min(maxIOPS, diskIOPS)) || diskIOPS;
        }
      }
    }

    // we should filter out any IOPS that are zeroes (ex. GCP M10)
    const iops = [awsIOPS, gcpIOPS, azureIOPS].filter((iops): iops is number => !!iops);
    if (iops.length === 0) {
      return 0;
    }
    return Math.min(...iops);
  },

  clusterRegionsSupportAzureSsdV2(
    replicationSpecList: ReplicationSpecList,
    regionProviderFeatures: RegionProviderFeatures
  ) {
    const allRegionNamesSet: Set<RegionName> = this.getAllAzureDiskRegions(replicationSpecList);
    return (
      allRegionNamesSet.size > 0 &&
      Array.from(allRegionNamesSet).every((regionName) =>
        this.regionSupportsAzureSsdV2(regionName, regionProviderFeatures)
      )
    );
  },

  regionSupportsAzureSsdV2(regionName: RegionName, regionProviderFeatures: RegionProviderFeatures): boolean {
    if (!regionProviderFeatures[CloudProvider.AZURE]?.[regionName]) {
      return false;
    }
    return (
      regionProviderFeatures[CloudProvider.AZURE][regionName].includes(ProviderFeature.IOPS_PROVISIONED) &&
      regionProviderFeatures[CloudProvider.AZURE][regionName].includes(ProviderFeature.DISK_SIZES_PRECISE)
    );
  },

  getAzureDiskType(
    currentReplicationSpecList: ReplicationSpecList,
    originalCluster?: ClusterDescription
  ): AzureDiskType | null {
    // cluster editor Azure disk type
    const formValuesAzureDiskType =
      replicationSpecListUtils.getFirstProviderBaseHardwareSpec(currentReplicationSpecList, CloudProvider.AZURE)
        ?.diskType || null;

    if (!originalCluster) {
      return formValuesAzureDiskType;
    }

    const { uniqueId, replicationSpecList: originalReplicationSpecList } = originalCluster;
    const isEdit = !!uniqueId;
    // existing cluster Azure disk type
    const originalAzureDiskType = !!originalReplicationSpecList
      ? this.getFirstProviderBaseHardwareSpec(originalReplicationSpecList, CloudProvider.AZURE)?.diskType || null
      : null;

    return isEdit ? originalAzureDiskType : formValuesAzureDiskType;
  },

  isDowngradingFromAzurePv2ToPv1(
    originalAzureDiskType: AzureDiskType | null,
    currentReplicationSpecList: ReplicationSpecList
  ): boolean {
    const currentAzureDiskType = this.getAzureDiskType(currentReplicationSpecList);
    // would not be downgrading if there is no longer an Azure region
    if (!currentAzureDiskType) {
      return false;
    }
    let areAllRegionsSelected = true;
    for (const replicationSpec of currentReplicationSpecList) {
      for (const regionConfig of replicationSpec.regionConfigs) {
        if (!regionConfig.regionName) {
          areAllRegionsSelected = false;
        }
      }
    }

    return (
      originalAzureDiskType === AzureDiskType.V2 && areAllRegionsSelected && currentAzureDiskType !== AzureDiskType.V2
    );
  },

  getAzureDiskIOPS(replicationSpecList: ReplicationSpecList): number | undefined {
    return replicationSpecListUtils.getFirstProviderBaseHardwareSpec(replicationSpecList, CloudProvider.AZURE)
      ?.diskIOPS;
  },

  sanitizeReplicationSpecListForAzure(
    clusterDescription: ClusterDescription,
    providerOptions: ProviderOptions,
    regionProviderFeatures: RegionProviderFeatures,
    isAzureSsdV2FeatureEnabled: boolean,
    originalCluster?: ClusterDescription
  ): ReplicationSpecList {
    const { diskSizeGB, replicationSpecList } = clusterDescription;
    const instanceSize = this.getFirstInstanceSize(replicationSpecList);
    const clusterRegionsSupportAzureSsdV2 = this.clusterRegionsSupportAzureSsdV2(
      replicationSpecList,
      regionProviderFeatures
    );
    const azureInstance = providerOptions[CloudProvider.AZURE].instanceSizes[instanceSize]!;
    const azureDiskType = replicationSpecListUtils.getAzureDiskType(replicationSpecList, originalCluster);

    if (clusterRegionsSupportAzureSsdV2 && (isAzureSsdV2FeatureEnabled || azureDiskType === AzureDiskType.V2)) {
      const standardIOPSForAzureSsdV2 = clusterDescriptionUtils.getStandardIOPSForAzureSsdV2(azureInstance, diskSizeGB);
      const azureDiskIOPS = this.getAzureDiskIOPS(replicationSpecList);
      // if disk IOPS is the same as standard IOPS for current disk size, we will unset it and rely on the backend
      // to properly set the standard IOPS, in order to handle the discrepancy between what we display in the UI
      // and what we actually provision
      if (azureDiskIOPS === standardIOPSForAzureSsdV2) {
        return this.updateAzureIOPS(undefined, AzureDiskType.V2, replicationSpecList);
      }
    }

    return replicationSpecList;
  },

  isInAzureSsdV2Mode(
    isEdit: boolean,
    isAzureSsdV2FeatureEnabled: boolean,
    azureDiskType: AzureDiskType | null,
    regionProviderFeatures: RegionProviderFeatures,
    replicationSpecList: ReplicationSpecList
  ): boolean {
    // regionProviderFeatures will always give us information regarding if the current cluster regions support Azure Pv2,
    // regardless of whether the feature flag is enabled (isAzureSsdV2FeatureEnabled)
    const clusterRegionsSupportAzureSsdV2 = this.clusterRegionsSupportAzureSsdV2(
      replicationSpecList,
      regionProviderFeatures
    );

    // scenario 1: if Azure disk type exists and is V1, then not in Azure Pv2 mode
    if (!!azureDiskType && azureDiskType !== AzureDiskType.V2) {
      return false;
    }

    // scenario 2: if current cluster is in V2 supported region and azureDiskType is V2, then we're in Azure Pv2 mode
    if (clusterRegionsSupportAzureSsdV2 && azureDiskType === AzureDiskType.V2) {
      return true;
    }

    // scenario 3: if creating a new cluster and feature flag is turned on and cluster regions support V2, we're in Azure Pv2 mode
    if (!isEdit && isAzureSsdV2FeatureEnabled && clusterRegionsSupportAzureSsdV2) {
      return true;
    }

    return false;
  },

  hasValidElectableNodes(replicationSpec: ReplicationSpec): boolean {
    const totalElectableNodes = this.getTotalElectableNodes(replicationSpec);

    return VALID_ELECTABLE_NODES.indexOf(totalElectableNodes) !== -1;
  },

  hasValidTotalNodes(replicationSpec: ReplicationSpec): boolean {
    const totalNodes = this.getTotalNodes(replicationSpec);
    return totalNodes <= MAX_TOTAL_NODES;
  },

  isSingleSpecValid(replicationSpecs: ReplicationSpecList, replicationSpec: ReplicationSpec) {
    const hasValidZoneName = this.isZoneNameValid(replicationSpecs, replicationSpec.id, replicationSpec.zoneName);
    const hasValidNumberOfNodes =
      this.hasValidElectableNodes(replicationSpec) && this.hasValidTotalNodes(replicationSpec);

    return hasValidZoneName && hasValidNumberOfNodes;
  },

  isHardwareAsymmetric(replicationSpecList: ReplicationSpecList): boolean {
    return this.getFirstAnalyticsInstanceSize(replicationSpecList) !== this.getFirstInstanceSize(replicationSpecList);
  },

  hasValidNumberOfShards(replicationSpecList: ReplicationSpecList) {
    return this.getTotalShards(replicationSpecList) <= this.MAX_NUM_SHARDS;
  },

  isZoneNameComposedCorrectly(name) {
    return name.length <= 20 && VALID_ZONE_NAME_REGEX.test(name);
  },

  isZoneNameValid(replicationSpecs: ReplicationSpecList, replicationSpecId: string, name: string) {
    return (
      this.isZoneNameComposedCorrectly(name) &&
      replicationSpecs.every((spec) => (spec.id !== replicationSpecId ? spec.zoneName !== name : true))
    );
  },

  hasValidZoneNames(replicationSpecList: ReplicationSpecList) {
    const zoneNames: Array<string> = [];
    for (const spec of replicationSpecList) {
      if (zoneNames.includes(spec.zoneName) || !this.isZoneNameComposedCorrectly(spec.zoneName)) {
        return false;
      }
      zoneNames.push(spec.zoneName);
    }

    return true;
  },

  hasValidRegionNames(replicationSpecList: ReplicationSpecList): boolean {
    for (const spec of replicationSpecList) {
      for (const regionConfig of spec.regionConfigs) {
        if (regionConfig.regionName === '') {
          return false;
        }
      }
    }
    return true;
  },

  isValid(replicationSpecList: ReplicationSpecList, clusterType: string) {
    const hasValidNumberOfNodes = replicationSpecList.every((replicationSpec) => {
      return this.hasValidElectableNodes(replicationSpec) && this.hasValidTotalNodes(replicationSpec);
    }, true);
    const hasValidNumberOfShards = this.hasValidNumberOfShards(replicationSpecList);

    let hasValidZoneNames;
    if (clusterType === 'GEOSHARDED') {
      hasValidZoneNames = this.hasValidZoneNames(replicationSpecList);
    } else {
      hasValidZoneNames = true;
    }

    const hasValidRegionNames = this.hasValidRegionNames(replicationSpecList);

    return hasValidZoneNames && hasValidNumberOfNodes && hasValidNumberOfShards && hasValidRegionNames;
  },

  hasMultipleRegions(replicationSpec: ReplicationSpec): boolean {
    return replicationSpec.regionConfigs.length > 1;
  },

  hasMultipleCloudProviders(replicationSpec: ReplicationSpec): boolean {
    return new Set(replicationSpec.regionConfigs.map((regionConfig) => regionConfig.cloudProvider)).size > 1;
  },

  isCrossRegionEnabled(replicationSpec: ReplicationSpec) {
    const hasMultipleRegions = this.hasMultipleRegions(replicationSpec);
    const hasNonstandardElectableNodes = this.getTotalElectableNodes(replicationSpec) !== 3;
    const hasNonstandardTotalNodes = this.getTotalNodes(replicationSpec) !== 3;

    return hasMultipleRegions || hasNonstandardElectableNodes || hasNonstandardTotalNodes;
  },

  // this currently does not work cross-cloud but is only ever used in the private IP mode azure case
  getAllUniqueSpecRegions(replicationSpecs: ReplicationSpecList): Array<string> {
    return [
      ...new Set(
        replicationSpecs.flatMap((replicationSpec) =>
          replicationSpec.regionConfigs.map((regionConfig) => regionConfig.regionName)
        )
      ),
    ];
  },

  getAllUniqueSpecRegionsWithProvider(replicationSpecs: ReplicationSpecList): Array<RegionPair> {
    const regionPairs: Array<RegionPair> = [];

    replicationSpecs
      .flatMap((replicationSpec) => replicationSpec.regionConfigs)
      .forEach((regionConfig) => {
        const cloudProvider = toBackingCloudProvider(regionConfig.cloudProvider);
        const regionPair: RegionPair = { name: regionConfig.regionName, provider: cloudProvider };
        regionPairs.push(regionPair);
      });

    return regionPairs;
  },

  // checks if the specs are equal, excluding ID
  areSpecsEqual(specA: ReplicationSpecList, specB: ReplicationSpecList): boolean {
    function getWithoutID(spec: ReplicationSpec) {
      const { id, ...withoutId } = { ...spec };
      return withoutId;
    }

    const withoutIdA = deepClone(specA).map(getWithoutID);
    const withoutIdB = deepClone(specB).map(getWithoutID);

    return _.isEqual(withoutIdA, withoutIdB);
  },

  nodeConfigurationsEqual(specsA: ReplicationSpecList, specsB: ReplicationSpecList): boolean {
    const summarizeSpecsConfig = (specs) =>
      specs.map((spec) =>
        spec.regionConfigs.map((config) => ({
          cloudProvider: config.cloudProvider,
          regionName: config.regionName,
          priority: config.priority,
          electableNodes: config.electableSpecs.nodeCount,
          analyticsNodes: config.analyticsSpecs.nodeCount,
          readOnlyNodes: config.readOnlySpecs.nodeCount,
        }))
      );
    return _.isEqual(summarizeSpecsConfig(specsA), summarizeSpecsConfig(specsB));
  },

  isConfiguredForGlobalReads(replicationSpecList: ReplicationSpecList, replicationSpec: ReplicationSpec): boolean {
    // check if replicationSpec has a read-only node in every zone's preferred region
    return replicationSpecList.every((rs) => {
      if (rs.id === replicationSpec.id) return true;
      const preferredRegion: RegionConfig = this.getPreferredRegion(rs);
      const preferredRegionIndex: number = replicationSpec.regionConfigs.findIndex(
        (regionConfig) =>
          preferredRegion.regionName === regionConfig.regionName &&
          preferredRegion.cloudProvider === regionConfig.cloudProvider
      );

      return (
        preferredRegionIndex !== -1 &&
        (replicationSpec.regionConfigs[preferredRegionIndex].readOnlySpecs.nodeCount > 0 ||
          replicationSpec.regionConfigs[preferredRegionIndex].priority === this.MAX_PRIORITY)
      );
    });
  },

  areAllConfiguredForGlobalReads(replicationSpecList: ReplicationSpecList): boolean {
    return replicationSpecList.every((spec) => this.isConfiguredForGlobalReads(replicationSpecList, spec));
  },

  configureGlobalReads(
    replicationSpecList: ReplicationSpecList,
    defaultReplicationSpecByProvider: Record<BackingCloudProvider, ReplicationSpec>
  ): ReplicationSpecList {
    const updatedReplicationSpecList: ReplicationSpecList = deepClone(replicationSpecList);
    const preferredRegions: Array<RegionConfig> = this.getPreferredRegions(updatedReplicationSpecList);

    updatedReplicationSpecList.forEach((replicationSpec: ReplicationSpec) => {
      preferredRegions.forEach((regionConfig: RegionConfig) => {
        const regionIndex: number = replicationSpec.regionConfigs.findIndex(
          (rc) => rc.cloudProvider == regionConfig.cloudProvider && rc.regionName === regionConfig.regionName
        );
        const isPreferredOrHasReadOnlyNodes: boolean =
          regionIndex !== -1 &&
          (replicationSpec.regionConfigs[regionIndex].priority === this.MAX_PRIORITY ||
            replicationSpec.regionConfigs[regionIndex].readOnlySpecs.nodeCount > 0);
        if (!isPreferredOrHasReadOnlyNodes) {
          if (regionIndex === -1) {
            const newRegionConfig = getEmptyRegionConfig(
              regionConfig.regionView,
              regionConfig.cloudProvider,
              regionConfig.electableSpecs,
              regionConfig.autoScaling,
              regionConfig.analyticsSpecs.instanceSize,
              regionConfig.analyticsAutoScaling,
              defaultReplicationSpecByProvider[regionConfig.cloudProvider]
            );
            newRegionConfig.readOnlySpecs.nodeCount = 1;
            replicationSpec.regionConfigs.push(newRegionConfig);
          } else {
            replicationSpec.regionConfigs[regionIndex].readOnlySpecs.nodeCount = 1;
          }
        }
      });
    });

    return updatedReplicationSpecList;
  },

  hasBlacklistedRegion(replicationSpecs: ReplicationSpecList, instanceSize): boolean {
    return replicationSpecs.some((rs) => {
      const regionConfigs = rs.regionConfigs;
      return regionConfigs.some((regionConfig) => {
        const provider = regionConfig.regionView.provider;
        const regionName = regionConfig.regionName;

        const availableRegion = instanceSize.availableRegions.find(
          (r) => r.regionName === regionName && r.providerName === provider
        );

        return availableRegion && availableRegion.isBlacklisted;
      });
    });
  },

  hasInstanceFamily(replicationSpecs: ReplicationSpecList, instanceSize: Instance): boolean {
    return replicationSpecs.some((rs) => {
      return rs.regionConfigs.some((regionConfig) => {
        const provider = regionConfig.regionView.provider;

        const availableRegion = instanceSize.availableRegions.find(
          (r) => r.regionName === regionConfig.regionName && r.providerName === provider
        );

        return availableRegion && availableRegion.availableFamilies.length > 0;
      });
    });
  },

  hasCommonInstanceFamily(replicationSpecs: ReplicationSpecList, instanceSize: Instance): boolean {
    const availableRegions = instanceSize.availableRegions;
    const instanceFamilyCounts = {};
    let numRegionConfigs = 0;

    if (this.getBackingCloudProviders(replicationSpecs).length > 1) {
      return true;
    }

    replicationSpecs.forEach((rs) => {
      rs.regionConfigs
        .filter((regionConfig) => regionConfig.regionName !== '')
        .forEach((regionConfig) => {
          numRegionConfigs++;
          const provider = regionConfig.electableSpecs.backingProvider
            ? regionConfig.electableSpecs.backingProvider
            : regionConfig.cloudProvider;
          const region = availableRegions.find(
            (r) => r.regionName === regionConfig.regionName && r.providerName === provider
          );

          if (region) {
            const regionFamilies = region.availableFamilies;
            regionFamilies.forEach((regionFamily) => {
              if (regionFamily in instanceFamilyCounts) {
                instanceFamilyCounts[regionFamily]++;
              } else {
                instanceFamilyCounts[regionFamily] = 1;
              }
            });
          }
        });
    });

    return Object.values(instanceFamilyCounts).some((c) => c === numRegionConfigs);
  },

  getMostCommonPreferredBackingCloudProvider(replicationSpecList: ReplicationSpecList): BackingCloudProvider {
    const providerCounts = replicationSpecList
      .map((replicationSpec) => {
        const preferredRegion = this.getPreferredRegion(replicationSpec);
        if (preferredRegion.cloudProvider == CloudProvider.FREE) {
          return preferredRegion.electableSpecs.backingProvider;
        }
        return preferredRegion.cloudProvider;
      })
      .reduce((counts, provider) => {
        if (provider in counts) {
          counts[provider]++;
        } else {
          counts[provider] = 1;
        }
        return counts;
      }, {});
    return BackingCloudProvider[_.max(Object.keys(providerCounts), (p) => providerCounts[p])];
  },

  // For free clusters, actually returns the underlying cluster
  getBackingCloudProviders(replicationSpecList: ReplicationSpecList): Array<BackingCloudProvider> {
    const providers: Array<BackingCloudProvider> = [];
    replicationSpecList
      .flatMap((spec) => spec.regionConfigs)
      .sort((a, b) => {
        return b.priority - a.priority;
      })
      .forEach((regionConfig) => {
        const topLevelProvider = regionConfig.cloudProvider;
        let provider: BackingCloudProvider;
        if (isBackingProvider(topLevelProvider)) {
          provider = topLevelProvider;
        } else {
          provider = regionConfig.electableSpecs.backingProvider!;
        }
        if (providers.indexOf(provider) === -1) {
          providers.push(provider);
        }
      });

    return providers;
  },

  getBackingCloudProvidersReadable(replicationSpecList: ReplicationSpecList): string {
    const providers: Array<UserFacingCloudProvider> = this.getBackingCloudProviders(replicationSpecList).map(
      (provider) => UserFacingCloudProvider[provider]
    );
    const numProviders = providers.length;
    if (numProviders === 0) {
      return '';
    }
    if (numProviders === 1) {
      return providers[0];
    }

    return `${providers.slice(0, numProviders - 1).join(', ')} and ${providers.slice(numProviders - 1)}`;
  },

  // For free clusters, actually returns FREE
  getCloudProviders(replicationSpecList: ReplicationSpecList): Array<CloudProvider> {
    const providers: Array<CloudProvider> = [];

    replicationSpecList
      .flatMap((spec) => spec.regionConfigs)
      .sort((a, b) => {
        return b.priority - a.priority;
      })
      .forEach((regionConfig) => {
        if (!providers.includes(regionConfig.cloudProvider)) {
          providers.push(regionConfig.cloudProvider);
        }
      });

    return providers;
  },

  convertToSingleRegion(replicationSpec: ReplicationSpec): ReplicationSpec {
    const preferredRegionConfig = this.getPreferredRegion(replicationSpec);
    const newRegionConfig = deepClone(preferredRegionConfig);
    newRegionConfig.electableSpecs.nodeCount = 3;
    newRegionConfig.readOnlySpecs.nodeCount = 0;
    newRegionConfig.analyticsSpecs.nodeCount = 0;
    const newReplicationSpec = deepClone(replicationSpec);
    newReplicationSpec.regionConfigs = [];
    newReplicationSpec.regionConfigs.push(newRegionConfig);
    return newReplicationSpec;
  },

  handlesPartialRegionOutage(replicationSpec: ReplicationSpec) {
    // true if >= 3 nodes in a recommended region
    const threeInRecommended = replicationSpec.regionConfigs.some((config) => {
      return config.regionView.isRecommended && config.electableSpecs.nodeCount >= 3;
    });

    // true if >= 3 nodes across at least 2 regions
    const totalElectableNodes = this.getTotalElectableNodes(replicationSpec);
    const totalElectableRegions = replicationSpec.regionConfigs.filter(
      (config) => config.electableSpecs.nodeCount > 0
    ).length;
    const threeAcrossMultiple = totalElectableNodes >= 3 && totalElectableRegions >= 2;

    return threeInRecommended || threeAcrossMultiple;
  },

  handlesFullRegionOutage(replicationSpec: ReplicationSpec) {
    // handles full region outage if any given region's outage leaves a majority of electable nodes
    const totalElectableNodes = this.getTotalElectableNodes(replicationSpec);
    return replicationSpec.regionConfigs.every((config) => {
      return totalElectableNodes - config.electableSpecs.nodeCount >= totalElectableNodes / 2;
    });
  },

  handlesCloudProviderOutage(replicationSpec: ReplicationSpec) {
    // handles full cloud provider outage if any given provider's outage leaves a majority of electable nodes
    const totalElectableNodes = this.getTotalElectableNodes(replicationSpec);
    let totalAWSElectableNodes = 0;
    let totalAzureElectableNodes = 0;
    let totalGCPElectableNodes = 0;
    replicationSpec.regionConfigs.forEach((config) => {
      switch (config.cloudProvider) {
        case CloudProvider.AWS:
          totalAWSElectableNodes += config.electableSpecs.nodeCount;
          break;
        case CloudProvider.AZURE:
          totalAzureElectableNodes += config.electableSpecs.nodeCount;
          break;
        case CloudProvider.GCP:
          totalGCPElectableNodes += config.electableSpecs.nodeCount;
          break;
      }
    });
    return [totalAWSElectableNodes, totalAzureElectableNodes, totalGCPElectableNodes].every(
      (providerElectableNodeCount) => {
        return totalElectableNodes - providerElectableNodeCount >= totalElectableNodes / 2;
      }
    );
  },

  calculateDistanceBetweenRegions(regionConfig1: RegionConfig, regionConfig2: RegionConfig): number | undefined {
    const lat1: number | undefined = regionConfig1.regionView.latitude;
    const lat2: number | undefined = regionConfig2.regionView.latitude;
    const lon1: number | undefined = regionConfig1.regionView.longitude;
    const lon2: number | undefined = regionConfig2.regionView.longitude;
    if (lat1 !== undefined && lat2 !== undefined && lon1 !== undefined && lon2 !== undefined) {
      return distanceUtils.calculateDistanceBetweenPointsInKM(lat1, lon1, lat2, lon2);
    }
  },

  hasLongElections(replicationSpec: ReplicationSpec): boolean {
    const maxDistanceInKM = 4000;
    const votingRegions: Array<RegionConfig> = replicationSpec.regionConfigs
      .filter((regionSpec: RegionConfig) => regionSpec.electableSpecs.nodeCount > 0)
      .filter((regionConfig: RegionConfig) => regionConfig.regionName !== '');

    if (votingRegions.length <= 1) {
      return false;
    }

    const allRegionsHaveCoordinates = votingRegions.every(
      (region: RegionConfig) => region.regionView.latitude !== undefined && region.regionView.longitude !== undefined
    );

    if (allRegionsHaveCoordinates) {
      return votingRegions.some((region1) => {
        return votingRegions.some(
          (region2) => this.calculateDistanceBetweenRegions(region1, region2) > maxDistanceInKM
        );
      });
    } else {
      return this.hasMultipleContinents(replicationSpec);
    }
  },

  hasMultipleContinents(replicationSpec: ReplicationSpec): boolean {
    const votingContinents = replicationSpec.regionConfigs
      .filter((regionSpec: RegionConfig) => regionSpec.electableSpecs.nodeCount > 0)
      .filter((regionConfig: RegionConfig) => regionConfig.regionName !== '')
      .reduce((memo: Array<String>, regionConfig: RegionConfig) => {
        const continent = regionConfig.regionView.continent;
        memo.indexOf(continent) === -1 && memo.push(continent);
        return memo;
      }, []);

    return votingContinents.length > 1;
  },

  getNextPriority(replicationSpec: ReplicationSpec): number {
    const lowestPriority: number = Math.min(
      ...replicationSpec.regionConfigs.map((regionConfig) => regionConfig.priority).filter((p) => p > 0)
    );
    return lowestPriority - 1;
  },

  getUnusedElectableRegions(replicationSpec: ReplicationSpec, regions: Array<RegionView>): Array<RegionView> {
    const usedElectableRegions = this.getElectableRegions(replicationSpec);
    return regions.filter(
      (region) =>
        usedElectableRegions.findIndex(
          (usedRegion) => region.provider === usedRegion.cloudProvider && region.key === usedRegion.regionName
        ) === -1
    );
  },

  changeProvider(
    replicationSpec: ReplicationSpec,
    newProvider: CloudProvider,
    previousRegion: RegionPair,
    defaultReplicationSpec: ReplicationSpec,
    nodeType: NodeType
  ): ReplicationSpec {
    return this.changeRegion(
      replicationSpec,
      [], // don't need regions, just adding a blank region
      {
        name: '',
        provider: newProvider,
      },
      previousRegion,
      defaultReplicationSpec,
      nodeType
    );
  },

  getUnusedReadOnlyRegions(replicationSpec: ReplicationSpec, regions: Array<RegionView>): Array<RegionView> {
    const usedReadOnlyRegions = this.getReadOnlyRegions(replicationSpec);

    return regions.filter(
      (r) =>
        usedReadOnlyRegions.findIndex(
          (usedRegion) => r.provider == usedRegion.cloudProvider && r.key === usedRegion.regionName
        ) === -1
    );
  },

  getUnusedAnalyticsRegions(replicationSpec: ReplicationSpec, regions: Array<RegionView>): Array<RegionView> {
    const usedAnalyticsRegions = this.getAnalyticsRegions(replicationSpec);

    return regions.filter(
      (r) =>
        usedAnalyticsRegions.findIndex(
          (usedRegion) => r.provider == usedRegion.cloudProvider && r.key === usedRegion.regionName
        ) === -1
    );
  },

  getUnusedPreferredRegions(replicationSpecList: ReplicationSpecList, regions: Array<RegionView>): Array<RegionView> {
    const unusedPreferredRegions: Array<RegionConfig> = this.getPreferredRegions(replicationSpecList);
    return regions.filter(
      (regionView: RegionView) =>
        unusedPreferredRegions.findIndex(
          (region: RegionConfig) => region.cloudProvider === regionView.provider && region.regionName === regionView.key
        ) === -1
    );
  },

  // used if !crossCloudEnabled to conform to old behavior
  addNewSelectedRegion(
    replicationSpec: ReplicationSpec,
    provider: CloudProvider,
    availableRegions: Array<RegionView>,
    defaultReplicationSpec: ReplicationSpec,
    nodeType: NodeType,
    isEdit: boolean
  ): ReplicationSpec {
    const updatedReplicationSpec: ReplicationSpec = deepClone(replicationSpec);

    let region: RegionView;
    switch (nodeType) {
      case NodeType.ELECTABLE: {
        region = this.getUnusedElectableRegions(replicationSpec, availableRegions)[0];
        break;
      }
      case NodeType.READ_ONLY: {
        const maxPriorityElectableRegion = this.getPreferredRegionName(replicationSpec);
        region = this.getUnusedReadOnlyRegions(replicationSpec, availableRegions).sort((r1, r2) => {
          // order the maxPriorityElectableRegion to last place
          return Number(r1.key === maxPriorityElectableRegion) - Number(r2.key === maxPriorityElectableRegion);
        })[0];
        break;
      }
      case NodeType.ANALYTICS: {
        const usedElectableRegionsPriorities = this.getElectableRegions(replicationSpec).reduce((acc, config) => {
          acc[config.regionName] = config.priority;
          return acc;
        }, {});
        region = this.getUnusedAnalyticsRegions(replicationSpec, availableRegions).sort((r1, r2) => {
          return (usedElectableRegionsPriorities[r2.key] || 0) - (usedElectableRegionsPriorities[r1.key] || 0);
        })[0];
        break;
      }
      default:
        assertNever(nodeType); // exhaustiveness check
    }

    const priority: number = nodeType === NodeType.ELECTABLE ? this.getNextPriority(replicationSpec) : 0;

    const index: number = replicationSpec.regionConfigs.findIndex(
      (regionConfig) => regionConfig.cloudProvider === provider && regionConfig.regionName === region.key
    );

    // if the region exists, we update the existing RegionConfig, otherwise, we make a new RegionConfig
    const newRegionConfig: RegionConfig =
      index !== -1
        ? deepClone(replicationSpec.regionConfigs[index])
        : getEmptyRegionConfig(
            region,
            provider,
            replicationSpec.regionConfigs[0].electableSpecs,
            replicationSpec.regionConfigs[0].autoScaling,
            replicationSpec.regionConfigs[0].analyticsSpecs.instanceSize,
            replicationSpec.regionConfigs[0].analyticsAutoScaling,
            defaultReplicationSpec
          );

    switch (nodeType) {
      case NodeType.ELECTABLE:
        newRegionConfig.electableSpecs.nodeCount = 2;
        newRegionConfig.priority = priority;
        break;
      case NodeType.READ_ONLY:
        newRegionConfig.readOnlySpecs.nodeCount = 1;
        break;
      case NodeType.ANALYTICS:
        newRegionConfig.analyticsSpecs.nodeCount = isEdit ? 1 : 2;
        break;
      default:
        assertNever(nodeType); // exhaustiveness check
    }

    if (index !== -1) {
      updatedReplicationSpec.regionConfigs.splice(index, 1, newRegionConfig);
    } else {
      updatedReplicationSpec.regionConfigs.push(newRegionConfig);
    }

    return updatedReplicationSpec;
  },

  addNewNode(
    replicationSpec: ReplicationSpec,
    provider: BackingCloudProvider,
    defaultReplicationSpec: ReplicationSpec,
    isPrivateIpModeAzure: boolean,
    nodeType: NodeType,
    isEdit: boolean
  ): ReplicationSpec {
    const updatedReplicationSpec: ReplicationSpec = deepClone(replicationSpec);

    if (nodeType === NodeType.ANALYTICS || (nodeType !== NodeType.ELECTABLE && isPrivateIpModeAzure)) {
      const usedElectableRegions = this.getElectableRegions(updatedReplicationSpec).sort((a, b) => {
        return b.priority - a.priority;
      });

      let i = 0;
      for (i; i < usedElectableRegions.length; i++) {
        const regionConfig = usedElectableRegions[i];
        switch (nodeType) {
          case NodeType.READ_ONLY:
            if (regionConfig.readOnlySpecs.nodeCount === 0) {
              regionConfig.readOnlySpecs.nodeCount = 1;
              return updatedReplicationSpec;
            }
            break;
          case NodeType.ANALYTICS:
            if (regionConfig.analyticsSpecs.nodeCount === 0) {
              regionConfig.analyticsSpecs.nodeCount = isEdit ? 1 : 2;
              return updatedReplicationSpec;
            }
            break;
        }
      }
    }

    const priority: number = nodeType === NodeType.ELECTABLE ? this.getNextPriority(replicationSpec) : 0;

    const emptyRegionConfig: RegionConfig = getEmptyRegionConfig(
      getEmptyRegionView(provider),
      toCloudProvider(provider),
      replicationSpec.regionConfigs[0].electableSpecs,
      replicationSpec.regionConfigs[0].autoScaling,
      replicationSpec.regionConfigs[0].analyticsSpecs.instanceSize,
      replicationSpec.regionConfigs[0].analyticsAutoScaling,
      defaultReplicationSpec
    );
    const newRegionConfig: RegionConfig = this.matchHardwareSpecCloudProviderFields(
      [replicationSpec],
      emptyRegionConfig
    );

    switch (nodeType) {
      case NodeType.ELECTABLE:
        newRegionConfig.electableSpecs.nodeCount = 2;
        newRegionConfig.priority = priority;
        break;
      case NodeType.READ_ONLY:
        newRegionConfig.readOnlySpecs.nodeCount = 1;
        break;
      case NodeType.ANALYTICS:
        newRegionConfig.analyticsSpecs.nodeCount = isEdit ? 1 : 2;
        break;
    }

    updatedReplicationSpec.regionConfigs.push(newRegionConfig);

    return updatedReplicationSpec;
  },

  /**
   * Add new Analytics or Read Only node to the region that has Electable nodes.
   */
  addNewNodeToRegionWithElectableNodes(
    replicationSpec: ReplicationSpec,
    nodeType: NodeType,
    isEdit: boolean
  ): ReplicationSpec {
    const updatedReplicationSpec: ReplicationSpec = deepClone(replicationSpec);
    const electableRegions = this.getElectableRegions(updatedReplicationSpec).sort((a, b) => b.priority - a.priority);

    for (const regionConfig of electableRegions) {
      switch (nodeType) {
        case NodeType.READ_ONLY:
          if (regionConfig.readOnlySpecs.nodeCount === 0) {
            regionConfig.readOnlySpecs.nodeCount = 1;
            return updatedReplicationSpec;
          }
          break;
        case NodeType.ANALYTICS:
          if (regionConfig.analyticsSpecs.nodeCount === 0) {
            regionConfig.analyticsSpecs.nodeCount = isEdit ? 1 : 2;
            return updatedReplicationSpec;
          }
          break;
      }
    }

    return updatedReplicationSpec;
  },

  removeRegion(replicationSpec: ReplicationSpec, nodeType: NodeType, regionConfigIndex: number): ReplicationSpec {
    if (regionConfigIndex >= replicationSpec.regionConfigs.length) {
      return replicationSpec;
    }

    const updatedReplicationSpec: ReplicationSpec = deepClone(replicationSpec);
    const newRegionConfig = deepClone(updatedReplicationSpec.regionConfigs[regionConfigIndex]);

    switch (nodeType) {
      case NodeType.ELECTABLE:
        newRegionConfig.electableSpecs.nodeCount = 0;
        newRegionConfig.priority = 0;
        break;
      case NodeType.READ_ONLY:
        newRegionConfig.readOnlySpecs.nodeCount = 0;
        break;
      case NodeType.ANALYTICS:
        newRegionConfig.analyticsSpecs.nodeCount = 0;
        break;
    }

    updatedReplicationSpec.regionConfigs.splice(regionConfigIndex, 1, newRegionConfig);

    return _ensurePrioritiesConsecutive(_removeEmptyRegions(updatedReplicationSpec));
  },

  removeNonPreferredRegions(replicationSpec: ReplicationSpec): ReplicationSpec {
    const updatedReplicationSpec: ReplicationSpec = deepClone(replicationSpec);
    const preferredRegion = this.getPreferredRegion(replicationSpec);

    updatedReplicationSpec.regionConfigs.forEach((regionConfig) => {
      if (
        !(
          regionConfig.cloudProvider === preferredRegion.cloudProvider &&
          regionConfig.regionName === preferredRegion.regionName
        )
      ) {
        regionConfig.electableSpecs.nodeCount = 0;
        regionConfig.priority = 0;
        regionConfig.readOnlySpecs.nodeCount = 0;
        regionConfig.analyticsSpecs.nodeCount = 0;
      }
    });

    return _ensurePrioritiesConsecutive(_removeEmptyRegions(updatedReplicationSpec));
  },

  getNumAnalyticsNodesInRegion(replicationSpec: ReplicationSpec, regionConfigIndex: number): number {
    return replicationSpec.regionConfigs[regionConfigIndex].analyticsSpecs.nodeCount;
  },

  changeRegion(
    replicationSpec: ReplicationSpec,
    regions: Array<RegionView>,
    newRegion: RegionPair,
    previousRegion: RegionPair,
    defaultReplicationSpec: ReplicationSpec,
    nodeType: NodeType
  ) {
    const updatedReplicationSpec: ReplicationSpec = deepClone(replicationSpec);

    let newRegionView: RegionView | undefined = regions.find(
      (region: RegionView) => region.key === newRegion.name && region.provider === newRegion.provider
    );

    let matchFunction: (RegionConfig) => boolean;
    if (previousRegion.name !== '') {
      matchFunction = (regionConfig: RegionConfig) =>
        regionConfig.cloudProvider === toCloudProvider(previousRegion.provider) &&
        regionConfig.regionName === previousRegion.name;
    } else {
      // if potentially multiple unselected regions exist of multiple node types, makes sure that the one being changed
      // is of the correct node type
      switch (nodeType) {
        case NodeType.ELECTABLE:
          matchFunction = (regionConfig: RegionConfig) =>
            regionConfig.cloudProvider === toCloudProvider(previousRegion.provider) &&
            regionConfig.regionName === previousRegion.name &&
            regionConfig.electableSpecs.nodeCount > 0;
          break;
        case NodeType.READ_ONLY:
          matchFunction = (regionConfig: RegionConfig) =>
            regionConfig.cloudProvider === toCloudProvider(previousRegion.provider) &&
            regionConfig.regionName === previousRegion.name &&
            regionConfig.readOnlySpecs.nodeCount > 0;
          break;
        case NodeType.ANALYTICS:
          matchFunction = (regionConfig: RegionConfig) =>
            regionConfig.cloudProvider === toCloudProvider(previousRegion.provider) &&
            regionConfig.regionName === previousRegion.name &&
            regionConfig.analyticsSpecs.nodeCount > 0;
          break;
      }
    }

    const previousRegionConfigIndex: number = updatedReplicationSpec.regionConfigs.findIndex(matchFunction);
    const previousRegionConfig: RegionConfig = updatedReplicationSpec.regionConfigs[previousRegionConfigIndex];

    if (!newRegionView) {
      // the only case where this default view should happen is if we just switched cloud providers and the region
      // defaults to undefined the region view does not matter at this point, as another call to changeRegion will be
      // required to set it
      newRegionView = getEmptyRegionView(newRegion.provider as BackingCloudProviderName);
    }

    // if we already have a RegionConfig for the region we are changing to, we add to the existing RegionConfig,
    // otherwise we create a new one
    let newRegionConfig: RegionConfig | undefined = updatedReplicationSpec.regionConfigs.find(
      (regionConfig) =>
        regionConfig.cloudProvider === toCloudProvider(newRegion.provider) &&
        regionConfig.regionName === newRegion.name &&
        regionConfig.regionName !== ''
    );

    if (!newRegionConfig) {
      newRegionConfig = getEmptyRegionConfig(
        newRegionView,
        toCloudProvider(newRegion.provider),
        replicationSpec.regionConfigs[0].electableSpecs,
        replicationSpec.regionConfigs[0].autoScaling,
        replicationSpec.regionConfigs[0].analyticsSpecs.instanceSize,
        replicationSpec.regionConfigs[0].analyticsAutoScaling,
        defaultReplicationSpec
      );
      updatedReplicationSpec.regionConfigs.splice(previousRegionConfigIndex + 1, 0, newRegionConfig);
    }

    const isProviderChanged: boolean = newRegion.provider !== previousRegion.provider;

    // move values from old -> new region and zero out relevant old values
    switch (nodeType) {
      case NodeType.ELECTABLE:
        if (isProviderChanged) {
          // we don't want to accidentally copy over provider-specific fields
          newRegionConfig.electableSpecs = {
            ...defaultReplicationSpec.regionConfigs[0].electableSpecs,
            nodeCount: deepClone(previousRegionConfig).electableSpecs.nodeCount,
            instanceSize: deepClone(previousRegionConfig).electableSpecs.instanceSize,
          };
        } else {
          newRegionConfig.electableSpecs = deepClone(previousRegionConfig.electableSpecs);
        }
        newRegionConfig.priority = previousRegionConfig.priority;
        previousRegionConfig.electableSpecs.nodeCount = 0;
        previousRegionConfig.priority = 0;
        break;
      case NodeType.READ_ONLY:
        if (isProviderChanged) {
          newRegionConfig.readOnlySpecs = {
            ...defaultReplicationSpec.regionConfigs[0].readOnlySpecs,
            nodeCount: deepClone(previousRegionConfig).readOnlySpecs.nodeCount,
            instanceSize: deepClone(previousRegionConfig).readOnlySpecs.instanceSize,
          };
        } else {
          newRegionConfig.readOnlySpecs = deepClone(previousRegionConfig.readOnlySpecs);
        }
        previousRegionConfig.readOnlySpecs.nodeCount = 0;
        break;
      case NodeType.ANALYTICS:
        if (isProviderChanged) {
          newRegionConfig.analyticsSpecs = {
            ...defaultReplicationSpec.regionConfigs[0].analyticsSpecs,
            nodeCount: deepClone(previousRegionConfig).analyticsSpecs.nodeCount,
            instanceSize: deepClone(previousRegionConfig).analyticsSpecs.instanceSize,
          };
        } else {
          newRegionConfig.analyticsSpecs = deepClone(previousRegionConfig.analyticsSpecs);
        }
        previousRegionConfig.analyticsSpecs.nodeCount = 0;
        break;
    }

    const oldReplicationSpecProviders: Array<CloudProvider> = this.getCloudProviders([replicationSpec]);
    const newRegionConfigProvider: CloudProvider = CloudProvider[newRegion.provider];

    if (oldReplicationSpecProviders.includes(newRegionConfigProvider)) {
      const newRegionConfigWithCloudProviderFieldsMatched = this.matchHardwareSpecCloudProviderFields(
        [replicationSpec],
        newRegionConfig
      );

      const newRegionConfigIndex = updatedReplicationSpec.regionConfigs.findIndex(
        (regionConfig: RegionConfig) => regionConfig === newRegionConfig
      );

      updatedReplicationSpec.regionConfigs.splice(
        newRegionConfigIndex,
        1,
        newRegionConfigWithCloudProviderFieldsMatched
      );
    }

    updatedReplicationSpec.regionConfigs = updatedReplicationSpec.regionConfigs.sort((a, b) => {
      return b.priority - a.priority;
    });

    return _removeEmptyRegions(updatedReplicationSpec);
  },

  changeNodes(replicationSpec: ReplicationSpec, numNodes: number, nodeType: NodeType, regionConfigIndex: number) {
    if (regionConfigIndex >= replicationSpec.regionConfigs.length) {
      return replicationSpec;
    }

    const updatedReplicationSpec: ReplicationSpec = deepClone(replicationSpec);
    const regionConfigToUpdate: RegionConfig = updatedReplicationSpec.regionConfigs[regionConfigIndex];

    if (regionConfigToUpdate) {
      switch (nodeType) {
        case NodeType.ELECTABLE:
          regionConfigToUpdate.electableSpecs.nodeCount = numNodes;
          break;
        case NodeType.READ_ONLY:
          regionConfigToUpdate.readOnlySpecs.nodeCount = numNodes;
          break;
        case NodeType.ANALYTICS:
          regionConfigToUpdate.analyticsSpecs.nodeCount = numNodes;
          break;
      }
    }
    return updatedReplicationSpec;
  },

  arePreferredRegionsAndZoneNamesEqual(specsA: ReplicationSpecList, specsB: ReplicationSpecList) {
    const preferredRegionsA = specsA.map((spec) => this.getPreferredRegion(spec).regionName);
    const zoneNamesA = specsA.map((spec) => spec.zoneName);

    const preferredRegionsB = specsB.map((spec) => this.getPreferredRegion(spec).regionName);
    const zoneNamesB = specsB.map((spec) => spec.zoneName);

    return _.isEqual(preferredRegionsA, preferredRegionsB) && _.isEqual(zoneNamesA, zoneNamesB);
  },

  getPreferredRegions(replicationSpecList: ReplicationSpecList): Array<RegionConfig> {
    return replicationSpecList.map((spec: ReplicationSpec) => this.getPreferredRegion(spec));
  },

  getPreferredRegionOfUnusedContinents(
    replicationSpecList: ReplicationSpecList,
    regions: Array<RegionView>
  ): Array<RegionView> {
    const usedPreferredContinents = this.getPreferredRegions(replicationSpecList).map(
      (config: RegionConfig) => config.regionView.continent
    );
    return regions.filter((r: RegionView) => usedPreferredContinents.indexOf(r.continent) === -1);
  },

  addNewReplicationSpec(
    replicationSpecList: ReplicationSpecList,
    availableRegions: Array<RegionView>,
    provider: CloudProvider,
    random: number,
    defaultReplicationSpec: ReplicationSpec
  ): ReplicationSpecList {
    const regionsUnusedContinents = this.getPreferredRegionOfUnusedContinents(replicationSpecList, availableRegions);
    let unUsedRegions;
    if (regionsUnusedContinents.length > 0) {
      unUsedRegions = regionsUnusedContinents;
    } else {
      unUsedRegions = this.getUnusedPreferredRegions(replicationSpecList, availableRegions);
    }

    const currentZoneNames = replicationSpecList.map((rs) => rs.zoneName);
    let zoneNameSuffix = 1;

    while (currentZoneNames.indexOf(`Zone ${zoneNameSuffix}`) !== -1) {
      zoneNameSuffix++;
    }

    // Randomly get a zone for region that's not yet used
    const nextAvailableRegion: RegionView = unUsedRegions[Math.floor(random * unUsedRegions.length)];

    const regionConfig: RegionConfig = getEmptyRegionConfig(
      nextAvailableRegion,
      provider,
      replicationSpecList[0].regionConfigs[0].electableSpecs,
      replicationSpecList[0].regionConfigs[0].autoScaling,
      replicationSpecList[0].regionConfigs[0].analyticsSpecs.instanceSize,
      replicationSpecList[0].regionConfigs[0].analyticsAutoScaling,
      defaultReplicationSpec
    );

    regionConfig.electableSpecs.nodeCount = 3;
    regionConfig.priority = 7;

    const newReplicationSpec = {
      id: new ObjectId().toString(),
      numShards: 1,
      zoneName: `Zone ${zoneNameSuffix}`,
      regionConfigs: [regionConfig],
    };
    const newReplicationSpecList = deepClone(replicationSpecList);
    newReplicationSpecList.push(newReplicationSpec);
    return newReplicationSpecList;
  },

  deleteReplicationSpec(replicationSpecList, replicationSpecId): ReplicationSpecList {
    const updatedReplicationSpecList = deepClone(replicationSpecList);
    const index = replicationSpecList.findIndex((rs: ReplicationSpec) => rs.id === replicationSpecId);
    updatedReplicationSpecList.splice(index, 1);

    return updatedReplicationSpecList;
  },

  isLastReplicationSpecWithAnalyticsNodes(replicationSpecList, replicationSpecId): boolean {
    const replicationSpecsWithAnalyticsNodes = replicationSpecList.filter(
      (replicationSpec) => this.getTotalAnalyticsNodes(replicationSpec) > 0
    );
    return (
      replicationSpecsWithAnalyticsNodes.length === 1 && replicationSpecsWithAnalyticsNodes[0].id === replicationSpecId
    );
  },

  updateInstanceSize(instanceSize: InstanceSize, replicationSpecList: ReplicationSpecList): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { electableSpecs, readOnlySpecs, analyticsSpecs } = regionConfig;

        const newElectableSpecs = { ...electableSpecs, instanceSize };
        const newReadOnlySpecs = { ...readOnlySpecs, instanceSize };
        const newAnalyticsSpecs = { ...analyticsSpecs, instanceSize };

        return {
          ...regionConfig,
          electableSpecs: newElectableSpecs,
          readOnlySpecs: newReadOnlySpecs,
          analyticsSpecs: newAnalyticsSpecs,
        };
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  updateBaseInstanceSize(instanceSize: InstanceSize, replicationSpecList: ReplicationSpecList): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { electableSpecs, readOnlySpecs, analyticsSpecs } = regionConfig;

        const newElectableSpecs = { ...electableSpecs, instanceSize };
        const newReadOnlySpecs = { ...readOnlySpecs, instanceSize };

        return {
          ...regionConfig,
          electableSpecs: newElectableSpecs,
          readOnlySpecs: newReadOnlySpecs,
          analyticsSpecs: analyticsSpecs,
        };
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  updateAnalyticsInstanceSize(
    instanceSize: InstanceSize,
    replicationSpecList: ReplicationSpecList
  ): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { electableSpecs, readOnlySpecs, analyticsSpecs } = regionConfig;
        const newAnalyticsSpecs = { ...analyticsSpecs, instanceSize };

        return {
          ...regionConfig,
          electableSpecs: electableSpecs,
          readOnlySpecs: readOnlySpecs,
          analyticsSpecs: newAnalyticsSpecs,
        };
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  updateFromTenantInstanceSize(
    cloudProvider: CloudProvider,
    instanceSize: InstanceSize,
    replicationSpecList: ReplicationSpecList
  ): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { electableSpecs, readOnlySpecs, analyticsSpecs } = regionConfig;

        const newElectableSpecs = { ...electableSpecs, instanceSize };
        const newReadOnlySpecs = { ...readOnlySpecs, instanceSize };
        const newAnalyticsSpecs = { ...analyticsSpecs, instanceSize };

        return {
          ...regionConfig,
          cloudProvider,
          electableSpecs: newElectableSpecs,
          readOnlySpecs: newReadOnlySpecs,
          analyticsSpecs: newAnalyticsSpecs,
        };
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  updateToTenantInstanceSize(
    backingProvider: BackingCloudProvider,
    instanceSize: InstanceSize,
    replicationSpecList: ReplicationSpecList
  ): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { electableSpecs, readOnlySpecs, analyticsSpecs } = regionConfig;

        const newElectableSpecs = {
          ...electableSpecs,
          backingProvider,
          instanceSize,
        };
        const newReadOnlySpecs = {
          ...readOnlySpecs,
          backingProvider,
          instanceSize,
        };
        const newAnalyticsSpecs = {
          ...analyticsSpecs,
          backingProvider,
          instanceSize,
        };

        return {
          ...regionConfig,
          cloudProvider: CloudProvider.FREE,
          electableSpecs: newElectableSpecs,
          readOnlySpecs: newReadOnlySpecs,
          analyticsSpecs: newAnalyticsSpecs,
        };
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  getChangeFromTenantInstanceDefaults(
    currentReplicationSpecList: ReplicationSpecList,
    targetInstanceSize: InstanceSize
  ): ReplicationSpecList {
    const backingProvider = replicationSpecListUtils.getBackingCloudProviders(currentReplicationSpecList)[0];
    const withUpdatedRegionConfigs = replicationSpecListUtils.updateFromTenantInstanceSize(
      toCloudProvider(backingProvider),
      targetInstanceSize,
      currentReplicationSpecList
    );
    return replicationSpecListUtils.updateBaseAutoScaling(
      {
        autoIndex: { enabled: false },
        compute: {
          enabled: false,
          scaleDownEnabled: false,
          minInstanceSize: null,
          maxInstanceSize: null,
        },
        diskGB: { enabled: true },
      },
      withUpdatedRegionConfigs
    );
  },

  updateBaseAutoScaling(autoScaling: AutoScaling, replicationSpecList: ReplicationSpecList): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        return { ...regionConfig, autoScaling: autoScaling };
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  updateAnalyticsAutoScaling(autoScaling: AutoScaling, replicationSpecList: ReplicationSpecList): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        return { ...regionConfig, analyticsAutoScaling: autoScaling };
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  // See CLOUDP-133162 - this function is not appropriate to use ever - when asymmetric
  // autoscaling is enabled the base and analytics autoscaling must be set separately to avoid
  // issues where the families mismatch. Current manifestations of bugs are minor and caught by
  // backend validation, but be very careful if adding a new call to this function.
  unsafeUpdateAutoScaling(autoScaling: AutoScaling, replicationSpecList: ReplicationSpecList): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        return { ...regionConfig, autoScaling: autoScaling };
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  updateAutoIndexing(autoIndex: AutoIndex, replicationSpecList: ReplicationSpecList): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { autoScaling, analyticsAutoScaling } = regionConfig;
        const newAutoScaling = { ...autoScaling, autoIndex };
        let newAnalyticsAutoScaling: AutoScaling | null = null;
        if (analyticsAutoScaling != null) {
          newAnalyticsAutoScaling = { ...analyticsAutoScaling, autoIndex };
        }
        return {
          ...regionConfig,
          autoScaling: newAutoScaling,
          analyticsAutoScaling: newAnalyticsAutoScaling,
        };
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  updateAWSIOPS(
    diskIOPS: number,
    updatedVolumeType: VolumeType | undefined,
    replicationSpecList: ReplicationSpecList
  ): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { electableSpecs, readOnlySpecs, analyticsSpecs, cloudProvider } = regionConfig;

        if (cloudProvider === CloudProvider.AWS) {
          const volumeType = updatedVolumeType || electableSpecs.volumeType;
          const newElectableSpecs = { ...electableSpecs, diskIOPS, volumeType };
          const newReadOnlySpecs = { ...readOnlySpecs, diskIOPS, volumeType };
          const newAnalyticsSpecs = { ...analyticsSpecs, diskIOPS, volumeType };

          return {
            ...regionConfig,
            electableSpecs: newElectableSpecs,
            readOnlySpecs: newReadOnlySpecs,
            analyticsSpecs: newAnalyticsSpecs,
          };
        }

        return regionConfig;
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  isEBSVolumeEncrypted(replicationSpecList: ReplicationSpecList): boolean {
    return replicationSpecList.every((rs: ReplicationSpec) =>
      rs.regionConfigs
        .filter((rc) => rc.cloudProvider === CloudProvider.AWS)
        .every((rc: RegionConfig) => rc.electableSpecs.encryptEBSVolume)
    );
  },

  updateAWSEncryptEBSVolume(encryptEBSVolume: boolean, replicationSpecList: ReplicationSpecList): ReplicationSpecList {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { electableSpecs, readOnlySpecs, analyticsSpecs, cloudProvider } = regionConfig;

        if (cloudProvider === CloudProvider.AWS) {
          const newElectableSpecs = { ...electableSpecs, encryptEBSVolume };
          const newReadOnlySpecs = { ...readOnlySpecs, encryptEBSVolume };
          const newAnalyticsSpecs = { ...analyticsSpecs, encryptEBSVolume };

          return {
            ...regionConfig,
            electableSpecs: newElectableSpecs,
            readOnlySpecs: newReadOnlySpecs,
            analyticsSpecs: newAnalyticsSpecs,
          };
        }

        return regionConfig;
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  updateAzureIOPS(
    diskIOPS: number | undefined,
    updatedDiskType: AzureDiskType | undefined,
    replicationSpecList: ReplicationSpecList
  ) {
    return replicationSpecList.map((replSpec): ReplicationSpec => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { electableSpecs, readOnlySpecs, analyticsSpecs, cloudProvider } = regionConfig;
        if (cloudProvider === CloudProvider.AZURE) {
          const diskType = updatedDiskType || electableSpecs.diskType;
          // no need to pass throughput to the backend
          const diskThroughput = undefined;

          const newElectableSpecs = { ...electableSpecs, diskIOPS, diskType, diskThroughput };
          const newReadOnlySpecs = { ...readOnlySpecs, diskIOPS, diskType, diskThroughput };
          const newAnalyticsSpecs = { ...analyticsSpecs, diskIOPS, diskType, diskThroughput };

          return {
            ...regionConfig,
            electableSpecs: newElectableSpecs,
            readOnlySpecs: newReadOnlySpecs,
            analyticsSpecs: newAnalyticsSpecs,
          };
        }

        return regionConfig;
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  updateGCPIOPS(diskIOPS: number, replicationSpecList: ReplicationSpecList) {
    return replicationSpecList.map((replSpec) => {
      const newRegionConfigs = replSpec.regionConfigs.map((regionConfig) => {
        const { electableSpecs, readOnlySpecs, analyticsSpecs, cloudProvider } = regionConfig;
        if (cloudProvider === CloudProvider.GCP) {
          const newElectableSpecs = { ...electableSpecs, diskIOPS };
          const newReadOnlySpecs = { ...readOnlySpecs, diskIOPS };
          const newAnalyticsSpecs = { ...analyticsSpecs, diskIOPS };

          return {
            ...regionConfig,
            electableSpecs: newElectableSpecs,
            readOnlySpecs: newReadOnlySpecs,
            analyticsSpecs: newAnalyticsSpecs,
          };
        }

        return regionConfig;
      });
      return { ...replSpec, regionConfigs: newRegionConfigs };
    });
  },

  clusterSupportsIo2(replicationSpecList: ReplicationSpecList): boolean {
    return !replicationSpecList.find((replSpec: ReplicationSpec) =>
      replSpec.regionConfigs.find((regionConfig: RegionConfig) =>
        AWS_REGIONS_WITHOUT_IO2.find((regionName: string) => regionName === regionConfig.regionName)
      )
    );
  },

  ensureProvisionedIOPSVolumeType(replicationSpecList: ReplicationSpecList): ReplicationSpecList {
    const clusterSupportsIo2 = this.clusterSupportsIo2(replicationSpecList);
    const currentVolumeType = this.getFirstAWSVolumeType(replicationSpecList);
    const diskIOPS = this.getFirstAWSDiskIOPS(replicationSpecList);

    if (!clusterSupportsIo2 && currentVolumeType === VolumeType.io2) {
      return this.updateAWSIOPS(diskIOPS, VolumeType.io1, replicationSpecList);
    }

    if (clusterSupportsIo2 && currentVolumeType === VolumeType.io1) {
      return this.updateAWSIOPS(diskIOPS, VolumeType.io2, replicationSpecList);
    }

    return replicationSpecList;
  },

  ensureAzureSsdV1DiskSize(
    replicationSpecList: ReplicationSpecList,
    providerOptions: ProviderOptions,
    diskSizeGB: number
  ): number {
    const instanceSize = replicationSpecListUtils.getFirstInstanceSize(replicationSpecList);
    const instance = providerOptions.AZURE.instanceSizes[instanceSize]!;
    return clusterDescriptionUtils.getSteppedDiskSizeGB(instance, diskSizeGB, false);
  },

  ensureAzureSsdIOPS(
    replicationSpecList: ReplicationSpecList,
    providerOptions: ProviderOptions,
    instance: Instance,
    diskSizeGB: number
  ): ReplicationSpecList {
    const diskIOPS = providerOptions.AZURE.diskSizes[diskSizeGB].iops;
    const maxIOPS = instance.maxIOPS;
    const newIOPS = (maxIOPS && Math.min(maxIOPS, diskIOPS)) || diskIOPS;
    const newDiskType = providerOptions.AZURE.diskSizes[diskSizeGB].type;
    return replicationSpecListUtils.updateAzureIOPS(newIOPS, newDiskType, replicationSpecList);
  },

  ensureAzureSsdV2IOPS(
    replicationSpecList: ReplicationSpecList,
    instance: Instance,
    diskSizeGBToSet: number,
    currentDiskSizeGB?: number
  ): ReplicationSpecList {
    let IOPSToPreserve: undefined | number;
    if (currentDiskSizeGB) {
      const currentAzureIOPS = replicationSpecListUtils.getFirstAzureDiskIOPS(replicationSpecList);
      const currentStandardIOPS = clusterDescriptionUtils.getStandardIOPSForAzureSsdV2(instance, currentDiskSizeGB);
      if (currentAzureIOPS !== currentStandardIOPS) {
        // if current IOPS is different from standard IOPS for current disk size, we will try to preserve this value when disk size changes
        IOPSToPreserve = currentAzureIOPS;
      }
    }
    // if IOPS to preserve is lower than standard IOPS, we will update the IOPS to be standard (which is also min),
    // otherwise we will preserve the original IOPS
    const azureIOPS = clusterDescriptionUtils.getIOPSForAzureSsdV2(instance, diskSizeGBToSet, IOPSToPreserve);
    return replicationSpecListUtils.updateAzureIOPS(azureIOPS, AzureDiskType.V2, replicationSpecList);
  },

  getZoneWithPreferredRegion(replicationSpecList: ReplicationSpecList, regionName: string): string | undefined {
    const specForRegion = replicationSpecList.find((spec) => this.getPreferredRegionName(spec) === regionName);
    return specForRegion ? specForRegion.zoneName : undefined;
  },

  getReplicationSpecIndexFromId(replicationSpecs, replicationSpecId) {
    if (replicationSpecs == undefined) {
      return -1;
    }
    return replicationSpecs.findIndex((spec) => spec.id === replicationSpecId);
  },

  getReplicationSpecFromId(
    replicationSpecs: ReplicationSpecList,
    replicationSpecId: string
  ): ReplicationSpec | undefined {
    return replicationSpecs.find((spec) => spec.id === replicationSpecId);
  },

  updateReplicationSpecInList(replicationSpecs: ReplicationSpecList, replicationSpec: ReplicationSpec) {
    const newReplicationSpecs = deepClone(replicationSpecs);
    const specIndex = this.getReplicationSpecIndexFromId(replicationSpecs, replicationSpec.id);
    if (specIndex === -1) {
      throw new Error(`Replication spec could not be found with ID (${replicationSpec.id})`);
    }
    newReplicationSpecs[specIndex] = replicationSpec;
    return newReplicationSpecs;
  },

  // this can happen when you've just changed providers in the cross-cloud builder
  containsUndefinedRegionName(replicationSpecList: ReplicationSpecList): boolean {
    return replicationSpecList.some(
      (replicationSpec: ReplicationSpec) =>
        replicationSpec.regionConfigs.findIndex((regionConfig) => regionConfig.regionName === '') != -1
    );
  },

  hasValidRegions(replicationSpecList: ReplicationSpecList) {
    return replicationSpecList.every((spec) => {
      return spec.regionConfigs.every((regionConfig) => regionConfig.regionName !== '');
    });
  },

  isInvalidInstanceSizeByIOPSProvisioned(
    isNVMe: boolean,
    instanceSizeName: string,
    replicationSpecList: ReplicationSpecList
  ): boolean {
    return (
      this.hasProvisionedIOPS(replicationSpecList, isNVMe) &&
      INSTANCE_TIERS_WITHOUT_PROVISIONED_IOPS_SUPPORT.has(instanceSizeName)
    );
  },

  hasProvisionedIOPS(replicationSpecList: ReplicationSpecList, isNVMe: boolean): boolean {
    const volumeType = this.getFirstAWSVolumeType(replicationSpecList);
    const isVolumeTypeIo1OrIo2 =
      volumeType !== undefined && (volumeType === VolumeType.io2 || volumeType === VolumeType.io1);
    return !isNVMe && isVolumeTypeIo1OrIo2;
  },

  getFirstAutoScaling(replicationSpecList: ReplicationSpecList): AutoScaling {
    return replicationSpecList[0].regionConfigs[0].autoScaling;
  },
  getFirstAnalyticsAutoScalingWithoutFallback(replicationSpecList: ReplicationSpecList): AutoScaling | null {
    return replicationSpecList[0].regionConfigs[0].analyticsAutoScaling;
  },
  // in order of preference, try
  // 1. Explicit analytics autoscaling
  // 2. Base autoscaling, if compute is compatible w analytics configuration
  // 3. Copy of base autoscaling with compute autoscaling disabled
  getFirstAnalyticsAutoScalingOrBaseFallbackOrDisabledComputeAutoScaling(
    replicationSpecList: ReplicationSpecList
  ): AutoScaling {
    const firstAnalyticsAutoScaling: AutoScaling | null =
      this.getFirstAnalyticsAutoScalingWithoutFallback(replicationSpecList);

    // if analytics autoscaling is present - use it
    if (firstAnalyticsAutoScaling !== null) {
      return firstAnalyticsAutoScaling;
    }
    const firstAutoscaling = this.getFirstAutoScaling(replicationSpecList);
    // If compute autoscale is disabled or not present, we can use base
    if (!(firstAutoscaling?.compute?.enabled ?? false)) {
      return firstAutoscaling;
    }
    if (this.getBaseAndAnalyticsHaveFamilyMismatch(replicationSpecList)) {
      return {
        ...firstAutoscaling,
        compute: {
          enabled: false,
          scaleDownEnabled: false,
          minInstanceSize: null,
          maxInstanceSize: null,
        },
      };
    }

    return (
      this.getFirstAnalyticsAutoScalingWithoutFallback(replicationSpecList) ??
      this.getFirstAutoScaling(replicationSpecList)
    );
  },

  getBaseAndAnalyticsHaveFamilyMismatch(replicationSpecList: ReplicationSpecList): boolean {
    const baseInstanceSizeString = this.getFirstInstanceSize(replicationSpecList);
    const analyticsInstanceSizeString = this.getFirstAnalyticsInstanceSize(replicationSpecList);

    const firstInstanceSize = instanceSizeUtils.parseInstanceSizeString(baseInstanceSizeString);
    const firstAnalyticsSize = instanceSizeUtils.parseInstanceSizeString(analyticsInstanceSizeString);

    return firstInstanceSize.instanceClass !== firstAnalyticsSize.instanceClass;
  },

  // Returns empty object if no hardware spec with cloud provider was found in replicationSpecList
  getFirstProviderBaseHardwareSpec(
    replicationSpecList: ReplicationSpecList,
    provider: CloudProvider
  ): HardwareSpec | undefined {
    for (const replicationSpec of replicationSpecList) {
      for (const regionConfig of replicationSpec.regionConfigs) {
        if (regionConfig.cloudProvider === provider) {
          return regionConfig.electableSpecs;
        }
      }
    }
  },

  getFirstProviderAnalyticsHardwareSpec(
    replicationSpecList: ReplicationSpecList,
    provider: CloudProvider
  ): HardwareSpec | undefined {
    for (const replicationSpec of replicationSpecList) {
      for (const regionConfig of replicationSpec.regionConfigs) {
        if (regionConfig.cloudProvider == provider) {
          return regionConfig.analyticsSpecs;
        }
      }
    }
  },

  // Changes cloud provider-specific fields of input regionConfig's hardware specs to match
  // hardware specs in replicationSpecList that have same cloud provider
  matchHardwareSpecCloudProviderFields(
    replicationSpecList: ReplicationSpecList,
    regionConfig: RegionConfig
  ): RegionConfig {
    const updatedRegionConfig = deepClone(regionConfig);
    const provider = regionConfig.cloudProvider;
    const providerSpecToMatch = this.getFirstProviderBaseHardwareSpec(replicationSpecList, provider);

    // No existing spec with provider in replicationSpecList
    if (providerSpecToMatch == null) {
      return updatedRegionConfig;
    }

    // Function that takes in a HardwareSpec and assigns its cloud provider-specific fields
    // to match providerSpecToMatch
    let matchSpec: (spec: HardwareSpec) => void;
    switch (provider) {
      case CloudProvider.AWS:
        matchSpec = (spec: HardwareSpec) => {
          spec.volumeType = providerSpecToMatch.volumeType;
          spec.encryptEBSVolume = providerSpecToMatch.encryptEBSVolume;
          spec.diskIOPS = providerSpecToMatch.diskIOPS;
        };
        break;
      case CloudProvider.AZURE:
        matchSpec = (spec: HardwareSpec) => {
          spec.diskType = providerSpecToMatch.diskType;
        };
        break;
      default:
        matchSpec = () => {};
    }

    matchSpec(updatedRegionConfig.electableSpecs);
    matchSpec(updatedRegionConfig.analyticsSpecs);
    matchSpec(updatedRegionConfig.readOnlySpecs);

    return updatedRegionConfig;
  },

  getRegionReadable(region: RegionConfig): string {
    const provider =
      region.cloudProvider === CloudProvider.FREE ? region.electableSpecs.backingProvider : region.cloudProvider;
    return `${provider} ${region.regionView.location} (${region.regionView.name})`;
  },

  getEncryptedStorage(replicationSpecList: ReplicationSpecList): boolean {
    if (!replicationSpecList) {
      return true;
    }
    const awsRegionConfigs = replicationSpecList
      .flatMap((spec) => spec.regionConfigs)
      .filter((regionConfig) => regionConfig.cloudProvider === CloudProvider.AWS);

    if (awsRegionConfigs.length === 0) {
      return true;
    }

    return awsRegionConfigs.every((regionConfig) => regionConfig.electableSpecs.encryptEBSVolume);
  },

  allRegionsAvailableForInstanceSize(replicationSpecList: ReplicationSpecList, instanceSize): boolean {
    return replicationSpecList.every((rs: ReplicationSpec) => {
      const involvedRegions: Array<RegionView> = rs.regionConfigs
        .filter((rc: RegionConfig) => rc.regionName !== '')
        .map((regionConfig: RegionConfig) => regionConfig.regionView);
      return involvedRegions.every((region: RegionView) =>
        instanceSize.availableRegions.some(
          (availableRegion) =>
            availableRegion.regionName === region.key && availableRegion.providerName === region.provider
        )
      );
    });
  },

  getDisplayRegionLocation(regionView: RegionView, showProvider: boolean): string {
    const readableProvider: string = UserFacingCloudProvider[regionView.provider];
    return `${showProvider ? `${readableProvider} ` : ''}${regionView.location}`;
  },

  getDisplayRegionName(regionView: RegionView, showProvider: boolean): string {
    return `${this.getDisplayRegionLocation(regionView, showProvider)} (${regionView.name})`;
  },

  isAWSProvisionedCluster(replicationSpec: ReplicationSpec) {
    return replicationSpec.regionConfigs.some(
      (conf: RegionConfig) =>
        conf.cloudProvider === 'AWS' &&
        (conf?.electableSpecs?.volumeType === VOLUME_TYPE.io1 || conf?.electableSpecs?.volumeType === VOLUME_TYPE.io2)
    );
  },

  iopsChanged(
    providerOptions: ProviderOptions,
    originalCluster: ClusterDescription,
    newCluster: ClusterDescription,
    ignoreIfVolumeTypeIsKeptAsProvisioned?: boolean
  ): boolean {
    const iopsChanged =
      this.getIOPSToDisplay(providerOptions, originalCluster.diskSizeGB, originalCluster.replicationSpecList) !==
      this.getIOPSToDisplay(providerOptions, newCluster.diskSizeGB, newCluster.replicationSpecList);

    const originalVolumeType = this.getFirstAWSVolumeType(originalCluster.replicationSpecList);
    const newVolumeType = this.getFirstAWSVolumeType(newCluster.replicationSpecList);
    const volumeTypeChanged = originalVolumeType !== newVolumeType;
    const volumeTypeIsKeptAsProvisioned =
      (originalVolumeType === 'io1' || originalVolumeType === 'io2') &&
      (newVolumeType === 'io1' || newVolumeType === 'io2');

    if (ignoreIfVolumeTypeIsKeptAsProvisioned) {
      return iopsChanged || (volumeTypeChanged && !volumeTypeIsKeptAsProvisioned);
    }

    return iopsChanged || volumeTypeChanged;
  },

  hasStorageChanges(
    providerOptions: ProviderOptions,
    originalCluster: ClusterDescription,
    newCluster: ClusterDescription
  ): boolean {
    const storageChanged = originalCluster.diskSizeGB !== newCluster.diskSizeGB;
    const iopsChanged = this.iopsChanged(providerOptions, originalCluster, newCluster);
    const ebsVolumeEncryptedChanged =
      this.isEBSVolumeEncrypted(originalCluster.replicationSpecList) !==
      this.isEBSVolumeEncrypted(newCluster.replicationSpecList);

    return storageChanged || iopsChanged || ebsVolumeEncryptedChanged;
  },

  getReadableZoneNameList(replicationSpecSet: Set<ReplicationSpec>): string {
    let readableZoneList = '';
    let index = 0;
    const numZones = replicationSpecSet.size;
    replicationSpecSet.forEach((curr: ReplicationSpec) => {
      if (index > 0) {
        if (index === numZones - 1) {
          readableZoneList += ' and ';
        } else {
          readableZoneList += ', ';
        }
      }

      readableZoneList += curr.zoneName;
      index++;
    });
    return readableZoneList;
  },

  changeRequiresAutomationUpdate(originalRSL: ReplicationSpecList, newRSL: ReplicationSpecList) {
    if (originalRSL.length !== newRSL.length) {
      return true;
    }

    if (!originalRSL.every(({ id }) => !!newRSL.find((rs) => rs.id === id))) {
      return true;
    }

    return originalRSL.some((originalSpec) => {
      const newSpec = newRSL.find((spec) => spec.id === originalSpec.id);

      if (!originalSpec || !newSpec) {
        return true;
      }

      if (newSpec.numShards != originalSpec.numShards) {
        return true;
      }

      for (const regionConfig of originalSpec.regionConfigs) {
        const newRegionConfig = newSpec.regionConfigs.find(
          (rc) => rc.cloudProvider === regionConfig.cloudProvider && rc.regionName === regionConfig.regionName
        );

        if (!newRegionConfig) {
          return true;
        }

        if (
          regionConfig.electableSpecs.nodeCount !== newRegionConfig.electableSpecs.nodeCount ||
          regionConfig.readOnlySpecs.nodeCount !== newRegionConfig.readOnlySpecs.nodeCount ||
          regionConfig.analyticsSpecs.nodeCount !== newRegionConfig.analyticsSpecs.nodeCount
        ) {
          return true;
        }
      }

      return false;
    });
  },

  /**
   * Remove regions that don't have a specified region name.
   */
  removeUnspecifiedRegions(replicationSpecList: ReplicationSpecList): ReplicationSpecList {
    return replicationSpecList.map((replicationSpec) => _removeUnspecifiedRegion(replicationSpec));
  },
};
