import { type CloudNavAnalyticsEvent } from '@lg-private/cloud-nav';
import heliotrope, {
  CommonIntegrations,
  ENVIRONMENT,
  HeliotropeReturn,
  InitOptions,
  SegmentUserObject,
  TrackOptions,
  UpdateStateOptions,
} from '@mongodb-js/heliotrope';
import { EndpointReturnType } from 'baas-admin-sdk';

import { Assignments, NonNullAssignment, TestGroupName, TestName } from '@packages/types/abTest';
import { ResourceType } from '@packages/types/auth/fga';
import { ChartsUserRoleInfo } from '@packages/types/chartsUserRoles';
import { BasicClusterEventProperties } from '@packages/types/clusters/clusterTracking';
import { CLT_SIDEBAR_TRACK_CONTEXT, DbToolDrawer, ValidCommand } from '@packages/types/clusters/commandLineTools';
import { Title as DataModelingTemplateTitle } from '@packages/types/dataModeling';
import { GroupId } from '@packages/types/groups';
import { MonitoringIdType } from '@packages/types/metrics/lastDataExplorerVisited';
import { TemplateKey } from '@packages/types/nds/defaultTemplate';
import { BackingCloudProvider, CloudProvider, InstanceSize } from '@packages/types/nds/provider';
import { RegionName } from '@packages/types/nds/region';
import { CloudTeams } from '@packages/types/observability';
import { type AppEnv } from '@packages/types/RequestParams';
import { ResourceContext, Tag } from '@packages/types/resourceTagging';
import { ThemePreference } from '@packages/types/theme';
import { PartiallyOptional } from '@packages/types/utilTypes';

import { getAbTestPersistedProps } from '@packages/common/utils/abTestUtil';
import getHeliotropeEnvironment from '@packages/common/utils/getHeliotropeEnvironment';
import { sendError } from '@packages/observability';
import {
  type SanitizeLocationOptions,
  sanitizeLocation as sanitizeWindowLocation,
} from '@packages/observability/url-utils';

export interface IdentifyParams {
  first_name: string;
  last_name: string;
  email: string;
  active_project_id: GroupId;
  is_cloud_manager_user: boolean;
  test_assignments: Assignments;
}

export const E2E_SDK_URL = '/static/cypress/support/assets/segment-sdk-mocked.js';

/**
 * The default timeout that should be used when awaiting an analytics call.
 *
 * Enough time to give it a chance to send the request WITHOUT disrupting the user experience and causing any visual
 * stutters or issues.
 */
export const DEFAULT_AWAIT_TIMEOUT_MS = 200;

export type SegmentEvents = (typeof SEGMENT_EVENTS)[keyof typeof SEGMENT_EVENTS];

type TrackArgs = [SegmentEvents, TrackProperties, OptionsProperties?, ((...args: Array<any>) => void)?];
type PageArgs = [string, PageProperties?];
type IdentifyArgs = [object, number?];
type UpdateStateArgs = [UpdateStateOptions, object?];

interface InitProperties {
  analyticsEnabled: boolean;
  segmentId: string;
  appId: string;
  userId?: string;
  orgId?: string;
  groupId?: string;
  email?: string;
  testAssignments?: Array<NonNullAssignment>;
  appEnv: AppEnv;
  accountCentralUrl: string;
  shouldRequestAuid?: boolean;
}

export interface OptionsProperties {
  timeout?: number;
}

interface PersistedProperties {
  cloud_user_id?: string;
  org_id?: string;
  group_id?: string;
  experiment_ids?: Array<string>;
  experiment_variant_ids?: Array<string>;
  test_assignments?: Array<string>;
  email?: string;
}

interface AnalyticsProperties {
  track: (...args: TrackArgs) => Promise<HeliotropeReturn>;
  page: (...args: PageArgs) => Promise<HeliotropeReturn>;
  identify: (...args: IdentifyArgs) => Promise<HeliotropeReturn>;
  user: () => SegmentUserObject | undefined;
  updateState: (...args: UpdateStateArgs) => Promise<HeliotropeReturn>;
  reset: () => void;
}

const getIntegrations = (orgId?: string): CommonIntegrations => {
  let integrations;

  if (orgId) {
    integrations = buildIntegrationsObject(orgId);
  } else {
    integrations = {};
  }

  integrations['Google Analytics'] = true;

  return integrations;
};

const initializeAnalytics = async ({
  analyticsEnabled,
  segmentId,
  appId,
  userId,
  orgId,
  groupId,
  email,
  testAssignments,
  appEnv,
  accountCentralUrl,
  shouldRequestAuid = true,
}: InitProperties): Promise<void> => {
  if (!analyticsEnabled) {
    return;
  }
  const environment: ENVIRONMENT = getHeliotropeEnvironment(accountCentralUrl, appEnv);
  const initOptions: InitOptions = {
    enabled: analyticsEnabled,
    segmentId,
    appId,
    integrations: getIntegrations(orgId),
    canSetAuidCookie: true,
    environment,
    shouldRequestAuid,
  };

  if (environment === ENVIRONMENT.E2E) {
    initOptions.e2eSdkUrl = E2E_SDK_URL;
  }

  const persistedProperties: PersistedProperties = testAssignments ? getAbTestPersistedProps(testAssignments) : {};

  if (email) {
    persistedProperties.email = email;
  }

  if (userId) {
    persistedProperties.cloud_user_id = userId;
  }

  if (orgId) {
    persistedProperties.org_id = orgId;
  }

  if (groupId) {
    persistedProperties.group_id = groupId;
  }

  if (Object.keys(persistedProperties).length > 0) {
    initOptions.persistedProperties = persistedProperties;
  }
  try {
    await heliotrope.init(initOptions);
  } catch (error) {
    sendError({ error, team: CloudTeams.AtlasGrowth });
  }
};

const analytics: AnalyticsProperties = {
  track: async (...args) => {
    const [eventName, properties, options = {}] = args;
    const trackOpts: TrackOptions<TrackProperties> = { eventName, properties };

    // Add timeout if passed in:
    const { timeout } = options;
    if (timeout) {
      trackOpts.timeout = timeout;
    }

    try {
      // await needed for handling promise rejections as well as thrown errors
      return await heliotrope.track(trackOpts);
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
    }
  },
  page: async (...args) => {
    const [pageName, properties] = args;

    try {
      return await heliotrope.page({ pageName, properties });
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
    }
  },
  identify: async (...args) => {
    const [traits, timeout] = args;

    try {
      return await heliotrope.identify({ traits, timeout });
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
    }
  },
  user: () => heliotrope.user(),
  updateState: async (newState, options) => {
    try {
      return await heliotrope.updateState(newState, options);
    } catch (error) {
      sendError({ error, team: CloudTeams.AtlasGrowth });
    }
  },
  reset: () => heliotrope.reset(),
};

// cluster description metadata from clusterTrackingUtil.js
export interface ClusterDescriptionTrackProperties {
  cloud_provider?: string;
  region?: string;
  cross_region?: boolean;
  global_cluster?: boolean;
  instance_size?: string;
  disk_size_gb?: number;
  IOPS_speed?: number;
  auto_expand_storage?: boolean;
  db_version?: string;
  backup_selected?: boolean;
  backup_option?: string;
  sharding?: boolean;
  shard_count?: number;
  BI_connector?: boolean;
  BI_node_type?: string;
  encryption_key_management?: boolean;
  key_index_limit?: boolean;
  require_indexes?: boolean;
  server_side_JS?: boolean;
  cluster_id?: string;
  cluster_name?: string;
  cluster_unique_id?: string;
  data_size?: any;
  auto_scaling_compute_enabled?: boolean;
  auto_scaling_compute_scaledown_enabled?: boolean;
  auto_scaling_compute_max_instance?: string;
  auto_scaling_compute_min_instance?: string;
  termination_protection_enabled?: boolean;
}

/**
 * @deprecated
 *
 * Please do not extend this interface with further properties!
 *
 * Having one large tracking property interface with many optional properties leads to
 * loose type definitions and potentionally incorrect event tracking. Over time, this interface will be
 * whittled down and removed.
 *
 * Instead, please define a new interface specific to the track event you're introducing with as few optional properties
 * as possible. (Similar to RegionSelectedEvent below.) Comment each property as is sensible and ensure each property
 * name is separated by underscores (_) instead of being camelCased.
 *
 * Once a new interface is created, add it to the TrackProperties type union to use it in a `track` call downstream.
 * */
export interface TrackPropertiesLegacy extends ClusterDescriptionTrackProperties {
  context?: string;
  action?: string;
  value?: string;
  environment?: string;
  signup_source?: string;
  signup_method?: string;
  error_code?: string;
  error_message?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  country?: string;
  isEdit?: boolean;
  pre_selected_tier?: InstanceSize;
  userAgent?: string;
  revenue?: number;
  quantity?: number;
  price?: number;
  sku?: string;
  name?: string;
  referrer?: string;
  has_project_name_changed?: boolean;
  has_company_name_changed?: boolean;
  preferred_programming_language?: string | undefined;
  optimizelyEndUserId?: string;
  url?: string;
  tck?: string;
  ad_id?: string;
  ad_id_set?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_source?: string;
  utm_term?: string;
  utm_medium?: string;
  utm_adid?: string;
  utm_ad?: string;
  utm_ad_campaign_id?: string;
  gclid?: string;
  form_url?: string;
  hourly_cost?: string;
  tier?: string;
  error_count?: number;
  step_name?: string;
  nextUrl?: string;
  pathfinder_filter?: string;
  goal?: string | undefined;
  user_type?: string;
  is_temporary?: boolean;
  has_payment_method?: boolean;
  tooltip_id?: string;
  campaign?: string;
  search_string?: string;
  message?: string;
  multi_cloud_view_is_open?: boolean;
  from?: string;
}

interface TriggersPreimageStateChangeEvent {
  context: string;
  action: string;
  new_preimage_state: boolean;
}

interface TriggersSavedEvent {
  enabled: boolean;
  trigger_type: string;
  skip_catchup_events: boolean;
  event_type: string;
  is_update: boolean;
}

interface DatabaseTriggersSaveEvent extends TriggersSavedEvent {
  ordered: boolean;
  watch_level: string;
  op_type_insert: boolean;
  op_type_update: boolean;
  op_type_delete: boolean;
  op_type_replace: boolean;
  op_type_create_collection: boolean;
  op_type_modify_collection: boolean;
  op_type_rename_collection: boolean;
  op_type_shard_collection: boolean;
  op_type_drop_collection: boolean;
  op_type_reshard_collection: boolean;
  op_type_refine_collection_shard_key: boolean;
  op_type_create_index: boolean;
  op_type_drop_index: boolean;
  op_type_drop_database: boolean;
  full_document: boolean;
  auto_resume: boolean;
  maximum_throughput: boolean;
  error_handler_enabled: boolean;
  match_expression_present: boolean;
  project_expression_present: boolean;
}

interface TriggersDependencyConfigurationStartedEvent {
  from_view: string;
}

interface DataAPIEnableDisableEvent {
  context: string;
  action: string;
  // Had to make this more generic, as there was another 'tier' field in the generated union that was just a string
  tier: string; // InstanceSize;
  cloudProvider: CloudProvider | BackingCloudProvider;
  // Had to make this more generic, as there was another 'region' field in the generated union that was just a string
  region: string; // RegionName;
}

interface DataAPISnippetCopiedEvent {
  context: string;
  action: string;
  snippetLanguage: string;
}

export interface EloquaData extends Session {
  data: Object;
  elqSiteID: number;
  elqFormName: string;
  elqCustomerGUID: string;
  elqCookieWrite: number;
  send_eloqua: boolean;
  url: string;
  anonymousId: string;
  web_check_businessemail: boolean;
}

export interface ExperimentViewedSimplestBaseEvent {
  test_name: TestName;
  test_group_id: TestGroupName;
  test_group_name: TestGroupName;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ExperimentViewedBaseEvent = {
  test_name: TestName;
  // Note we are sending in TestGroupName under two params. test_group_id is being kept around for backwards compat,
  // and will be removed when other areas of the system have been updated.
  test_group_id: TestGroupName;
  test_group_name: TestGroupName;
  entity_type: NonNullAssignment['entityType'];
  entity_id: NonNullAssignment['entityId'];
  test_tag: NonNullAssignment['tag'];
  assignment_date: NonNullAssignment['assignmentDate'];
  test_group_database_id: NonNullAssignment['testGroupDatabaseId'];
  test_id: NonNullAssignment['testId'];
  test_assignment: string;
};

export type ExperimentViewedGhostEvent = {
  linked_holdout_test_id: string;
  linked_holdout_test_name: string;
} & ExperimentViewedBaseEvent;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ExperimentViewedChartsAwarenessExperimentProperties = {
  num_clusters: number;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ExperimentViewedClusterUpgradeTemplatesExperimentProperties = {
  clusterCount: number;
  fromCta?: string;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ExperimentViewedDedicatedTierFeatureAwarenessExperimentProperties = {
  feature: string;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type LocalCurrencyExperimentProperties = {
  currency_selected?: string;
  selected_currency?: string;
};

export type ExperimentViewedAdditionalProperties =
  | ExperimentViewedChartsAwarenessExperimentProperties
  | ExperimentViewedClusterUpgradeTemplatesExperimentProperties
  | ExperimentViewedDedicatedTierFeatureAwarenessExperimentProperties;

export type ExperimentEventViewed =
  | (ExperimentViewedBaseEvent | ExperimentViewedGhostEvent | ExperimentViewedSimplestBaseEvent)
  | ((ExperimentViewedBaseEvent | ExperimentViewedGhostEvent) & ExperimentViewedAdditionalProperties);

// event fired when a region is selected in the cluster builder region picker
interface RegionSelectedEvent {
  action: string;
  context: string;
  // which cluster builder form the event was fired from
  deployment_type: string;
  // e.g. US_EAST_1
  region_key: string;
  // a human legible location for a region, e.g. N. Virginia
  region_location: string;
  // whether or not a free tier is available for the newly selected region
  free_tier_available: boolean;
  // if the selected region is a grayed out paid tier region (experiment AG-360)
  grayed_out_paid_tier: boolean;
  // if the selected region is a low carbon region (experiment AG-583)
  low_carbon_region: boolean;
}

interface ClusterCardTipClick {
  context: string;
  action: string;
  tip_position?: string;
  instance_size?: string;
  tip_type?: string;
}

interface LastVisitedDataExplorerUpdatedEvent {
  monitoring_id: string;
  monitoring_id_type: MonitoringIdType;
}

export interface AlertMessageEvent {
  alert_id?: string;
  alert_config_id?: string;
  scope: string;
  type: string;
  event_type: string;
  org_id?: string;
  group_id?: string;
}

export interface PurchaseButtonClickedEvent {
  action: string;
  context: string;
  ctaRef?: string;
}

export interface PartnerLinkOrgButtonClickedEvent {
  action: string;
  context: string;
  org_id: string;
}

export interface FeatureUpdatesTriggerClickedEvent {
  action: string;
  context: string;
  copy: string;
  unread_count: string;
}

interface TieredClusterSubmittedSuccessfullyEventBase {
  isEdit: boolean;
  pre_selected_tier?: InstanceSize;
  context: string;
  hourly_cost: string;
  region_autoselect: RegionName;
  user_country_code: string;
  template_key?: TemplateKey;
  automate_security?: boolean;
  load_sample_dataset?: boolean;
}

export interface TieredClusterSubmittedSuccessfullyEvent
  extends BasicClusterEventProperties,
    TieredClusterSubmittedSuccessfullyEventBase {
  context: string;
  hourly_cost: string;
}

interface ServerlessClusterSubmittedSuccessfullyEventBase {
  region_autoselect: RegionName;
  user_country_code: string;
}

export interface ServerlessClusterSubmittedSuccessfullyEvent
  extends BasicClusterEventProperties,
    ServerlessClusterSubmittedSuccessfullyEventBase {
  context: string;
}

type ClusterSubmittedSuccessfullyEvent =
  | ServerlessClusterSubmittedSuccessfullyEventBase
  | TieredClusterSubmittedSuccessfullyEventBase;

interface ClusterBuilderErrorEventBase {
  errorName: string;
  errorMessage: string;
}

export interface ClusterBuilderErrorEvent extends BasicClusterEventProperties, ClusterBuilderErrorEventBase {}

interface ClusterBuilderSearchNodesCountChangedEvent {
  nodeCount: number;
}

interface ClusterBuilderSearchTierChangedEvent {
  instanceSizeOld: string;
  instanceSizeNew: string;
}

export enum FormType {
  REGISTRATION = 'registration',
}

export enum EmailType {
  STANDARD = 'standard',
  BUSINESS = 'business',
}

export interface RegistrationFormSubmittedEvent {
  context: string;
  action: string;
  email_type: EmailType;
  form_type: FormType;
}

export interface IpWhitelistFormSubmittedEvent {
  context: string;
  adding_current_ip_address: boolean;
}

interface CLTDropdownBase {
  context: typeof CLT_SIDEBAR_TRACK_CONTEXT;
}

// needs to be separate here and not inherit from CLTDropdownBase
// as that includes 'context' and breaks the union type above
interface CLTSidebarDrawerEventBase {
  drawer_name: DbToolDrawer;
}

export interface CLTSidebarDrawerEvent extends CLTDropdownBase, CLTSidebarDrawerEventBase {}

interface CLTSidebarCodeSampleEventBase {
  code_sample_name: ValidCommand;
}

export interface CLTSidebarCodeSampleEvent extends CLTDropdownBase, CLTSidebarCodeSampleEventBase {
  action: 'Code Sample Copied';
}

export interface CLTSidebarDropdownEvent extends CLTDropdownBase {
  action: 'Cluster Dropdown Changed';
}

export interface SettingsToggleCheckedEvent {
  context: string;
  settings_name: string;
  checked: boolean;
}

export interface PagerDutyActivationEvent {
  configuration_method: string;
}

export interface CheckoutStepViewedEvent extends PaymentInfoStep {
  percent_form_completed?: string;
}

export interface PaymentInfoErrorEvent {
  code_value: string;
  error_message: string;
}

export interface PaymentInfoSubmittedSuccessfullyEvent {
  code_value: string;
  brand: string | null;
}

export interface PaymentInfoEnteredEvent {
  brand: string | null;
}

export enum PaymentInfoStepName {
  Address = 'Address',
  Payment = 'Payment',
}

export const IndexToPaymentStepName = [PaymentInfoStepName.Address, PaymentInfoStepName.Payment];

interface PaymentInfoStep {
  step: PaymentInfoStepName;
}

export interface PaymentInfoEditInitiatedEvent extends PaymentInfoStep {
  brand: string | null;
}

interface PaymentSubmittedEvent {
  payment_type: string;
}

interface DataModelingTemplatesBase {
  dataset: DataModelingTemplateTitle;
}

export interface DataModelingTemplatesSidebarToggled extends DataModelingTemplatesBase {
  action: 'Generate Data Sidebar Opened';
  context: 'Data Modeling Templates Page';
}

export interface DataModelingTemplatesLoadDatasetClicked extends DataModelingTemplatesBase {
  action: 'Clicked Load Sample Data';
  context: 'Data Modeling Templates Page';
}

export type DataModelingTemplatesEvents = DataModelingTemplatesSidebarToggled | DataModelingTemplatesLoadDatasetClicked;

interface PersonalizationWizardFormSubmittedEvent {
  atlas_search: boolean;
  metrics_monitoring: boolean;
  data_lake: boolean;
  charts: boolean;
  app_services: boolean;
  device_sync: boolean;
}

export interface MarketplaceFeedbackSubmitEvent {
  context: string;
  action: string;
  feedback_data: object;
}

interface MarketplaceIntegrationCardClickedEvent {
  destination_url: string;
}

export interface AtlasGoToCommandCompletedEvent {
  command: string;
}

interface ChurnSurveyDowngrageSuggestionClicked {
  current_instance_size: InstanceSize;
  target_instance_size: InstanceSize;
}

export interface ResetPasswordEvent {
  context: string;
  action: string;
  username: string;
}

export interface DataAPIReturnTypeChangeEvent {
  context: string;
  action: string;
  return_type: EndpointReturnType;
}

export interface ClusterStarterTemplate {
  action: string;
  template_name: string;
  clusterCount: number;
}

export interface ClusterStarterTemplateDeployLaterClickedEvent {
  context: string;
  action: string;
}

export interface ClusterDraftsTemplate {
  action: string;
  context: string;
  cluster_builder_url: string;
  instance_size: string;
  cluster_name: string;
  is_edit: boolean;
  cloud_provider: string;
  region: string;
  tier: string;
  is_shared: boolean;
  db_version: string;
  cluster_id: string;
}

interface MFAEncouragementFirstFactorSetup {
  factor_name: string;
}

interface MFAEncouragementSecondFactorSetup {
  factor_name: string;
}

export type ChartsActivationEvent = ChartsUserRoleInfo & { source: string };

interface CommandLineToolsButtonClickedEvent {
  is_open: boolean;
}

interface SelectivelyLoadSampleDatasetsEvent {
  context: string;
  action: string;
  datasets: Array<string>;
}
/**
 * The different modules displayed on Home Center page
 */
export enum HomeCenterModule {
  Feedback = 'Feedback',
  ResourcesCenter = 'Resources Center',
  Toolkit = 'Toolkit',
  Deployments = 'Deployments',
  ApplicationClientsEmptyState = 'Application Clients Empty State',
  ApplicationClients = 'Application Clients',
  ProductionTips = 'Production Tips',
  Toolbar = 'Toolbar',
  Clusters = 'Clusters',
  DataManagement = 'Data Management',
}

interface BaseHomeCenterModuleEvent {
  context: 'Atlas Home Center';
  home_center_module: HomeCenterModule;
}

interface BaseHomeCenterModuleEventWithoutContext extends Omit<BaseHomeCenterModuleEvent, 'context'> {}

interface HomeCenterFeedbackModuleSubmitEventBase {
  feedback_option_selected?: 'positive' | 'negative';
  feedback_text?: string;
}

export interface HomeCenterFeedbackModuleSubmitEvent
  extends BaseHomeCenterModuleEvent,
    HomeCenterFeedbackModuleSubmitEventBase {}

interface HomeCenterToolkitModuleItemClickEventBase {
  action: string;
  copy: string;
}

export interface HomeCenterToolkitModuleItemClickEvent
  extends BaseHomeCenterModuleEvent,
    HomeCenterToolkitModuleItemClickEventBase {}

interface HomeCenterResourceModuleItemClickEventBase {
  action: string;
  resource_clicked: string;
  is_intercom_link?: boolean;
}

export interface HomeCenterResourceModuleItemClickEvent
  extends BaseHomeCenterModuleEvent,
    HomeCenterResourceModuleItemClickEventBase {}

interface HomeCenterApplicationClientsModuleItemClickEventBase {
  tab_name: string;
  button_name: string;
  link_text: string;
}

export interface HomeCenterApplicationClientsModuleItemClickEvent
  extends BaseHomeCenterModuleEvent,
    HomeCenterApplicationClientsModuleItemClickEventBase {}

interface HomeCenterDeploymentSelectedEventBase {
  action: string;
}

export interface HomeCenterDeploymentSelectedEvent
  extends BaseHomeCenterModuleEvent,
    HomeCenterDeploymentSelectedEventBase {}

interface HomeCenterUpdatedLanguageEventBase {
  action: string;
  programming_language: string;
}

export interface HomeCenterUpdatedLanguageEvent extends BaseHomeCenterModuleEvent, HomeCenterUpdatedLanguageEventBase {}

interface HomeCenterUXActionPerformed {
  action: string;
  context: string;
  home_center_module: HomeCenterModule;
  cluster_name?: string;
  link_name?: string;
  driver_name?: string;
}

interface HomeCenterClusterNavigationModalSelectorEvent {
  context: string;
  action: string;
  deployment: string;
  deployment_id: string;
}

interface HomeCenterProductionTipCardEvent {
  home_center_module: HomeCenterModule;
  name: string;
  deployment_name?: string;
  field_value?: string;
}

interface HomeCenterProductionTipModuleSeenEvent extends BaseHomeCenterModuleEventWithoutContext {
  open_tips?: {
    slow_query_rec_outstanding_queries?: number;
    performance_advisor_rec_outstanding_items?: number;
    backup_configuration_rec_outstanding_items?: number;
    auto_scaling_rec_outstanding_items?: number;
    billing_alerts_rec_outstanding_items?: number;
    usage_alerts_rec_outstanding_items?: number;
    ip_access_rec_outstanding_items?: number;
    databasae_access_rec_outstanding_items?: number;
    storage_threshold_rec_outstanding_items?: number;
    connections_threshold_rec_outstanding_items?: number;
  };
}

interface HomeCenterProductionTipModuleTabClickedEvent extends BaseHomeCenterModuleEventWithoutContext {
  num_incomplete_tab: number;
  num_all_tab: number;
}

interface ProgrammaticAccessEvent {
  context: string;
  action?: string;
  has_cluster?: boolean;
  tool_name?: string;
  resource_name?: string;
  resource_type?: string;
}

interface PlainTextPasswordToggledEvent {
  context: string;
  show_plain_text_password: boolean;
}

interface AlertActionEvent {
  context: string;
  action: string;
  is_default?: boolean;
  alert: string;
}

/////
// Resource Tagging Events
/////

interface ResourceTaggingBaseEvent {
  context: string;
  action: string;
  resource_name: string;
  resource_id: string;
  resource_type: ResourceType;
  resource_context: ResourceContext;
}

interface ResourceTaggingSaveTagsEvent extends ResourceTaggingBaseEvent {
  tags: Array<Tag>;
}

type ResourceTaggingAutocompleteClickEvent = PartiallyOptional<
  ResourceTaggingBaseEvent,
  'resource_id' | 'resource_name'
> & { group: string; item: string };

export interface Session {
  jump_id?: string;
  tck?: string;
  ad_id?: string;
  ad_id_set?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  gclid?: string;
  form_url?: string;
}

interface StreamsOpenRequestAccessSurvey {
  context: string;
  action: string;
  via_query_param: string;
}

interface StreamsCRUDConnection {
  context: string;
  action: string;
  connection_type: string;
  access_type: string;
}

interface StreamsCancelCRUDConnection {
  context: string;
  action: string;
  is_edit: string;
}

interface StreamsSetKafkaSecurityProtocol {
  context: string;
  action: string;
  protocol: string;
}

interface StreamProcessingOverview {
  context: string;
  action: string;
  current_step: string;
}

interface DataFederationSQLSchemaFormSaved {
  context: string;
  action: string;
  is_generated: boolean;
}

interface DataFederationSQLSchemaFormCancelled {
  context: string;
  action: string;
  unsaved_changes: boolean;
}

interface IpAccessListEntryRemoved {
  is_removing_global_access: boolean;
}

interface TeamCreatedEvent {
  team_usernames: Array<string>;
  team_name: string;
}

interface ProjectInviteEvent {
  user_ids: Array<string>;
  team_ids: Array<string>;
  api_user_ids: Array<string>;
  service_account_client_ids: Array<string>;
}

interface SnippetEvent {
  snippet_id: string;
  snippet_type: string;
}

interface ClusterDraftModalContinueEvent {
  redirect_to_cluster_builder: boolean;
}

interface ClusterTransactionLoadedEvent {
  action: string;
  context: string;
  transactionLoadedFrom: string;
}

interface VercelDataAPIToggleStatusEvent {
  data_api_toggle_status: string;
}

interface VercelUnlinkClusterConfirmEvent {
  data_api_toggle_status: string;
  unlink_cluster_name: string;
}

interface VercelLinkClusterConfirmEvent {
  data_api_toggle_status: string;
  selected_cluster: string;
}

interface TableFilterClickedEvent {
  filterVariant: string;
}

interface TemplateDoorClickedEvent {
  template_type: 'Advanced' | 'Basic';
}

interface ThemeSelectedEvent {
  prior_theme: ThemePreference;
}

interface CostExplorerTableSortedEvent {
  sort_column: string;
  sort_direction: string;
}

interface FiltersClearedEventBase {
  projects_cleared: string;
  clusters_cleared: string;
  services_cleared: string;
}

interface CostExplorerFiltersClearedEvent extends FiltersClearedEventBase {
  organizations_cleared: string;
  dates_cleared: string;
}

interface UsageDetailsFiltersClearedEvent extends FiltersClearedEventBase {
  bill_dates_cleared: string;
  usage_dates_cleared: string;
}

interface CostExplorerTableSizeSelectedEvent {
  table_size: number;
}

interface ConnectVersionSelectedEvent {
  driver_selected: string;
  version_selected: string;
}

interface MongooseLinkClickedEvent {
  link_text: string;
}

interface ProjectOverviewToggleClickedEvent {
  toggled: string;
}

interface BackupClusterTabClickedEvent {
  category: string;
  label: string;
}

interface RealTimePerformancePanelExpandedCollapsedEvent {
  index: $TSFixMe;
}

interface ClusterCardTooltipEvent {
  dataPercentUsed: string;
}

interface DeploymentsSearchBarUsedEvent {
  content: string;
}

interface AtlasClusterCardViewedEvent {
  project_cluster_count: number;
  cluster_instance_sizes: $TSFixMe;
  user_roles: $TSFixMe;
}

interface AtlasClusterCardEditConfigClickedEvent {
  clusterId: string;
}

interface AtlasClusterCardNewClusterClickedEvent {
  project_cluster_count: number;
  project_paid_cluster_count: $TSFixMe;
}

interface DeviceSyncOnboardingCreateServiceClickEvent {
  flow: string;
}

interface DeviceSyncOnboardingTemplateSelectClickEvent {
  template: string;
}

interface DeviceSyncLandingViewServiceClickEvent {
  productType: string;
}

interface SegmentUncollapsedEvent {
  segment_name: string;
}

interface ManageSQLSchemasEvent {
  context: string;
  action: string;
  data_lake_type: string;
}

interface SchemaUpdateScheduleActionEvent {
  context: string;
  action: string;
  frequency: string;
}

interface ProjectCreatedEvent {
  action: string;
  context: string;
  name: string;
}

interface QueryInsightRender {
  slowQueriesNum: number;
  clusterName: string;
}

interface CutFeatureBulletTooltipEntered {
  action: string;
  context: string;
  current_instance_size: InstanceSize;
  template_name: string;
  feature_bullet_text: string;
}

interface ClusterStarterTemplateCardsViewed {
  context: string;
  cards: Array<string>;
}

interface ConnectPageEvent {
  context: string;
  action?: string;
  connect_option?: string;
  connect_category?: string;
  value?: string;
}

interface IpAddressAddedEvent extends ConnectPageEvent {
  address_type: string;
}

interface ConnectResourceLinkClickedEvent extends ConnectPageEvent {
  name: string;
}

interface DownloadCompassClickedEvent extends ConnectPageEvent {
  os: string;
  version: string;
}

interface DriverSelectedEvent extends ConnectPageEvent {
  driver_selected: string;
}

interface AccessLinkClickedEvent extends ConnectPageEvent {
  view: string;
}

interface AppClientsDevToolsEvent {
  tab_state?: string;
  dev_tool_connect_option?: string;
}

interface CheckboxClickedEvent {
  action: string;
  checked: boolean;
  context: string;
  selected_tier?: string;
}

interface LoadSampleDatasetStatusBannerUXActionPerformed {
  action: string;
  context: string;
  page: string;
}

interface CSTOnPricingPageQueryParam {
  isValidClusterName: boolean;
  isValidQueryParam: boolean;
  isNewUser: boolean;
}

interface CreatedStreamProcessor {
  tenant_id: string;
}

interface ServiceAccountSecretCreatedEvent {
  selected_secret_expiry: string;
}

// @experiment AI Awareness Iteration | Jira Epic: CLOUDP-258816
interface AiAwarenessStacksModalEvent {
  cta_text?: string;
  dropdown?: string;
  tab_name?: string;
}

// A union of all possible tracking property shapes
export type TrackProperties =
  | AlertMessageEvent
  | TrackPropertiesLegacy
  | EloquaData
  | ExperimentEventViewed
  | TriggersPreimageStateChangeEvent
  | TriggersSavedEvent
  | DatabaseTriggersSaveEvent
  | TriggersDependencyConfigurationStartedEvent
  | RegionSelectedEvent
  | ClusterCardTipClick
  | LastVisitedDataExplorerUpdatedEvent
  | PurchaseButtonClickedEvent
  | ClusterSubmittedSuccessfullyEvent
  | ClusterBuilderErrorEventBase
  | ClusterBuilderSearchNodesCountChangedEvent
  | ClusterBuilderSearchTierChangedEvent
  | RegistrationFormSubmittedEvent
  | DataAPIEnableDisableEvent
  | DataAPISnippetCopiedEvent
  | DataAPIReturnTypeChangeEvent
  | PartnerLinkOrgButtonClickedEvent
  | FeatureUpdatesTriggerClickedEvent
  | IpWhitelistFormSubmittedEvent
  | CLTSidebarDrawerEventBase
  | CLTSidebarCodeSampleEventBase
  | LocalCurrencyExperimentProperties
  | SettingsToggleCheckedEvent
  | PagerDutyActivationEvent
  | CheckoutStepViewedEvent
  | PaymentInfoErrorEvent
  | PaymentInfoSubmittedSuccessfullyEvent
  | PaymentInfoEnteredEvent
  | PaymentInfoEditInitiatedEvent
  | PaymentSubmittedEvent
  | PersonalizationWizardFormSubmittedEvent
  | DataModelingTemplatesBase
  | MarketplaceFeedbackSubmitEvent
  | MarketplaceIntegrationCardClickedEvent
  | AtlasGoToCommandCompletedEvent
  | ChurnSurveyDowngrageSuggestionClicked
  | ResetPasswordEvent
  | ClusterStarterTemplate
  | ClusterStarterTemplateDeployLaterClickedEvent
  | ClusterStarterTemplateCardsViewed
  | ClusterDraftsTemplate
  | MFAEncouragementFirstFactorSetup
  | MFAEncouragementSecondFactorSetup
  | ChartsActivationEvent
  | HomeCenterFeedbackModuleSubmitEventBase
  | HomeCenterToolkitModuleItemClickEventBase
  | HomeCenterResourceModuleItemClickEventBase
  | HomeCenterApplicationClientsModuleItemClickEventBase
  | HomeCenterDeploymentSelectedEventBase
  | HomeCenterUpdatedLanguageEventBase
  | HomeCenterUXActionPerformed
  | HomeCenterClusterNavigationModalSelectorEvent
  | HomeCenterProductionTipCardEvent
  | HomeCenterProductionTipModuleSeenEvent
  | HomeCenterProductionTipModuleTabClickedEvent
  | CommandLineToolsButtonClickedEvent
  | PlainTextPasswordToggledEvent
  | AlertActionEvent
  | ResourceTaggingBaseEvent
  | ResourceTaggingSaveTagsEvent
  | ResourceTaggingAutocompleteClickEvent
  | StreamsOpenRequestAccessSurvey
  | StreamsCRUDConnection
  | StreamsCancelCRUDConnection
  | StreamsSetKafkaSecurityProtocol
  | StreamProcessingOverview
  | DataFederationSQLSchemaFormSaved
  | DataFederationSQLSchemaFormCancelled
  | IpAccessListEntryRemoved
  | TeamCreatedEvent
  | ProjectInviteEvent
  | SnippetEvent
  | ClusterDraftModalContinueEvent
  | ClusterTransactionLoadedEvent
  | VercelDataAPIToggleStatusEvent
  | VercelUnlinkClusterConfirmEvent
  | VercelLinkClusterConfirmEvent
  | TableFilterClickedEvent
  | TemplateDoorClickedEvent
  | ThemeSelectedEvent
  | CostExplorerTableSortedEvent
  | CostExplorerFiltersClearedEvent
  | CostExplorerTableSizeSelectedEvent
  | UsageDetailsFiltersClearedEvent
  | ConnectVersionSelectedEvent
  | MongooseLinkClickedEvent
  | ProjectOverviewToggleClickedEvent
  | BackupClusterTabClickedEvent
  | RealTimePerformancePanelExpandedCollapsedEvent
  | ClusterCardTooltipEvent
  | DeploymentsSearchBarUsedEvent
  | AtlasClusterCardViewedEvent
  | AtlasClusterCardEditConfigClickedEvent
  | AtlasClusterCardNewClusterClickedEvent
  | DeviceSyncOnboardingCreateServiceClickEvent
  | DeviceSyncOnboardingTemplateSelectClickEvent
  | DeviceSyncLandingViewServiceClickEvent
  | SegmentUncollapsedEvent
  | ManageSQLSchemasEvent
  | SchemaUpdateScheduleActionEvent
  | ProjectCreatedEvent
  | QueryInsightRender
  | CutFeatureBulletTooltipEntered
  | ConnectPageEvent
  | IpAddressAddedEvent
  | ConnectResourceLinkClickedEvent
  | DownloadCompassClickedEvent
  | DriverSelectedEvent
  | AccessLinkClickedEvent
  | AppClientsDevToolsEvent
  | CheckboxClickedEvent
  | LoadSampleDatasetStatusBannerUXActionPerformed
  | CSTOnPricingPageQueryParam
  | CreatedStreamProcessor
  | SelectivelyLoadSampleDatasetsEvent
  | ServiceAccountSecretCreatedEvent
  | ProgrammaticAccessEvent
  | CloudNavAnalyticsEvent['properties']
  | AiAwarenessStacksModalEvent;

interface RegistrationPage {
  signup_source: string;
  isFromMp: boolean;
  marketplaceSource: string;
}

interface LoginPage {
  isFromMp: boolean;
  marketplaceSource: string;
}

interface AtlasHomePageProperties {
  defaultTab?: string;
}

interface GenericPageProperties {
  title?: string;
  url?: string;
  path?: string;
  search?: string;
  referrer?: string;
  signup_source?: string;
  userId?: string;
  resend?: boolean;
  context?: string;
}

interface ClusterBuilderPageProperties {
  fromCta?: string;
  filterVariant?: string;
  prevUrl?: string;
  clusterCount: number;
}

interface ClusterStarterTemplatesPageProperties {
  fromCta?: string;
  clusterCount: number;
}

// A union of all possible page property shapes
export type PageProperties =
  | RegistrationPage
  | LoginPage
  | AtlasHomePageProperties
  | GenericPageProperties
  | ClusterStarterTemplatesPageProperties
  | ClusterBuilderPageProperties;

// The below are the only supported event names for Segment events
// Analytics should be sent with these event names, with more granular information as event properties
const SEGMENT_EVENTS = {
  UX_ACTION_PERFORMED: 'UX Action Performed',
  BUTTON_CLICKED: 'Button Clicked',
  DEPLOYMENT_SELECTED: 'Deployment Selected',
  TOOLTIP_VIEWED: 'Tooltip Viewed',
  MIGRATE_CLICKED: 'Migrate Clicked',
  MIGRATE_MODAL_EXITED: 'Migrate Model Exited',
  MIGRATE_MODAL_CLICKED_READY: 'Migrate Modal Clicked Ready',
  MIGRATE_MODAL_CLICKED_START: 'Migrate Modal Clicked Start',
  MIGRATE_RELATIONAL_DATABASE_LINK_CLICKED: 'Migrate from Relational Database Link Clicked',
  CREDENTIALS_PROVIDED: 'Credentials Provided',
  CUTOVER_STARTED: 'Cutover Started',
  REGISTRATION_FORM_SUBMITTED: 'Registration Form Submitted',
  REGISTRATION_FORM_SUBMITTED_BUSINESS_EMAIL: 'Registration Form Submitted - Business Email',
  REGISTRATION_FAILED: 'Registration Failed',
  REGISTRATION_SUCCESS_SEEN: 'Registration Success Seen',
  VERCEL_CONFIRMATION_SEEN: 'Vercel Confirmation Page Seen',
  FORM_SUBMITTED: 'Form Submitted',
  DATABASE_USER_FORM_SUBMITTED: 'Database User Form Submitted',
  IP_WHITELIST_FORM_SUBMITTED: 'IP Whitelist Form Submitted',
  IP_WHITELIST_ENTRY_REMOVED: 'IP Whitelist Entry Removed',
  IP_WHITELIST_ENTRY_EDITED: 'IP Whitelist Entry Edited',
  EXPERIMENT_VIEWED: 'Experiment Viewed',
  EXPERIMENT_GHOST_VIEWED: 'Experiment GhostViewed',
  PROJECT_SETTINGS_TOGGLE_CHECKED: 'Project Settings Toggle Checked',
  USER_SETTINGS_TOGGLE_CHECKED: 'User Settings Toggle Checked',
  MODAL_CLOSED: 'Modal Closed',
  MFA_ENCOURAGEMENT_SKIPPED: 'MFA Encouragement Skipped',
  MFA_ENCOURAGEMENT_FIRST_FACTOR_SETUP: 'MFA Encouragement First Factor Setup',
  MFA_ENCOURAGEMENT_SECOND_FACTOR_SETUP: 'MFA Encouragement Second Factor Setup',

  // events from billing overview page
  APPLY_CREDIT_MODAL_SHOWN: 'Apply Credit Modal Shown',
  APPLY_CREDIT_CODE_APPLIED_SUCCESSFULLY: 'Credit Activation Code Applied Successfully',

  COST_EXPLORER_PNG_EXPORT_CLICKED: 'Cost Explorer PNG Export Clicked',
  COST_EXPLORER_FILTERS_CLEARED: 'Cost Explorer Filters Cleared',
  COST_EXPLORER_FILTERS_COLLAPSED: 'Cost Explorer Filters Collapsed',
  COST_EXPLORER_TABLE_SIZE_SELECTED: 'Cost Explorer Table Size Selected',
  COST_EXPLORER_TABLE_SORTED: 'Cost Explorer Table Sorted',

  USAGE_DETAILS_FILTERS_CLEARED: 'Usage Details Filters Cleared',
  USAGE_DETAILS_FILTERS_COLLAPSED: 'Usage Details Filters Collapsed',

  CROSS_ORG_UNLINK_REQUEST_CREATED: 'Cross Organization Unlink Request Created',
  CROSS_ORG_UNLINK_REQUEST_CANCELLED: 'Cross Organization Unlink Request Cancelled',
  CROSS_ORG_UNLINK_REQUEST_MODAL_CLOSED: 'Cross Organization Unlink Request Modal Closed',

  // events only fired during cluster creation (cluster builder, starter templates, or payment page)
  CLUSTER_SUBMITTED_SUCCESSFULLY: 'Cluster Submitted Successfully',
  CLUSTER_PROVISIONED: 'Cluster Provisioned',
  /**
   * CLUSTER_TIER_BUMPED: when user selects region/provider where tier unavailable => auto selects tier
   * @repro Provider change: Dedicated (N. Virginia) -> choose M700 -> select GCP -> notice M10 now chosen
   * @repro Region change: Dedicated (N. Virginia) -> choose M700 -> select Sau Paulo -> notice M10 now chosen
   */
  CLUSTER_TIER_BUMPED: 'Cluster Tier Bumped',
  CLUSTER_BUILDER_ERROR: 'Cluster Builder Error',

  // events only fired from the payment page
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  DEPRECATED_CODE_PATH: 'Deprecated Code Path',
  ORDER_COMPLETED: 'Order Completed',
  PAYMENT_INFO_ERROR: 'Payment Info Error',
  PAYMENT_FULL_PAGE_SHOWN: 'Payment Full-Page Shown',
  PAYMENT_FULL_PAGE_CLOSED: 'Payment Full-Page Closed',
  PAYMENT_INFO_EDIT_INITIATED: 'Payment Info Edit Initiated',

  LOAD_SAMPLE_DATA_CLICKED: 'Load Sample Data Clicked',
  LOAD_SAMPLE_DATA_CANCELLED: 'Load Sample Data Cancelled',
  LOAD_SAMPLE_DATA_STARTED: 'Load Sample Data Started',
  SUPPORT_PLAN_ACTIVATED: 'Support Plan Activated',
  SUPPORT_PLAN_CANCELLED: 'Support Plan Cancelled',
  CLOUD_MANAGER_PLAN_UPGRADED: 'Cloud Manager Plan Upgraded',

  // Data Explorer CRUD Operations
  DOCUMENT_INSERTED: 'Document Inserted',
  DOCUMENT_INSERTED_MANY: 'Many Documents Inserted via JSON',
  DOCUMENT_CLONED: 'Document Cloned',
  DOCUMENT_CLONED_MANY: 'Document Cloned with Multiple Insertions via JSON',
  DOCUMENT_UPDATED: 'Document Updated',
  DOCUMENT_DELETED: 'Document Deleted',
  QUERY_EXECUTED: 'Query Executed',
  NAMESPACE_CREATED: 'Namespace Created',
  INDEX_CREATED: 'Index Created',
  INDEX_DELETED: 'Index Deleted',
  NAMESPACE_DELETED: 'Namespace Deleted',
  READ_ONLY_AGGREGATION_EXECUTED: 'Read-only Aggregation Executed',
  READ_WRITE_AGGREGATION_EXECUTED: 'Read-write Aggregation Executed',
  SEARCH_AGGREGATION_EXECUTED: 'Search Aggregation Executed',

  // Data Federation
  ADF_TEMPLATED_ONBOARDING: 'Data Federation - Templated Onboarding',

  // Peering
  PEERING_CONNECTION_SUBMITTED: 'Peering Connection Submitted',

  // MONGO NAV
  NAVIGATION_CLICKED: 'Navigation Clicked',
  TOP_NAV_SEARCH: 'Top Nav Search',

  // Online Archive
  VIEWED_ONLINE_ARCHIVE: 'Viewed Online Archive',

  // Query Insights
  VIEWED_QUERY_INSIGHTS: 'Viewed Query Insights',
  QUERY_INSIGHTS_BUTTON_RENDERED: 'Query Insights Button Rendered',

  // For all steppers:
  STEP_COMPLETED: 'Step Completed',

  // Cluster Card
  CLUSTER_CARD_VIEW_LOADED: 'Cluster Card View Loaded',

  // Recommend Atlas Search Banner
  RECOMMEND_ATLAS_SEARCH_BANNER_LOADED: 'Recommend Atlas Search Banner Loaded',

  // Cloud Feature Updates
  CLOUD_FEATURE_UPDATES_TRIGGER_CLICKED: 'Cloud Feature Updates Trigger Clicked',

  // Sample Data Load
  CLICKED_ADD_SAMPLE_DATA: 'Clicked Add Sample Data',

  // Local Currency
  CLICKED_CURRENCY_SELECTOR: 'Clicked Currency Selector',
  CLICKED_CURRENCY_TOGGLE: 'Clicked Currency Toggle',

  // Go To
  GO_TO_SHOWN: 'Atlas GoTo Shown',
  GO_TO_COMPLETED: 'Atlas GoTo Command Completed',
  GO_TO_TOAST_SHOWN: 'Atlas GoTo Toast Shown',

  // Cluster Edit Config Button
  CLUSTER_CARD_EDIT_CONFIG_BUTTON_OPENED: 'Cluster Card Edit Config Button Opened',
  CLUSTER_CARD_EDIT_CONFIG_BUTTON_CLICKED: 'Cluster Card Edit Config Button Clicked',

  // Leafygreen toast events
  TOAST_SHOWN: 'Toast Shown',
  TOAST_REDIRECT: 'Toast Redirect',

  // Marketplace
  ECOSYSTEM_VIEWED: 'Ecosystem',
  INTEGRATION_FEEDBACK_SUBMITTED: 'Integration Feedback Submitted',
  INTEGRATION_PAGE_VIEWED: 'Integration',
  INTEGRATION_CARD_CLICKED: 'Integration Card Clicked',
  FEEDBACK_CARD_CLICKED: 'Feedback Card Clicked',
  FEEDBACK_PAGE_VIEWED: 'Feedback',
  DOCUMENT_CARD_CLICKED: 'Document Card Clicked',
  BACK_BUTTON_CLICKED: 'Back Button Clicked',
  SIDE_NAV_CLICKED: 'Side Nav Clicked',
  TRENDING_SEE_MORE_CLICKED: 'Trending See More',
  POPULAR_SEE_MORE_CLICKED: 'Popular See More',
  INTEGRATIONS_AND_TOOLS_CLICKED: 'Integrations and Tools',
  SUBMIT_INTEGRATION_IDEA_LINK_CLICKED: 'Submit an Integration Idea Link',
  SUBMIT_YOUR_IDEA_CARD_CLICKED: 'Submit Your Idea Card',
  HAVE_AN_IDEA_CLICKED: 'Have an Idea',
  ALL_INTEGRATION_PAGE_VIEWED: 'All Integrations Page Viewed',

  // Cluster Drafts
  // The Cluster Drafts feature has undergone several name changes, but segment events have retained their original
  // names at the preference of the analytics team for consistency.
  // This is why various terminologies are used interchangeably to refer to cluster drafts.
  // For more information, refer to this link: https://wiki.corp.mongodb.com/pages/viewpage.action?pageId=276635097
  CLUSTER_CONFIGURATION_RESET: 'Cluster Configuration Reset',
  CLUSTER_TRANSACTION_LOADED: 'Cluster Transaction Loaded',
  CLUSTER_DRAFT_NOT_FOUND: 'Cluster Draft Not Found',
  CLUSTER_DRAFT_NOT_VALID: 'Cluster Draft Not Valid',

  // CDN Failed to Load
  CDN_BLOCKED: 'CDN Blocked',

  // Charts
  CHARTS_AUTOMATIC_ACTIVATION: 'Charts Activation Automatically Requested',

  // Home Center Events
  HOME_CENTER_FEEDBACK_CARD_DISMISSED: 'Feedback Card Dismissed',
  HOME_CENTER_FEEDBACK_SUBMITTED: 'Feedback Submitted',
  HOME_CENTER_UPDATED_PROGRAMMING_LANGUAGE: 'Updated Programming Language',
  HOME_CENTER_MIGRATION_RESOURCES_TOGGLED: 'Migration Resources Toggled',
  TAB_CLICKED: 'Application Development Module Tab Clicked',

  // Vercel
  VERCEL_DATA_API_TOGGLE_STATUS: 'Vercel Data API Toggle Status',
  VERCEL_LINK_CLUSTER_CONFIRM: 'Vercel Link Cluster Confirm',
  VERCEL_UNLINK_CLUSER_CONFIRM: 'Vercel Unlink Cluster Confirm',

  // Streamlined Onboarding
  AUTO_SETUP_ACTION: 'Auto Setup Action Performed',
  PLAIN_TEXT_PASSWORD_IN_CONNECTION_STRING_TOGGLED: 'Plain Text Password in Connection String Toggled',

  // Data Services Triggers
  TRIGGERS_DEPENDENCY_CONFIGURATION_STARTED: 'Data Services Triggers Dependency Configuration Started',
  TRIGGERS_LOGS_SEARCHED: 'Triggers Logs Searched',
  TRIGGERS_SAVED: 'Triggers Saved',
  TRIGGERS_DEPENDENCY_UPLOADED: 'Triggers Dependencies node_modules Folder Uploaded',

  // Snippets
  FUNCTION_SNIPPET_BUTTON_CLICKED: 'Show Function Snippets',
  FUNCTION_SNIPPET_DETAILS_SELECTED: 'Function Snippet Details selected',
  SNIPPET_INSERT_CLICKED: 'Snippet Inserted',
  SNIPPET_COPY_CLICKED: 'Snippet Copied',
  FUNCTION_SNIPPET_VIEW_GITHUB_URL: 'View Github URL for the Function Snippet',

  // Device Sync
  SYNC_EMPTY_STATE_VIEWED: 'Viewed Device Sync Empty State',
  SYNC_APP_EXISTS_VIEWED: 'Viewed Device Sync App Exists State',

  // Resource Tagging
  RESOURCE_TAGS_SAVED_SUCCESSFULLY: 'Resource Tags Saved Successfully',
  RESOURCE_TAGS_AUTOCOMPLETE_ITEM_CLICKED: 'Resource Tags Autocomplete Item Clicked',

  // Churn Survey
  CHURN_SURVEY_DOWNGRADE_SUGGESTION_CLICKED: 'Churn Survey Downgrade Suggestion Clicked',

  // Atlas Stream Processing
  STREAMS_REQUEST_ACCESS_LOADED: 'Request Access Page Loaded',

  // Dark Mode
  OS_DARK_MODE_DETECTED: 'OS Dark Mode In Use',
  THEME_SELECTED: 'Theme Selected',

  // Production Tips
  PRODUCTION_TIPS_MODULE_SEEN: 'Production Tips Module Seen',
  PRODUCTION_TIPS_TIP_DISMISSED: 'Tip Dismissed',
  PRODUCTION_TIPS_LEARN_MORE_CLICKED: 'Learn More Link Clicked',
  PRODUCTION_TIPS_UPGRADE_CLUSTER_CLICKED: 'Upgrade Cluster Link Clicked',
  PRODUCTION_TIPS_CARD_SEEN: 'Production Tips Card Seen',

  // Limits & Alerts Production Tips
  LIMITS_ALERTS_ALERT_SETTINGS: 'Alert Settings Link Clicked',

  // Alert Message Actions
  // Alert Ack Modal Opened From Message
  ALERT_ACK_OPENED: 'Alert Ack Modal Opened From Message Link',
  // Alert Config Edit Modal Opened From Message
  ALERT_CONFIG_OPENED: 'Alert Config Modal Opened From Message Link',

  // Project Creation
  PROJECT_CREATED: 'Project Created',

  // Migrators Onboarding
  RECOMMENDED_MIGRATOR_RESOURCE_LINK_CLICKED: 'Recommended Migrator Resource Link Clicked',

  // Quick Connect Modal
  QUICK_ACCESS_MODAL_VIEWED: 'Quick Access Modal Viewed',

  // Cluster Starter Templates
  CLUSTER_STARTER_TEMPLATES_CARDS_VIEWED: 'Cluster Starter Templates Cards Viewed',

  // Connect Page
  IP_ADDRESS_ADDED: 'IP Address Added',
  DATABASE_USER_ADDED: 'Database User Added',
  CONNECT_OPTION_SELECTED: 'Connect Option Selected',
  CONNECTION_CATEGORY_SELECTED: 'Connection Category Selected',
  SEGMENTED_CONTROL_TOGGLED: 'Segmented Control Toggled',
  CONNECTION_STRING_COPIED: 'Connection String Copied',
  CONNECTION_COMMAND_COPIED: 'Connection Command Copied',
  FULL_CODE_EXAMPLE_COPIED: 'Full Code Example Copied',
  CONNECT_RESOURCE_LINK_CLICKED: 'Connect Resource Link Clicked',
  DRIVER_INSTALL_COMMAND_COPIED: 'Driver Install Command Copied',
  INSTALLATION_CHECKBOX_TOGGLED: 'Installation Checkbox Toggled',
  DRIVER_SELECTED: 'Driver Selected',
  DATABASE_ACCESS_LINK_CLICKED: 'Database Access Link Clicked',
  NETWORK_ACCESS_LINK_CLICKED: 'Network Access Link Clicked',

  // Dev Tools Awareness on AHO
  DEV_TOOL_CARD_BUTTON_CLICKED: 'Dev Tool Card Button Clicked',
  DATA_EXPLORER_LINK_CLICKED: 'Data Explorer Link Clicked',
  RECOMMENDATION_LINK_CLICKED: 'Recommendation Link Clicked',
  DEV_TOOL_TOOLTIP_HOVERED: 'Dev Tool Tooltip Hovered',

  // @experiment CST on Pricing Page | Jira Epic: CLOUDP-221041
  WWW_PRICING_PAGE_EXPERIMENT_VIEWED: 'WWW Pricing Page Experiment Viewed',
  ROUTED_TO_FPP_FROM_LANDING_PAGE: 'Routed to FPP From Landing Page',
  FREE_CLUSTER_LIMIT_REACHED: 'Free Cluster Limit Reached',
  INVALID_CLUSTER_QUERY_PARAM: 'Invalid Cluster Query Param',
  INVALID_CLUSTER_NAME: 'Invalid Cluster Name',

  // @experiment Dedicated Tier Feature Awareness | Jira Epic: CLOUDP-239358
  PRODUCT_FEATURE_WALL_BUTTON_CLICKED: 'Product Feature Wall Button Clicked',
  PRODUCT_FEATURE_WALL_FEATURE_CLICKED: 'Product Feature Wall Feature Clicked',
  PRODUCT_FEATURE_WALL_LINK_CLICKED: 'Product Feature Wall Link Clicked',

  // @experiment Data Module on Atlas Home Page | Jira Epic: CLOUDP-242106
  CLICKED_ADVANCED_DATA_FEATURE: 'Clicked Advanced Data Feature',
  CLICKED_DATA_EXPLORER_FEATURE: 'Clicked Data Explorer Feature',

  // Service Accounts
  SERVICE_ACCOUNT_SECRET_CREATED: 'Service Account Secret Created',

  // @experiment Revamped Command Line Tools Products Page in Atlas | Jira Epic: CLOUDP-251975
  CLI_TOOLS_BANNER_SHOWN: 'CLI Tools Banner Shown',
  CLI_TOOLS_BANNER_CLOSED: 'CLI Tools Banner Closed',
  CLI_TOOLS_BANNER_LINK_CLICKED: 'CLI Tools Banner Link Clicked',
  CLICKED_CLI_TOOL: 'Clicked on CLI/IaC tool',
  CLICKED_NEXT_STEPS: 'Next Steps Resource Clicked',
  PROGRAMMATIC_ACCESS_SIDE_NAV_CLICKED: 'Programmatic Access Side Nav Clicked',
  PROGRAMMATIC_ACCESS_PAGE_VIEWED: 'Programmatic Access Page Viewed',
  PROGRAMMATIC_ACCESS_FEEDBACK_CARD_DISMISSED: 'Programmatic Access Feedback Card Dismissed',
  PROGRAMMATIC_ACCESS_FEEDBACK_SUBMITTED: 'Programmatic Access Feedback Submitted',

  // Feature awareness overlay
  FEATURE_AWARENESS_OVERLAY_VIEWED: 'Feature Awareness Overlay Viewed',
  FEATURE_AWARENESS_OVERLAY_CLOSED: 'Feature Awareness Overlay Closed',

  // @experiment AI Awareness Iteration | Jira Epic: CLOUDP-258816
  AI_VECTOR_SEARCH_CTA_CLICKED: 'AI & Vector Search CTA Clicked',
  STACKS_MODAL_TAB_CLICKED: 'Stacks Modal Tab Clicked',
  STACKS_MODAL_DROPDOWN_CHANGED: 'Stacks Modal Dropdown Changed',
  STACKS_MODAL_CTA_CLICKED: 'Stacks Modal CTA Clicked',
} as const;

const PAGE_CALLS = {
  REGISTRATION_SUCCESS: 'Registration Success',
  SIGN_IN: 'Sign In',
  REGISTRATION: 'Registration',
  TERMS_OF_SERVICE: 'Terms of Service',
  ATLAS_SEARCH: 'Atlas Search',
  FLEX_CONSULTING: 'Flex Consulting',
  OPEN_PROJECT_ALERTS: 'Open Project Alerts',
  VERIFICATION_EMAIL: 'Verification Email',
  VERIFICATION_EMAIL_BANNER: 'Verification Email Banner',
  MANAGE_PROJECT_ALERTS: 'Manage Project Alerts',
  OPEN_ORG_ALERTS: 'Open Org Alerts',
  MANAGE_ORG_ALERTS: 'Manage Org Alerts',
  ALL_CLUSTERS: 'All Clusters',
  DATA_API: 'Data Api',
  DATA_LAKE_PIPELINE: 'Data Lake Pipeline',
  DATA_FEDERATION: 'Data Federation',
  DEVICE_SYNC: 'Device Sync',
  TRIGGERS: 'Triggers',
  CLUSTER_STARTER_TEMPLATES: 'Cluster Starter Templates',
  CLUSTER_UPGRADE_TEMPLATES: 'Cluster Upgrade Templates',
  MFA_ENCOURAGEMENT_PROMPT: 'MFA Encouragement Prompt',
  LIVE_MIGRATION: 'Live Migration',
  HOME_CENTER: 'Atlas Home Center',
  CLUSTER_BUILDER: 'Cluster Builder',
} as const;

const buildIntegrationsObject = (orgId: string) => ({
  Amplitude: {
    groups: {
      Organization: orgId,
    },
  },
});

// defining the variable locally here so it can be mocked by Sinon in tests
// if we straight re-export it, it will error out as a non-configurable property
const sanitizeLocation = sanitizeWindowLocation;

export default analytics;
export {
  initializeAnalytics,
  SEGMENT_EVENTS,
  PAGE_CALLS,
  buildIntegrationsObject,
  sanitizeLocation,
  SanitizeLocationOptions,
  ENVIRONMENT,
};

export type { HeliotropeReturn };
