import {
  CostExplorerFilterOptions,
  FetchYayPayLinkResponseView,
  PartnerCustomerLinkOrgResult,
  PartnerCustomerLinkOrgStatus,
  PartnerSubscriptionStatusResult,
  PartnerType,
  PullForwardRequestUpdateResponse,
  UsageDetailsFilterOptions,
  UsageSummary,
} from '@packages/types/billing';

import { getDateFromBillingAnalyticsShortString } from '@packages/common/utils/billingHelpers';

import fetchWrapper, { formParams } from './fetchWrapper';

export function getMetadata() {
  return fetchWrapper('/billing/metadata', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getBillingStatus({ orgId }) {
  return fetchWrapper(`/billing/orgs/${orgId}/billingStatus`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getUsageSummary({ orgId }): Promise<UsageSummary> {
  return fetchWrapper(`/billing/usageSummary/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getCrossOrgTotal({ orgId }) {
  return fetchWrapper(`/billing/crossOrgTotalSummary/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getBraintreeCustomer({ orgId }) {
  return fetchWrapper(`/billing/braintreeCustomer/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getEstimatedCost({ orgId, numDays }) {
  return fetchWrapper(`/billing/estimateBasedOnPastUsage/${orgId}/${numDays}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function activateSelfServePlan({
  orgId,
  selfServeProductType,
}: {
  orgId: string;
  selfServeProductType: string;
}) {
  return fetchWrapper(`/selfServe/activate/orgs/${orgId}/productType/${selfServeProductType}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function getPlanBillingDetails(orgId, productType) {
  return fetchWrapper(`/selfServe/reactivationDetails/org/${orgId}?productType=${productType}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function cancelSelfServeSupportPlan({ orgId, productType }: { orgId: string; productType: string }) {
  return fetchWrapper(`/selfServe/cancel/orgs/${orgId}/productType/${productType}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function getHistoricSelfServePlansForOrg({ orgId }) {
  return fetchWrapper(`/selfServe/selfServePlans/orgs/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAllInvoices({ orgId }) {
  return fetchWrapper(`/billing/allInvoices/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAllLinkedInvoices({ orgId }) {
  return fetchWrapper(`/billing/allLinkedInvoices/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getPlan({ orgId, plan }) {
  return fetchWrapper(`/billing/plan/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getPrepaidPlans({ orgId }) {
  return fetchWrapper(`/billing/prepaidPlans/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoice({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/invoice/${orgId}/${invoiceId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getNonCreditLineItems({ orgId, invoiceId, canViewZeroCentLineItems }) {
  let excludeZeroCentLineItems = !canViewZeroCentLineItems;
  return fetchWrapper(
    `/billing/invoiceLineItems/${orgId}/${invoiceId}?nonCredit=true&excludeZeroCent=${excludeZeroCentLineItems}`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function getLineItemsBySKU({ orgId, invoiceId, sku }) {
  return fetchWrapper(`/billing/invoiceLineItems/${orgId}/${invoiceId}?sku=${sku}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoiceByDate({ orgId, date }) {
  return fetchWrapper(`/billing/invoiceByDate/${orgId}/${date}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoiceByInvoiceId({ invoiceId }) {
  return fetchWrapper(`/admin/billing/search/invoices/${invoiceId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoiceByPaymentId({ paymentId }) {
  return fetchWrapper(`/admin/billing/search/payments/${paymentId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoiceByStripeChargeId({ stripeChargeId: stripeChargeIdOrPaymentIntentId }) {
  return fetchWrapper(`/admin/billing/search/charges/${stripeChargeIdOrPaymentIntentId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoiceAddress({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/invoiceAddress/${orgId}/${invoiceId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getLineItems({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/invoiceLineItems/${orgId}/${invoiceId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getPerProjectPriceCents({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/perProjectPriceCents/${orgId}/${invoiceId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getCreditDrawdownPayments({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/orgs/${orgId}/invoices/${invoiceId}/creditDrawdownPayments`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getNonMonthlyCommitmentPayments({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/orgs/${orgId}/invoices/${invoiceId}/nonMonthlyCommitmentPayments`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getOrgPreferences({ orgId }) {
  return fetchWrapper(`/billing/orgPreferences/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoiceMonthlyCommitmentPayments({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/orgs/${orgId}/invoices/${invoiceId}/monthlyCommitmentPayments`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getRefunds({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/invoiceRefunds/${orgId}/${invoiceId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoiceSkuServiceSummaries({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/invoiceSkuServiceSummary/${orgId}/${invoiceId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoiceBackupSummary({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/invoiceBackupSummary/${orgId}/${invoiceId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getInvoiceSupportSummary({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/invoiceSupportSummary/${orgId}/${invoiceId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getStripeSetupIntentSecret({ orgId, billingAccount }) {
  return fetchWrapper(`/billing/stripeSetupIntentSecret/${orgId}/${billingAccount}`, {
    method: 'GET',
  }).then((resp) => resp.text());
}

export function getAvailableActivationCode({ activationCode }) {
  return fetchWrapper(`/billing/activationCode/${activationCode}`, {
    method: 'GET',
  }).then((resp) => resp.text());
}

export function retryPayment({ orgId, paymentId }) {
  return fetchWrapper(`/billing/retryPayment/${orgId}/${paymentId}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function confirmPayment({ orgId, paymentId }) {
  return fetchWrapper(`/billing/confirmPayment/${orgId}/${paymentId}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function forgivePayment({ orgId, paymentId, reason }) {
  const params = {
    reason,
  };

  return fetchWrapper(`/billing/forgivePayment/${orgId}/${paymentId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function partialPayment({ orgId }) {
  return fetchWrapper(`/billing/partialPayment/${orgId}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function fullRefund({ orgId, paymentId, reason, revenueRefundReason }) {
  const params = {
    reason,
    revenueRefundReason,
  };

  return fetchWrapper(`/billing/fullRefund/${orgId}/${paymentId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function partialRefund({ orgId, paymentId, amountCents, reason, revenueRefundReason }) {
  const params = {
    amountCents,
    reason,
    revenueRefundReason,
  };

  return fetchWrapper(`/billing/partialRefund/${orgId}/${paymentId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function chargeThenRefund({ orgId, paymentId, reason, revenueRefundReason }) {
  const params = {
    reason,
    revenueRefundReason,
  };

  return fetchWrapper(`/billing/chargeThenRefund/${orgId}/${paymentId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function taxRefund({ paymentId, reason }) {
  const params = {
    reason,
  };

  return fetchWrapper(`/billing/taxRefund/${paymentId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function payByCheck({ orgId, invoiceId, checkNumber, externalNote, dateReceived }) {
  const params = {
    checkNumber,
    externalNote,
    dateReceived,
  };
  return fetchWrapper(`/billing/invoicePayments/payByCheck/${orgId}/${invoiceId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  });
}

export function payByWireTransfer({ orgId, invoiceId, wireTransferNumber, externalNote, dateReceived }) {
  const params = {
    wireTransferNumber,
    externalNote,
    dateReceived,
  };
  return fetchWrapper(`/billing/invoicePayments/payByWireTransfer/${orgId}/${invoiceId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  });
}

export function getCompanyAddress({ orgId }) {
  return fetchWrapper(`/billing/companyAddress/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateCompanyAddress({ orgId, address }) {
  return fetchWrapper(`/billing/companyAddress/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(address),
  }).then((resp) => resp.json());
}

export function updateOrgPreferences({ orgId, sendInvoiceOnlyToBillingEmail }) {
  return fetchWrapper(`/billing/orgPreferences/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(sendInvoiceOnlyToBillingEmail),
  }).then((resp) => resp.json());
}

export async function pullForwardCredit(creditId: string, orgId: string) {
  return fetchWrapper(`/billing/pullForwardCredit/${orgId}/${creditId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    credentials: 'include',
  }).then((resp) => resp.json());
}

export function applyCouponOrActivationCode({ orgId, couponCode, centralUrl = '', allowPullforward = true }) {
  const params = {
    couponCode,
    allowPullforward,
  };

  return fetchWrapper(`${centralUrl}/billing/applyCouponOrActivationCode/${orgId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include',
    method: 'POST',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function validateAddress({ orgId, address }) {
  return fetchWrapper(`/billing/validateAddress/${orgId}`, {
    method: 'POST',
    body: JSON.stringify(address),
  });
}

export function validateVATID({ orgId, country, vatNumber }) {
  const params = {
    country,
    vatNumber,
  };
  return fetchWrapper(`/billing/validateVATID/${orgId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  });
}

export function getPaymentMethods(orgId, useEffectivePayingOrg) {
  return fetchWrapper(`/billing/paymentMethods/${orgId}?useEffectivePayingOrg=${useEffectivePayingOrg}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAnyFailedPayments({ orgId }) {
  return fetchWrapper(`/billing/anyFailedPayments/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updatePaymentMethod({
  orgId,
  billingAccount,
  stripePaymentMethodId,
  stripeSetupIntentId,
  vatNumber,
  couponCode,
  linkBraintree,
  addrLine1,
  addrLine2,
  city,
  state,
  zip,
  country,
}: {
  orgId: string;
  billingAccount?: string | null;
  stripePaymentMethodId?: string | null;
  stripeSetupIntentId?: string | null;
  vatNumber?: string | null;
  couponCode?: string | null;
  linkBraintree: boolean;
  addrLine1?: string | null;
  addrLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
}) {
  const params = {
    billingAccount,
    stripePaymentMethodId,
    stripeSetupIntentId,
    vatNumber,
    couponCode,
    linkBraintree,
    addrLine1,
    addrLine2,
    city,
    state,
    zip,
    country,
  };
  return fetchWrapper(`/billing/updatePaymentMethod/${orgId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function deletePaymentMethod({ orgId }) {
  return fetchWrapper(`/billing/paymentMethods/${orgId}`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function hasEffectivePaymentMethod({ orgId }: { orgId: string }): Promise<boolean> {
  return fetchWrapper(`/billing/hasEffectivePaymentMethod/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function hasCreditAvailableAsPaymentMethod({ orgId }: { orgId: string }): Promise<boolean> {
  return fetchWrapper(`/billing/hasCreditAvailableAsPaymentMethod/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getPendingInvoice({ orgId }) {
  return fetchWrapper(`/billing/pendingInvoice/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getRecentInvoice({ orgId }) {
  return fetchWrapper(`/billing/recentInvoice/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getCoupons({ couponSearchTxt, sortBy, isAscending, startIndex, numberOfCoupons }) {
  return fetchWrapper(
    `/admin/billing/coupons/percentoff?searchTxt=${couponSearchTxt}&sortBy=${sortBy}&isAscending=${isAscending}&startIndex=${startIndex}&numberOfCoupons=${numberOfCoupons}`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function getAgreementByActivationCode({ activationCode }) {
  return fetchWrapper(`/admin/usagereporting/agreement?activationCode=${activationCode}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getUsageReportingFailures() {
  return fetchWrapper(`/admin/usagereporting/failures`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function createCoupon({ code, description, duration, maxApplications, percentDiscount, selectedSKUNames }) {
  const params = JSON.stringify({
    code,
    _t: 'PERCENT_OFF',
    description,
    durationDays: duration,
    maxApplicationsPerOrg: maxApplications,
    percentDiscount,
    skus: selectedSKUNames,
  });

  return fetchWrapper(`/admin/billing/coupons/percentoff`, {
    method: 'POST',
    body: params,
  }).then((resp) => resp.json());
}

export function updateCoupon({ couponId, durationDays, percentDiscount }) {
  const params = JSON.stringify({
    durationDays,
    percentDiscount,
  });

  return fetchWrapper(`/admin/billing/coupons/percentoff/${couponId}`, {
    method: 'PATCH',
    body: params,
  }).then((resp) => resp.json());
}

export function deleteCoupon({ couponId }) {
  return fetchWrapper(`/admin/billing/coupons/percentoff/${couponId}`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function getAllCredits() {
  return fetchWrapper('/admin/billing/credits/credits', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAuditResults() {
  return fetchWrapper('/admin/billing/audit/results', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getHistoricResultsForAuditor(auditorName) {
  return fetchWrapper(`/admin/billing/audit/results/${auditorName}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function issueServiceCredit({
  amountCents,
  orgId,
  reason,
  note,
  isValidPaymentMethod,
  revenueReason,
  startDate,
}) {
  const params = JSON.stringify({
    amountCents,
    orgId,
    reason,
    note,
    isValidPaymentMethod,
    revenueReason,
    startDate,
  });

  return fetchWrapper('/admin/billing/credits/serviceCredit', {
    method: 'POST',
    body: params,
  }).then((resp) => resp.json());
}

export function getCredits({ orgId, filter }) {
  return fetchWrapper(`/billing/credits/${orgId}${filter ? `?filter=${filter}` : ''}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMonthlyCommitmentCredits({ orgId }) {
  return fetchWrapper(`/billing/monthlyCommitmentCredits/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function anyActivePaymentMethod({ orgId }) {
  return fetchWrapper(`/billing/anyActivePaymentMethod/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function anyActivePrepaidCredits({ orgId }) {
  return fetchWrapper(`/billing/anyActivePrepaidCredits/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getPromoCodes({ promoSearchTxt, sortBy, isAscending, startIndex, numberOfPromos }) {
  return fetchWrapper(
    `/admin/billing/coupons/dollaroff?searchTxt=${promoSearchTxt}&sortBy=${sortBy}&isAscending=${isAscending}&startIndex=${startIndex}&numberOfPromos=${numberOfPromos}`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function createPromoCode({
  code,
  description,
  startDate,
  endDate,
  maxApplications,
  dollarOffAmountInCents,
  selectedPlanTypeNames,
  isValidPaymentMethod,
  hourlyBillingLimitCents,
  internal,
  familyId,
  promotionName,
}) {
  const params = JSON.stringify({
    code,
    _t: 'DOLLAR_OFF',
    description,
    startDate,
    endDate,
    maxApplicationsAcrossAllOrgs: maxApplications,
    dollarOffAmountInCents,
    planTypes: selectedPlanTypeNames,
    isValidPaymentMethod,
    hourlyBillingLimitCents,
    internal,
    familyId,
    promotionName,
  });

  return fetchWrapper(`/admin/billing/coupons/dollaroff`, {
    method: 'POST',
    body: params,
  }).then((resp) => resp.json());
}

export function createMultiplePromoCodes({
  code,
  description,
  startDate,
  endDate,
  maxApplications,
  dollarOffAmountInCents,
  selectedPlanTypeNames,
  isValidPaymentMethod,
  hourlyBillingLimitCents,
  numberOfCoupons,
  familyId,
  promotionName,
}) {
  const params = JSON.stringify({
    code,
    _t: 'DOLLAR_OFF',
    description,
    startDate,
    endDate,
    maxApplicationsAcrossAllOrgs: maxApplications,
    dollarOffAmountInCents,
    planTypes: selectedPlanTypeNames,
    isValidPaymentMethod,
    hourlyBillingLimitCents,
    familyId,
    promotionName,
  });

  return fetchWrapper(`/admin/billing/coupons/dollaroff/${numberOfCoupons}`, {
    method: 'POST',
    body: params,
  }).then((resp) => resp.json());
}

export function updatePromoCode({
  promoCodeId,
  maxApplicationsAcrossAllOrgs,
  endDate,
  dollarOffAmountInCents,
  isValidPaymentMethod,
  hourlyBillingLimitCents,
  internal,
  familyId,
}) {
  const params = JSON.stringify({
    maxApplicationsAcrossAllOrgs,
    endDate,
    dollarOffAmountInCents,
    isValidPaymentMethod,
    hourlyBillingLimitCents,
    internal,
    familyId,
  });

  return fetchWrapper(`/admin/billing/coupons/dollaroff/${promoCodeId}`, {
    method: 'PATCH',
    body: params,
  }).then((resp) => resp.json());
}

export function deletePromoCode({ promoCodeId }) {
  return fetchWrapper(`/admin/billing/coupons/dollaroff/${promoCodeId}`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function getDiscounts({ orgId }) {
  return fetchWrapper(`/billing/discounts/${orgId}?activeDiscounts=true`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getHistoricDiscounts({ orgId }) {
  return fetchWrapper(`/billing/discounts/${orgId}?activeDiscounts=false`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getDiscountsFromCouponId({ couponId }) {
  return fetchWrapper(`/admin/billing/coupons/percentoff/${couponId}/discounts`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function unapplyCouponDiscount({ couponId, discountId }) {
  return fetchWrapper(`/admin/billing/coupons/percentoff/${couponId}/discounts/${discountId}`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function createCouponFamily({ name }) {
  const params = {
    name,
  };

  return fetchWrapper(`/admin/billing/coupons/family`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function getCouponFamilies() {
  return fetchWrapper(`/admin/billing/coupons/family`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateBillingLimits({
  orgId,
  hourlyBillingLimitCents,
  maxOutstandingBillCents,
  maxOutstandingBillCapCents,
}) {
  const params = {
    hourlyBillingLimitCents,
    maxOutstandingBillCents,
    maxOutstandingBillCapCents,
  };

  return fetchWrapper(`/billing/billingLimits/${orgId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'PATCH',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function updatePlan({ orgId, plan }) {
  return fetchWrapper(`/billing/plan/${orgId}`, {
    method: 'PATCH',
    body: JSON.stringify(plan),
  }).then((resp) => resp.json());
}

export function getBraintreeClientToken({ orgId }) {
  return fetchWrapper(`/billing/braintreeClientToken/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function linkBraintreeCustomer({ orgId, paymentMethodNonce, billingAddress }) {
  return fetchWrapper(`/billing/braintreeCustomer/${orgId}`, {
    method: 'POST',
    body: JSON.stringify({
      paymentMethodNonce,
      type: 'PayPalAccount',
      billingAddress,
    }),
  });
}

export function getPayingOrganization({ orgId }) {
  return fetchWrapper(`/billing/payingOrg/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getLinkableOrgView({ orgId }) {
  return fetchWrapper(`/billing/payingOrg/linkableOrgView/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getLinkableOrganizations({ orgId }) {
  return fetchWrapper(`/billing/payingOrg/linkableOrgs/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getLinkedOrganizations({ orgId }) {
  return fetchWrapper(`/billing/payingOrg/${orgId}/linked`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function linkToPayingOrg({ payingOrgId, linkedOrgIds }) {
  return fetchWrapper(`/billing/payingOrg/linkToPayingOrg/${payingOrgId}`, {
    method: 'POST',
    body: JSON.stringify({
      payingOrgId,
      linkedOrgIds,
    }),
  }).then((resp) => resp.json());
}

export function createUnlinkRequest({ payingOrgId, linkedOrgId, activationCode }) {
  return fetchWrapper(`/billing/cross-org/paying-org/${payingOrgId}/linked-org/${linkedOrgId}/unlink-request`, {
    method: 'POST',
    body: JSON.stringify({
      activationCode: activationCode,
    }),
  }).then((resp) => {
    return resp.text().then((text) => {
      return text ? JSON.parse(text) : {};
    });
  });
}

export function getPendingUnlinkRequest(linkedOrgId: string) {
  return fetchWrapper(`/billing/cross-org/linked-org/${linkedOrgId}/unlink-request`, {
    method: 'GET',
  }).then((resp) => {
    return resp.text().then((text) => {
      return text ? JSON.parse(text) : {};
    });
  });
}

export function cancelUnlinkRequest(
  payingOrgId: string,
  linkedOrgId: string,
  unlinkRequestId: string,
  applyCodeToParentOrg: boolean
) {
  return fetchWrapper(
    `/billing/cross-org/paying-org/${payingOrgId}/linked-org/${linkedOrgId}/unlink-request/${unlinkRequestId}/cancel`,
    {
      method: 'PUT',
      body: JSON.stringify({
        applyCodeToParentOrg: applyCodeToParentOrg,
      }),
    }
  ).then((resp) => {
    return resp.text().then((text) => {
      return text ? JSON.parse(text) : {};
    });
  });
}

export function isEligibleToBecomePayingOrg({ orgId }) {
  return fetchWrapper(`/billing/payingOrg/${orgId}/eligible`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function purchaseConsultingUnits({ orgId, units }) {
  const params = { units };
  return fetchWrapper(`/billing/flexConsulting/purchase/${orgId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams(params),
  }).then((resp) => resp.json());
}

export function getConsultingUnitPrice({ orgId }) {
  return fetchWrapper(`/billing/flexConsulting/price/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function validateActivationCodePullForwardEligible({
  orgId,
  couponCode,
}): Promise<PullForwardRequestUpdateResponse> {
  return fetchWrapper(`/billing/validateActivationCodePullForwardEligible/${orgId}/${couponCode}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getConsultingDepths({ orgId }) {
  return fetchWrapper(`/billing/flexConsulting/depths/${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getDailyUsageForInvoice({ orgId, invoiceId }) {
  return fetchWrapper(`/billing/orgs/${orgId}/invoice/${invoiceId}/usage`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getYayPayPortalLink({ paymentId, orgId }): Promise<FetchYayPayLinkResponseView> {
  return fetchWrapper(`/billing/invoicePayments/${orgId}/${paymentId}/yayPayLink`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMonthlyUsageForOrg({ orgId }) {
  return fetchWrapper(`/billing/orgs/${orgId}/usage`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function partnerCustomerLinkOrg(partnerType: PartnerType, orgId: string): Promise<PartnerCustomerLinkOrgResult> {
  return fetchWrapper(`/billing/partners/${partnerType}/customer/linkOrg`, {
    method: 'POST',
    body: JSON.stringify({
      organizationId: orgId,
    }),
  }).then((resp) => resp.json());
}

export function getPartnerCustomerLinkOrgStatus(partnerType: PartnerType): Promise<PartnerCustomerLinkOrgStatus> {
  return fetchWrapper(`/billing/partners/${partnerType}/customer/linkOrg/status`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getPartnerSubscriptionStatus(orgId: string): Promise<PartnerSubscriptionStatusResult> {
  return fetchWrapper(`/billing/partners/customer/subscription/status?organizationId=${orgId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export async function getCostExplorerFilterOptions(orgId: string): Promise<CostExplorerFilterOptions> {
  return fetchWrapper(`/billing/costExplorer/filterOptions/${orgId}`)
    .then((resp) => resp.json())
    .then((respJson) => {
      return {
        ...respJson,
        minStartDate: getDateFromBillingAnalyticsShortString(respJson.minStartDate),
        maxStartDate: getDateFromBillingAnalyticsShortString(respJson.maxStartDate),
      };
    });
}

export async function getUsageDetailsFilterOptions(
  orgId: string,
  invoiceId: string
): Promise<UsageDetailsFilterOptions> {
  return fetchWrapper(`/billing/usagedetails/orgs/${orgId}/invoice/${invoiceId}/lineItems/filterOptions?envelope=false`)
    .then((resp) => resp.json())
    .then((respJson) => {
      return {
        ...respJson,
        billDates: {
          start: getDateFromBillingAnalyticsShortString(respJson.billDates.startDate),
          end: getDateFromBillingAnalyticsShortString(respJson.billDates.endDate),
        },
        usageDates: {
          start: getDateFromBillingAnalyticsShortString(respJson.usageDates.startDate),
          end: getDateFromBillingAnalyticsShortString(respJson.usageDates.endDate),
        },
      };
    });
}

export function terminateServicesRequestedByUser({ orgId }) {
  return fetchWrapper(`/billing/terminateServicesRequestedByUser/${orgId}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}
