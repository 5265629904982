import fetchWrapper from './fetchWrapper';

export const getAdminAccountDetails = (cloudId: string) => {
  return fetchWrapper(`/v2/admin/accounts/${cloudId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
};

export const forcePasswordReset = async (username: string) => {
  return fetchWrapper(`/v2/admin/accounts/${username}/forcePasswordReset`, {
    method: 'POST',
  });
};
