import { uniq } from 'underscore';

import fetchWrapper from './fetchWrapper';

// These types mostly mirror the types defined on the backend in server/src/main/com/xgen/cloud/common/constants/_public/modal/resources/ResourceConstants.java
export const PolicyType = {
  Custom: 'CUSTOM',
  Managed: 'MANAGED',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PolicyType = (typeof PolicyType)[keyof typeof PolicyType];

export type ResourceService = 'cloud';
export type ResourceType = 'global' | 'organization' | 'project' | 'cluster';

interface ResourceId {
  service: ResourceService;
  type: ResourceType;
  id: string;
}

interface ResourceTypeId {
  service: ResourceService;
  type: ResourceType;
}

export type PermissionEffect = 'ALLOW' | 'DENY';

export interface Permission {
  effect: PermissionEffect;
  action: string;
  resource: {
    service: ResourceService;
    type: ResourceType;
  };
}

export type PermissionOption = Omit<Permission, 'effect'>;

export interface PolicyPermission {
  actions: Array<string>;
  resourceId: ResourceId;
  effect: 'ALLOW' | 'DENY';
}

interface PolicyPermissionOption {
  actions: Array<string>;
  resourceTypeId: ResourceTypeId;
}

interface Policy {
  id: string;
  name: string;
  description?: string;
  // updatedAt will be absent when creating a new policy
  updatedAt?: string;
  permissions: Array<PolicyPermission>;
  type: PolicyType;
}

export interface CustomPolicy extends Policy {
  type: 'CUSTOM';
  ownerResource: ResourceId;
}

export interface ManagedPolicy extends Policy {
  type: 'MANAGED';
  permissionBoundary: ResourceTypeId;
}

export interface PolicyAssignment {
  policyId: string;
  scope: ResourceId;
  resourceDisplayName: string | null;
  policyDisplayName: string | null;
}

export interface UserInvitation {
  username: string;
  policyAssignments: Array<PolicyAssignment>;
}

export interface Actor {
  id: string;
  policyAssignments: Array<PolicyAssignment>;
}

const convertPolicyPermissionsToPermissionOptions = (
  policyPermissions: Array<PolicyPermissionOption>
): Array<PermissionOption> => {
  const permissionOptions: Array<PermissionOption> = [];

  for (const permission of policyPermissions || []) {
    for (const action of permission.actions || []) {
      permissionOptions.push({
        action,
        resource: {
          service: permission.resourceTypeId.service,
          type: permission.resourceTypeId.type,
        },
      });
    }
  }

  // Remove any duplicate permission options
  return uniq(permissionOptions, false, (permissionOption) => JSON.stringify(permissionOption));
};

const unwantedActions = new Set(['ANY_AUTHENTICATED_USER']);

const removeUnwantedActions = (policies: Array<CustomPolicy | ManagedPolicy>): Array<CustomPolicy | ManagedPolicy> => {
  const mappedPolicies = policies.map((policy) => {
    const permissions = policy.permissions
      .map((permission) => ({
        ...permission,
        actions: permission.actions.filter((action) => !unwantedActions.has(action)),
      }))
      .filter((permission) => permission.actions.length > 0);

    return {
      ...policy,
      permissions,
    };
  });

  return mappedPolicies.filter((policy) => policy.permissions.length > 0);
};

export default {
  createCustomPolicy({ orgId, policy }: { orgId: string; policy: CustomPolicy }): Promise<CustomPolicy> {
    return fetchWrapper(`/orgs/${orgId}/customPolicy`, {
      method: 'POST',
      body: JSON.stringify(policy),
    }).then((resp) => resp.json());
  },

  updateCustomPolicy({ orgId, policy }: { orgId: string; policy: CustomPolicy }): Promise<CustomPolicy> {
    return fetchWrapper(`/orgs/${orgId}/customPolicy/${policy.id}`, {
      method: 'PUT',
      body: JSON.stringify(policy),
    }).then((resp) => resp.json());
  },

  deleteCustomPolicy({ orgId, policyId }: { orgId: string; policyId: string }): Promise<Response> {
    return fetchWrapper(`/orgs/${orgId}/customPolicy/${policyId}`, {
      method: 'DELETE',
    });
  },

  getOrgPolicies({ orgId }: { orgId: string }): Promise<Array<CustomPolicy | ManagedPolicy>> {
    return fetchWrapper(`/orgs/${orgId}/policies`, {
      method: 'GET',
    })
      .then((resp) => resp.json())
      .then(removeUnwantedActions);
  },

  getOrgPolicyAssignments({
    orgId,
  }: {
    orgId: string;
  }): Promise<{ actors: Array<Actor>; invitations: Array<UserInvitation> }> {
    return fetchWrapper(`/orgs/${orgId}/policies/assignments`, {
      method: 'GET',
    }).then((resp) => resp.json());
  },

  updateOrgPolicyAssignments({
    orgId,
    userIds,
    assignments,
  }: {
    orgId: string;
    userIds: Array<string>;
    assignments: Array<PolicyAssignment>;
  }): Promise<Array<Actor>> {
    return fetchWrapper(`/orgs/${orgId}/policies/assignments`, {
      method: 'PUT',
      body: JSON.stringify({
        policyAssignments: assignments,
        // Local user IDs are prefixed with an '@' that needs to be removed
        userIds: userIds.map((userId) => userId.replace('@', '')),
      }),
    }).then((resp) => resp.json());
  },

  updateUserPolicyAssignments({
    orgId,
    userId,
    assignments,
  }: {
    orgId: string;
    userId: string;
    assignments: Array<PolicyAssignment>;
  }): Promise<Array<PolicyAssignment>> {
    return fetchWrapper(`/orgs/${orgId}/policies/assignments/user/${userId}`, {
      method: 'PUT',
      body: JSON.stringify(assignments),
    }).then((resp) => resp.json());
  },

  getAllPermissionsUnderOrgResourceType({ orgId }: { orgId: string }): Promise<Array<PermissionOption>> {
    return fetchWrapper(`/orgs/${orgId}/policies/permissions`, {
      method: 'GET',
    })
      .then((resp) => resp.json())
      .then(convertPolicyPermissionsToPermissionOptions);
  },
};
