import queryString from 'query-string';

import { AWSSubnet } from '@packages/types/dashboard/adminSearch';
import { AdminBatchJob, AdminJob } from '@packages/types/dashboard/atlasBillingJobs';
import {
  AssistedAWSInstanceCapacitySpec,
  AWSInstanceUnboundCapacitySpec,
} from '@packages/types/dashboard/cloudProviderCapacity';
import {
  CorruptionDetectionOperationOrigin,
  DbCheck,
  RawDbCheck,
  RunResult,
  RunStatus,
} from '@packages/types/dashboard/corruptionDetection';
import { FastTenantRecordType } from '@packages/types/dashboard/fastTenantRecord';
import { ForcePlanningHost, ForcePlanningReplicaSet } from '@packages/types/dashboard/forcePlanning';
import { CollectionTypeInfo } from '@packages/types/dashboard/internalClusterDescription';
import { MTMCompaction } from '@packages/types/dashboard/MTMCompaction';
import { MTMCluster, MTMTenant, ProxyPrefix, ServerlessMTMPool } from '@packages/types/dashboard/MTMManager';
import { PinnedCluster } from '@packages/types/dashboard/pinnedCluster';
import { PlanExecutorJobPriorityConfig, PlanExecutorJobPrioritySettings } from '@packages/types/dashboard/planExecutor';
import { ServerlessMTMLoad, ServerlessTraceSummary } from '@packages/types/dashboard/ServerlessMTMPoolManager';
import { PreferredStorageType } from '@packages/types/nds/provider';
import { ReplicaSetHardware } from '@packages/types/nds/replicaSetHardware';

import fetchWrapper, { formParams } from '../fetchWrapper';
import envoy from './envoyApi';
import externalMaintenance from './externalMaintenanceApi';
import logIngestion from './logIngestionApi';
import serverlessLoadBalancingDeployment from './serverlessLoadBalancingDeploymentApi';

export function formUrlWithFilters(baseUrl: string, filters = {}): string {
  let urlWithFilters = baseUrl;
  if (Object.keys(filters).length > 0) {
    urlWithFilters += `?${Object.keys(filters)
      .filter((key) => filters[key] != null)
      .map((key) => {
        return `${key}=${filters[key]}`;
      })
      .join('&')}`;
  }
  return urlWithFilters;
}

interface MTMUpdate {
  tenantInstanceSize: string | undefined;
  maxCapacity: string | undefined;
  assignmentEnabled: boolean | undefined;
  isolationGroupIds: string | null;
}

export interface CustomBuildFilterOptions {
  trueNameFilter?: string;
  trueNameAfter?: string;
  trueNameBefore?: string;
}

export interface MongoDbBuild {
  trueName: string;
  gitVersion: string;
  modules: Array<string>;
  architecture: string;
  platform: string;
  flavor: string;
  minOsVersion: string | number;
  maxOsVersion: string | number;
  url: string;
}

export interface MTMClusterFilter {
  searchValue: string;
  cloudProvider: string;
  regionName: string;
  instanceSize: string;
  limit: number;
  skip: number;
}

export function getDataProcessingRegions() {
  return fetchWrapper('/admin/nds/dataProcessingRegions', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function setDataProcessingRegion(groupId, data) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/dataProcessingRegion`, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

function deletePlanExecutorJobPriorityConfigValue(configId) {
  return fetchWrapper(`/admin/nds/ndsJobPriorityConfigs/${configId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function savePlanExecutorJobPriorityConfigValue(payload: PlanExecutorJobPriorityConfig) {
  return fetchWrapper(`/admin/nds/ndsJobPriorityConfigs`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function getPlanExecutorJobPriorityConfigValues(): Promise<Array<PlanExecutorJobPriorityConfig>> {
  return fetchWrapper(`/admin/nds/ndsJobPriorityConfigs`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function updatePlanExecutorJobPriorityConfigValue(payload: PlanExecutorJobPriorityConfig) {
  return fetchWrapper(`/admin/nds/ndsJobPriorityConfigs/${payload.id}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function deletePlanExecutorJobPrioritySetting(settingsId) {
  return fetchWrapper(`/admin/nds/ndsJobPrioritySettings/${settingsId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function addPlanExecutorJobPrioritySetting(payload: PlanExecutorJobPrioritySettings) {
  return fetchWrapper(`/admin/nds/ndsJobPrioritySettings`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function getPlanExecutorJobPrioritySetting(): Promise<Array<PlanExecutorJobPrioritySettings>> {
  return fetchWrapper(`/admin/nds/ndsJobPrioritySettings`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

function updatePlanExecutorJobPrioritySetting(payload: PlanExecutorJobPrioritySettings) {
  return fetchWrapper(`/admin/nds/ndsJobPrioritySettings/${payload.id}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function forceMigrateFromAvailabilitySets(
  clusterName: string,
  groupId: string,
  availabilitySetNames: Array<string>
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/availabilitySet`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      availabilitySetNames,
    }),
  }).then((resp) => resp.json());
}

export function loadPlanJson(planId: string) {
  return fetchWrapper(`/admin/nds/plans/${planId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function skipMove(planId: string, moveId: string) {
  return fetchWrapper(`/admin/nds/plans/${planId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      skipMoveId: moveId,
    }),
  }).then((resp) => resp.json());
}

export function rerunValidation(targetInstanceId: string) {
  return fetchWrapper(`/admin/nds/dataValidation/${targetInstanceId}/rerun`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function getDataValidationRecords(filters = {}) {
  const urlWithFilters = formUrlWithFilters('/admin/nds/dataValidations', filters);
  return fetchWrapper(urlWithFilters, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getDataValidationAggregations(filters = {}) {
  const urlWithFilters = formUrlWithFilters('/admin/nds/dataValidations/aggregations', filters);
  return fetchWrapper(urlWithFilters, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getDryRunModeEnabled(): Promise<{
  dryRunModeEnabled: boolean;
}> {
  return fetchWrapper('/admin/nds/dryRunMode', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function requestValidation(hostname: string, mongoDBVersion?: string) {
  const args: {
    mongoDBVersion?: string;
  } = {};
  if (mongoDBVersion) {
    args.mongoDBVersion = mongoDBVersion;
  }
  return fetchWrapper(`/admin/nds/dataValidation/${hostname}`, {
    method: 'POST',
    body: JSON.stringify(args),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.json());
}

export function requestValidationForGroup(groupId: string) {
  return fetchWrapper(`/admin/nds/dataValidation/groupId/${groupId}`, {
    method: 'POST',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.json());
}

function rawJsonToDbCheck(rawDbCheck: RawDbCheck): DbCheck {
  const dbCheck = {
    ...rawDbCheck,
    result: RunResult[rawDbCheck.result],
    status: RunStatus[rawDbCheck.status],
    operationOrigin: rawDbCheck.operationOrigin
      ? CorruptionDetectionOperationOrigin[rawDbCheck.operationOrigin]
      : CorruptionDetectionOperationOrigin.MANUAL,
    lastManualStatusChangeDate: rawDbCheck.lastManualStatusChangeDate
      ? new Date(rawDbCheck.lastManualStatusChangeDate)
      : undefined,
  };

  return dbCheck;
}

export function getDbCheckRecordPage(filters = {}): Promise<Array<DbCheck>> {
  const urlWithFilters = formUrlWithFilters('/admin/nds/dbcheck', filters);
  return fetchWrapper(urlWithFilters, {
    method: 'GET',
  })
    .then((resp) => resp.json())
    .then((jsonArray) => jsonArray.map(rawJsonToDbCheck));
}

export function updateDbCheckStatus(dbCheckId: string, newStatus: RunStatus, changeReason: string): Promise<Response> {
  const statusUpdate = { newStatus, changeReason };

  return fetchWrapper(`/admin/nds/dbcheck/${dbCheckId}/status`, {
    method: 'PATCH',
    body: JSON.stringify(statusUpdate),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getAdminBatchJobs(batchName: string, date: number): Promise<Array<AdminBatchJob>> {
  let uri = `/admin/nds/atlasBilling/batches/${batchName}?startDate=${date}`;
  return fetchWrapper(uri, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAdminBatchChildJobs(batchJobId: string): Promise<Array<AdminJob>> {
  let uri = `/admin/nds/atlasBilling/batchChildJobs/${batchJobId}`;
  return fetchWrapper(uri, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function resubmitMeterUsages(startTime, endTime, endpoint) {
  let url = `/admin/nds/atlasBilling/` + endpoint;
  return fetchWrapper(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      startTime,
      endTime,
    }),
  }).then((resp) => resp.json());
}

export function previewFailedMeterUsageSubmissionJobs(startTime, endTime, endpoint) {
  let url = `/admin/nds/atlasBilling/preview/` + endpoint + `?startTime=${startTime}&endTime=${endTime}`;
  return fetchWrapper(url, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function retryFailedMeterUsageSubmissionJobs(startTime, endTime, endpoint) {
  let url = `/admin/nds/atlasBilling/` + endpoint;
  return fetchWrapper(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      startTime,
      endTime,
    }),
  }).then((resp) => resp.json());
}

export function getCustomBuilds(opts: CustomBuildFilterOptions): Promise<Array<MongoDbBuild>> {
  let uri = `/admin/nds/customBuilds`;
  const filters: Array<string> = [];
  if (opts.trueNameFilter) {
    filters.push(`trueNameFilter=${encodeURIComponent(opts.trueNameFilter)}`);
  }
  if (opts.trueNameAfter) {
    filters.push(`trueNameAfter=${encodeURIComponent(opts.trueNameAfter)}`);
  }
  if (opts.trueNameBefore) {
    filters.push(`trueNameBefore=${encodeURIComponent(opts.trueNameBefore)}`);
  }
  if (filters.length > 0) {
    uri += '?' + filters.join('&');
  }
  return fetchWrapper(uri, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function addCustomBuild(customBuild: MongoDbBuild): Promise<Array<MongoDbBuild>> {
  let uri = `/admin/nds/customBuilds`;
  return fetchWrapper(uri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(customBuild),
  }).then((resp) => resp.json());
}

export function deleteCustomBuild(trueName: string): Promise<any> {
  let uri = `/admin/nds/customBuilds/${trueName}`;
  return fetchWrapper(uri, {
    method: 'DELETE',
  });
}

export function updateConfigServerToEmbedded(
  groupId: string,
  clusterName: string,
  fixedConfigType: boolean,
  updatedShardCount: number
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/embeddedConfigServer`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      fixedConfigType,
      updatedShardCount,
    }),
  });
}

export function updateConfigServerToDedicated(
  groupId: string,
  clusterName: string,
  fixedConfigType: boolean,
  updatedShardCount: number
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/dedicatedConfigServer`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      fixedConfigType,
      updatedShardCount,
    }),
  });
}

export function clearUnprovisionedTargetGroups(groupId: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clearTargetGroups`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((resp) => resp.json());
}

export function getClusterDescriptionForHostname({ hostname }) {
  return fetchWrapper(`/admin/nds/${hostname}/clusterDescription`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getRsIdFromHostname({ hostname }) {
  return fetchWrapper(`/admin/nds/${hostname}/rsId`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getProviderOptions(provider: string) {
  return fetchWrapper(`/admin/nds/cloudProviders/${provider}/options`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAllProviderOptions(): Promise<any> {
  return Promise.all([
    this.getProviderOptions('AWS'),
    this.getProviderOptions('AZURE'),
    this.getProviderOptions('GCP'),
    this.getProviderOptions('FREE'),
  ]).then(([awsOptions, azureOptions, gcpOptions, freeDefault]) => {
    return {
      AWS: awsOptions,
      AZURE: azureOptions,
      GCP: gcpOptions,
      FREE: freeDefault,
    };
  });
}

export function updateFixedFeatureCompatibilityForCluster(groupId: string, clusterName: string, data = {}) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/featureCompatibilityVersion`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  }).then((resp) => resp.json());
}

function getDateOneQuarterLater(): Date {
  const daysInQuarter = 83;
  const oneQuarterLater = new Date();
  oneQuarterLater.setDate(oneQuarterLater.getDate() + daysInQuarter);
  return oneQuarterLater;
}

export function fixMongoDBVersionForCluster(
  groupId: string,
  clusterName: string,
  version: string,
  reason: string,
  expirationDate = getDateOneQuarterLater().getTime()
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/version`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      version,
      reason,
      expirationDate,
    }),
  }).then((resp) => resp.json());
}

export function unfixMongoDBVersionForCluster(groupId: string, clusterName: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/version`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function fixFeatureCompatibilityVersionForCluster(
  groupId: string,
  clusterName: string,
  featureCompatibilityVersion: string,
  reason: string,
  expirationDate = getDateOneQuarterLater().getTime()
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/featureCompatibilityVersion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      featureCompatibilityVersion,
      reason,
      expirationDate,
    }),
  }).then((resp) => resp.json());
}

export function unfixFeatureCompatibilityVersionForCluster(groupId: string, clusterName: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/featureCompatibilityVersion`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function updateFixedMongoDBVersionForCluster(groupId: string, clusterName: string, data = {}) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/version`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  }).then((resp) => resp.json());
}

export function getFixedFeatureCompatibilityVersionClusters(): Promise<Array<PinnedCluster>> {
  return fetchWrapper(`/admin/nds/fixedFeatureCompatibilityVersionClusters`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getFixedCpuArchClusters(): Promise<Array<PinnedCluster>> {
  return fetchWrapper(`/admin/nds/fixedCpuArchClusters`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateFixedCpuArchForCluster(groupId: string, clusterName: string, data = {}) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/preferredCpuArch`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  }).then((resp) => resp.json());
}

export function fixCpuArchForCluster(
  groupId: string,
  clusterName: string,
  preferredCpuArch: string,
  reason = '',
  expirationDate = getDateOneQuarterLater().getTime()
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/preferredCpuArch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      preferredCpuArch,
      reason,
      expirationDate,
    }),
  }).then((resp) => resp.json());
}

export function unfixCpuArchForCluster(groupId: string, clusterName: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/preferredCpuArch`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function fixAgentVersionForGroup(
  groupId: string,
  agentType: string,
  version = '',
  baseUrl = '',
  reason = '',
  expirationDate = 0
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/agents/${agentType}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      version,
      baseUrl,
      reason,
      expirationDate,
    }),
  }).then((resp) => resp.json());
}

export function unfixAgentVersionForGroup(groupId: string, agentType: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/agents/${agentType}`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function updateFixedAgentVersionForGroup(groupId: string, agentType: string, data = {}) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/agents/${agentType}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  }).then((resp) => resp.json());
}

export function setAzurePreferredStorageTypeForCluster(
  groupId: string,
  clusterName: string,
  preferredStorageType: PreferredStorageType
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/azurePreferredStorageType`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      preferredStorageType,
    }),
  }).then((resp) => resp.json());
}

export function getPlanExtraInformation(groupId: string, planId: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${planId}/planExtraInformation`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getReplicaSetHardware(groupId: string, clusterName: string): Promise<Array<ReplicaSetHardware>> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/replicaSetHardware`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getClusterCollectionTypes(groupId: string, clusterName: String): Promise<CollectionTypeInfo> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/collectionTypes`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getDowngradableFeatureCompatibilityVersion(groupId: string, clusterName: string) {
  return fetchWrapper(
    `/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/getDowngradableFeatureCompatibilityVersion`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function clearFailedPlansCount(groupId) {
  return fetchWrapper(`/admin/nds/groups/${groupId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body: formParams({
      clearFailedPlansCount: true,
    }),
  });
}

export function getLastPing(groupId, hostname) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/host/${hostname}/lastPing`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getProcessArgs(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/processArgs`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateProcessArgs(groupId, clusterName, newArgsJson) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/processArgs`, {
    method: 'PATCH',
    body: JSON.stringify(newArgsJson),
  }).then((resp) => resp.json());
}

export function updateMongotProcessArgs(groupId, clusterName, newArgsJson) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/mongotProcessArgs`, {
    method: 'PATCH',
    body: JSON.stringify(newArgsJson),
  }).then((resp) => resp.json());
}

export function getHasFTSEnabled(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/ftsEnabled`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMongotVersion(groupId) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/mongotVersion`, {
    method: 'GET',
  })
    .then((resp) => resp.json())
    .catch(() => null);
}

export function getCloudProviderConsoleInfoDefaults(groupId, hostname) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/cloudProviderConsole/${hostname}/defaults`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getCloudProviderConsoleInfo(groupId, hostname, extension, body) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/cloudProviderConsole/${hostname}.${extension}`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function cancelPendingIndexes(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/pendingIndexes`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function clearIndexConfig(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/clearIndexConfig`, {
    method: 'DELETE',
  }).then((resp) => resp.json());
}

export function getAccessRequests() {
  return fetchWrapper('/admin/nds/serverAccess/requests', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAccessRequestSessions(groupId, hostname, requestId) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/ssh-session-recordings/${hostname}/${requestId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getSshSessionRecordingURLs(groupId, hostname, requestId, sessionId) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/ssh-session-recordings/${hostname}/${requestId}/${sessionId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function checkOrgRestrictedEmployeeAccess({ hostname }) {
  return fetchWrapper(`/admin/nds/serverAccess/validateHostname?hostname=${hostname}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function submitAccessRequest({ hostname, reason, overwriteExistingHostAccess = false }) {
  return fetchWrapper(
    `/admin/nds/serverAccess/requests${overwriteExistingHostAccess ? '?overwriteExistingHostAccess=true' : ''}`,
    {
      method: 'POST',
      body: JSON.stringify({
        hostname,
        reason,
      }),
    }
  ).then((resp) => resp.json());
}

export function submitClusterAccessRequest({ hostname, reason, overwriteExistingHostAccess = false }) {
  return fetchWrapper(
    `/admin/nds/serverAccess/wholeClusterRequests${
      overwriteExistingHostAccess ? '?overwriteExistingHostAccess=true' : ''
    }`,
    {
      method: 'POST',
      body: JSON.stringify({
        hostname,
        reason,
      }),
    }
  ).then((resp) => resp.json());
}

export function submitSingleShardAccessRequest({ hostname, reason, overwriteExistingHostAccess = false }) {
  return fetchWrapper(
    `/admin/nds/serverAccess/singleShardRequests${
      overwriteExistingHostAccess ? '?overwriteExistingHostAccess=true' : ''
    }`,
    {
      method: 'POST',
      body: JSON.stringify({
        hostname,
        reason,
      }),
    }
  ).then((resp) => resp.json());
}

export function addMTMProfile(data) {
  return fetchWrapper(`/admin/nds/mtm/profiles`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function addServerlessMTMProfile(data) {
  return fetchWrapper(`/admin/nds/mtm/serverless/profiles`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function addUniformFrontendLoadBalancingDeployment(data) {
  return fetchWrapper(`/admin/nds/uniformFrontend/loadBalancingDeployment`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function getServerlessMTMProfiles() {
  return fetchWrapper('/admin/nds/mtm/serverless/profiles', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getServerlessMTMProfilesByPoolId(poolId: string) {
  return fetchWrapper(`/admin/nds/mtm/serverless/profiles/pool/${poolId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function requestClusterCriticalReboot(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/${clusterName}/reboot`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function getLeakedItems(skip, limit, provider, type) {
  return fetchWrapper(`/admin/nds/leakedItems/?skip=${skip}&limit=${limit}&provider=${provider}&type=${type}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function approveLeakedItemForDeletion(cloudProvider, id, type) {
  return fetchWrapper(`/admin/nds/leakedItems/${cloudProvider}/${type}`, {
    method: 'PATCH',
    body: formParams({
      id,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function revokeLeakedItemApprovalForDeletion(cloudProvider, id, type) {
  return fetchWrapper(`/admin/nds/leakedItems/${cloudProvider}/${type}`, {
    method: 'DELETE',
    body: formParams({
      id,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function ignoreLeakedItem(cloudProvider, id, type) {
  return fetchWrapper(`/admin/nds/leakedItems/ignored/${cloudProvider}/${type}`, {
    method: 'PATCH',
    body: formParams({
      id,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function updateLeakedItem(cloudProvider, id, type, jiraTicket) {
  return fetchWrapper(`/admin/nds/leakedItems/update/${cloudProvider}/${type}`, {
    method: 'PATCH',
    body: formParams({
      id,
      jiraTicket,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function addOsTunedFileOverrides(groupId: string, clusterName: string, data: Object): Promise<Response> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/osTunedFileOverrides`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getMTMGroups() {
  return fetchWrapper('/admin/nds/groups/mtmGroups', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getFlexMTMGroups() {
  return fetchWrapper('/admin/nds/groups/flex/mtmGroups', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getServerlessMTMGroups() {
  return fetchWrapper('/admin/nds/groups/serverless/mtmGroups', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMTMClusters(): Promise<Array<MTMCluster>> {
  return fetchWrapper('/admin/nds/mtm/clusters', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getProxyNaRegexThrottlingPrefixes(): Promise<Array<ProxyPrefix>> {
  return fetchWrapper('/admin/nds/proxy/throttling/nonAnchoredRegex/watchedPrefixes', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function addProxyNaRegexThrottlingPrefix(prefix) {
  return fetchWrapper(`/admin/nds/proxy/throttling/nonAnchoredRegex/watchedPrefix/${prefix}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function deleteProxyNaRegexThrottlingPrefix(prefix) {
  return fetchWrapper(`/admin/nds/proxy/throttling/nonAnchoredRegex/watchedPrefix/${prefix}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function getProxyNaRegexThrottlingDelay(): Promise<number> {
  return fetchWrapper('/admin/nds/proxy/throttling/nonAnchoredRegex/delayMillis', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function setProxyNaRegexThrottlingDelay(delay: number): Promise<Response> {
  return fetchWrapper('/admin/nds/proxy/throttling/nonAnchoredRegex/delayMillis', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({ delay: delay }),
  });
}

export function getPaginatedMTMClusters(filters: MTMClusterFilter): Promise<Array<MTMCluster>> {
  let baseUrl = `/admin/nds/mtm/paginatedClusters?limit=${filters.limit}&skip=${filters.skip}`;

  return fetchWrapper(getPaginatedQueryParams(filters, baseUrl), {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getFlexMTMClusters(filters: MTMClusterFilter): Promise<Array<MTMCluster>> {
  let baseUrl = `/admin/nds/mtm/flex/clusters?limit=${filters.limit}&skip=${filters.skip}`;

  return fetchWrapper(getPaginatedQueryParams(filters, baseUrl), {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getFlexMTMClustersCount(filters: MTMClusterFilter) {
  let url = `/admin/nds/mtm/flex/clusters/count?`;
  return fetchWrapper(getPaginatedQueryParams(filters, url), {
    method: 'GET',
  })
    .then((resp) => resp.json())
    .then((json) => json.count);
}

export function getServerlessMTMTenants(groupId: string, mtmName: string): Promise<Array<MTMTenant>> {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${mtmName}/tenants`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getSharedMTMTenants(groupId: string, mtmName: string): Promise<Array<MTMTenant>> {
  return fetchWrapper(`/admin/nds/mtm/clusters/${groupId}/${mtmName}/tenants`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getFlexMTMTenants(groupId: string, mtmName: string): Promise<Array<MTMTenant>> {
  return fetchWrapper(`/admin/nds/mtm/flex/clusters/${groupId}/${mtmName}/tenants`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getMTMClusterCount(filters: MTMClusterFilter): Promise<number> {
  let baseUrl = '/admin/nds/mtm/clusters/count?';
  return fetchWrapper(getPaginatedQueryParams(filters, baseUrl), {
    method: 'GET',
  })
    .then((resp) => resp.json())
    .then((json) => json.count);
}

export function getUnfinishedMTMCompactions(): Promise<Array<MTMCompaction>> {
  const url = '/admin/nds/mtm/clusters/compactions?status=PROCESSING&status=PAUSED';
  return fetchWrapper(url, {
    method: 'GET',
  }).then((resp) => {
    return resp.json();
  });
}

export function getFinishedMTMCompactions(limit: number): Promise<Array<MTMCompaction>> {
  const url = `/admin/nds/mtm/clusters/finishedCompactions?limit=${limit}`;
  return fetchWrapper(url, {
    method: 'GET',
  }).then((resp) => {
    return resp.json();
  });
}

export function getFinishedOAV3Migrations(
  limit: number,
  skip: number,
  archiveIdsString: string,
  states: Array<string>
) {
  let url = `/admin/nds/onlineArchives/finishedV3Migrations?limit=${limit}&skip=${skip}`;
  const filters: Array<string> = [];
  const archiveIds: Array<string> = archiveIdsString.split(',').map((id) => id.replace(/%20/g, ' ').trim());
  if (archiveIdsString !== '' && archiveIds.length > 0) {
    filters.push(archiveIds.map((archiveId) => `archiveId=${archiveId}`).join('&'));
  }
  if (states.length > 0) {
    filters.push(states.map((state) => `state=${state}`).join('&'));
  }
  if (filters.length > 0) {
    url += '&' + filters.join('&');
  }

  return fetchWrapper(url, {
    method: 'GET',
  }).then((resp) => {
    return resp.json();
  });
}

export function getUnfinishedOAV3Migrations(
  limit: number,
  skip: number,
  archiveIdsString: string,
  states: Array<string>
) {
  let url = `/admin/nds/onlineArchives/unfinishedV3Migrations?limit=${limit}&skip=${skip}`;
  const filters: Array<string> = [];
  const archiveIds: Array<string> = archiveIdsString.split(',').map((id) => id.replace(/%20/g, ' ').trim());
  if (archiveIdsString !== '' && archiveIds.length > 0) {
    filters.push(archiveIds.map((archiveId) => `archiveId=${archiveId}`).join('&'));
  }
  if (states.length > 0) {
    filters.push(states.map((state) => `state=${state}`).join('&'));
  }
  if (filters.length > 0) {
    url += '&' + filters.join('&');
  }
  return fetchWrapper(url, {
    method: 'GET',
  }).then((resp) => {
    return resp.json();
  });
}

export function getMTMCluster(clusterName: string, groupId: string): Promise<MTMCluster> {
  return fetchWrapper(`/admin/nds/mtm/clusters/${groupId}/${clusterName}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getServerlessMTMClusters() {
  return fetchWrapper('/admin/nds/mtm/serverless/clusters', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getServerlessMTMClustersByPoolId(poolId: string) {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/pool/${poolId}`, { method: 'GET' }).then((resp) =>
    resp.json()
  );
}

export function getPaginatedServerlessMTMClusters(filters: MTMClusterFilter): Promise<Array<MTMCluster>> {
  let url = `/admin/nds/mtm/serverless/paginatedClusters?limit=${filters.limit}&skip=${filters.skip}`;
  return fetchWrapper(getPaginatedQueryParams(filters, url), {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getServerlessMTMClusterCount(filters: MTMClusterFilter) {
  let url = `/admin/nds/mtm/serverless/clusters/count?`;
  return fetchWrapper(getPaginatedQueryParams(filters, url), {
    method: 'GET',
  })
    .then((resp) => resp.json())
    .then((json) => json.count);
}

export function setMTMHolder(groupId, data) {
  return fetchWrapper(`/admin/nds/groups/${groupId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function setMTMCluster(groupId, clusterName, data) {
  return fetchWrapper(`/admin/nds/mtm/clusters/${groupId}/${clusterName}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function setServerlessMTMCluster(groupId, clusterName, data) {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function unsetMTMCluster(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/mtm/clusters/${groupId}/${clusterName}`, {
    method: 'DELETE',
  });
}

export function unsetServerlessMTMCluster(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}`, {
    method: 'DELETE',
  });
}

export function setFlexMTMCluster(groupId, clusterName, data) {
  return fetchWrapper(`/admin/nds/mtm/flex/clusters/${groupId}/${clusterName}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function unsetFlexMTMCluster(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/mtm/flex/clusters/${groupId}/${clusterName}`, {
    method: 'DELETE',
  });
}

export function updateMTMCluster(groupId: string, clusterName: string, data: MTMUpdate): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/clusters/${groupId}/${clusterName}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function updateServerlessMTMCluster(groupId: string, clusterName: string, data: MTMUpdate): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function updateFlexMTMCluster(groupId: string, clusterName: string, data: MTMUpdate): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/flex/clusters/${groupId}/${clusterName}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams(data),
  });
}

export function getServerlessMTMPools(): Promise<Array<ServerlessMTMPool>> {
  return fetchWrapper('/admin/nds/mtm/serverless/pools', {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function createServerlessMTMPool(data: ServerlessMTMPool): Promise<ServerlessMTMPool> {
  return fetchWrapper(`/admin/nds/mtm/serverless/pools`, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function updateServerlessMTMPool(poolId: string, data: ServerlessMTMPool): Promise<ServerlessMTMPool> {
  return fetchWrapper(`/admin/nds/mtm/serverless/pools/${poolId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
}

export function deleteServerlessMTMPool(poolId: string) {
  return fetchWrapper(`/admin/nds/mtm/serverless/pools/${poolId}`, {
    method: 'DELETE',
  });
}

export function getServerlessMTMLoad(
  groupId: string,
  clusterName: string,
  numTopTenants: number
): Promise<ServerlessMTMLoad> {
  return fetchWrapper(
    `/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}/load?numTopTenants=${numTopTenants}`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function getServerlessTraceSummariesByPool(
  poolId: string,
  startedAfterDate: string,
  endedBeforeDate: string
): Promise<Array<ServerlessTraceSummary>> {
  return fetchWrapper(
    `/admin/nds/serverless/pools/${poolId}/traceSummaries?startedAfterDate=${Date.parse(
      startedAfterDate
    )}&endedBeforeDate=${Date.parse(endedBeforeDate)}`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function getTenantUpgrades(status: string) {
  return fetchWrapper(`/admin/nds/tenantUpgrades?status=${status}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getTenantUpgradesToServerless(status: string) {
  return fetchWrapper(`/admin/nds/tenantUpgradesToServerless?status=${status}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getTenantUpgradeSnapshotDownloadURL(tenantUpgradeId: string) {
  return fetchWrapper(`/admin/nds/tenantUpgrades/${tenantUpgradeId}/downloadUrl`, {
    method: 'GET',
  }).then((resp) => resp.text());
}

export function getTenantUpgradeToServerlessSnapshotDownloadURL(tenantUpgradeId: string) {
  return fetchWrapper(`/admin/nds/tenantUpgradesToServerless/${tenantUpgradeId}/downloadUrl`, {
    method: 'GET',
  }).then((resp) => resp.text());
}

export function getFastSharedRecords(state: string, limit: number, skip: number) {
  return fetchWrapper(`/admin/nds/fastSharedPreallocatedRecords?state=${state}&limit=${limit}&skip=${skip}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getFastFlexRecords(state: string, limit: number, skip: number) {
  return fetchWrapper(`/admin/nds/fastFlexPreallocatedRecords?state=${state}&limit=${limit}&skip=${skip}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getFastServerlessRecords(state: string, limit: number, skip: number) {
  return fetchWrapper(`/admin/nds/fastServerlessPreallocatedRecords?state=${state}&limit=${limit}&skip=${skip}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function setFastSharedRecordState(data: FastTenantRecordType): Promise<Response> {
  return fetchWrapper(`/admin/nds/setFastSharedPreallocatedRecordState`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.json());
}

export function setFastFlexRecordState(data: FastTenantRecordType): Promise<Response> {
  return fetchWrapper(`/admin/nds/setFastFlexPreallocatedRecordState`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.json());
}

export function setFastServerlessRecordState(data: FastTenantRecordType): Promise<Response> {
  return fetchWrapper(`/admin/nds/setFastServerlessPreallocatedRecordState`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.json());
}

export function restartProcess(processType: string, hostname: string): Promise<Response> {
  return fetchWrapper(`/admin/nds/restartProcess/${processType}/hostname/${hostname}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function scaleMTMCapacity(profileId: string, numMTMs: number): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/serverless/profiles/${profileId}/scaleMTMCapacity?numMTMs=${numMTMs}`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function setupServerless(
  orgId: string,
  groupId: string,
  cloudProvider: string,
  regionName: string,
  backingInstanceSize: string,
  createServerlessInstance: boolean
): Promise<Response> {
  return fetchWrapper(
    `/admin/nds/mtm/serverless/${orgId}/${cloudProvider}/${regionName}/setupServerless?backingInstanceSize=${backingInstanceSize}&groupId=${groupId}&createServerlessInstance${
      createServerlessInstance ? '=true' : '=false'
    }`,
    {
      method: 'POST',
    }
  ).then((resp) => resp.json());
}

export function setupFreeTier(
  orgId: string,
  cloudProvider: string,
  regionName: string,
  backingInstanceSize: string,
  tenantInstanceSize: string
): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/clusters/${orgId}/setupFreeTier`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      cloudProvider,
      regionName,
      backingInstanceSize,
      tenantInstanceSize,
    }),
  }).then((resp) => resp.json());
}

export function setupFlex(
  orgId: string,
  cloudProvider: string,
  regionName: string,
  backingInstanceSize: string,
  tenantInstanceSize: string,
  shouldCreateFlexInstance: boolean
): Promise<Response> {
  return fetchWrapper(`/admin/nds/mtm/clusters/${orgId}/setupFlex`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      cloudProvider,
      regionName,
      backingInstanceSize,
      tenantInstanceSize,
      shouldCreateFlexInstance,
    }),
  }).then((resp) => resp.json());
}

export function updateGCPPSCNATSubnetRange(groupId: string, gcpPSCRegionGroupCIDRRange: string): Promise<Response> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/gcpPSCRegionGroupCIDRRange`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      gcpPSCRegionGroupCIDRRange,
    }),
  }).then((resp) => resp.json());
}

export function getForcePlanningReplicaSets(
  groupId: string,
  clusterName: string
): Promise<Array<ForcePlanningReplicaSet>> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/forcePlanningReplicaSets`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function setForcePlanningReplicaSets(groupId: string, clusterName: string, payload: Array<ForcePlanningHost>) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/forcePlanningReplicaSets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

export function getLiveImportOverrides() {
  return fetchWrapper(`/admin/nds/liveImportOverrides`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function addLiveImportOverrides(
  groupId,
  clusterName,
  oplogBatchSize,
  collStatsThreshold,
  maxNumParallelPartitions,
  numInsertersPerPartition,
  loadLevel,
  buildIndexes
) {
  return fetchWrapper(`/admin/nds/liveImportOverrides`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      groupId,
      clusterName,
      oplogBatchSize,
      collStatsThreshold,
      maxNumParallelPartitions,
      numInsertersPerPartition,
      loadLevel,
      buildIndexes,
    }),
  });
}

export function updateLiveImportOverrides(
  groupId,
  clusterName,
  oplogBatchSize,
  collStatsThreshold,
  maxNumParallelPartitions,
  numInsertersPerPartition,
  loadLevel,
  buildIndexes
) {
  return fetchWrapper(`/admin/nds/liveImportOverrides/${groupId}/${clusterName}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      oplogBatchSize,
      collStatsThreshold,
      maxNumParallelPartitions,
      numInsertersPerPartition,
      loadLevel,
      buildIndexes,
    }),
  });
}

export function deleteLiveImportOverrides(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/liveImportOverrides/${groupId}/${clusterName}`, {
    method: 'DELETE',
  });
}

export function getAdminClusterLockGivenHostname(hostname) {
  return fetchWrapper(`/admin/nds/hostnames/${hostname}/adminClusterLocks`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAdminClusterLock(groupId: string, clusterName: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/adminClusterLocks`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function acquireAdminClusterLock({ groupId, clusterName, reason }) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/adminClusterLocks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({ reason }),
  }).then((resp) => resp.json());
}

export function acquireAdminClusterLockGivenHostname({ hostname, reason }) {
  return fetchWrapper(`/admin/nds/hostnames/${hostname}/adminClusterLocks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({ hostname, reason }),
  }).then((resp) => resp.json());
}

export function releaseAdminClusterLock({ groupId, clusterName }) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/adminClusterLocks`, {
    method: 'DELETE',
  });
}

export function renewAdminClusterLock({ groupId, clusterName }) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusters/${clusterName}/adminClusterLocks`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then((resp) => resp.json());
}

export function getMTMsForCompaction(filters = {}): Promise<Array<MTMCluster>> {
  const urlWithFilters = formUrlWithFilters('/admin/nds/mtm/clusters/compactions/recommendations', filters);

  return fetchWrapper(urlWithFilters, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function initiateMTMCompactions(payload) {
  return fetchWrapper(`/admin/nds/mtm/clusters/compactions`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function setMTMCompactionAction(mtmCompactionId, status) {
  return fetchWrapper(`/admin/nds/mtm/clusters/compactions/${mtmCompactionId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      status,
    }),
  }).then((resp) => resp.json());
}

export function setTenantAction(mtmCompactionId, tenantUniqueId, action) {
  return fetchWrapper(`/admin/nds/mtm/clusters/compactions/${mtmCompactionId}/tenants/${tenantUniqueId}`, {
    method: `PATCH`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      action,
    }),
  }).then((resp) => resp.json());
}

export function requestBlockServerlessTenant(tenantUniqueId) {
  return fetchWrapper(`/admin/nds/serverless/${tenantUniqueId}/blockTenant`, {
    method: 'POST',
  });
}

export function requestUnblockServerlessTenant(tenantUniqueId) {
  return fetchWrapper(`/admin/nds/serverless/${tenantUniqueId}/unblockTenant`, {
    method: 'POST',
  });
}

export function editMTMGrpcMaxCapacity(groupId: string, clusterName: string, maxCapacity: number) {
  return fetchWrapper(
    `/admin/nds/serverless/grpcIncrementalRollout/${groupId}/${clusterName}/updateMaxCapacity?maxCapacity=${maxCapacity}`,
    {
      method: 'PATCH',
    }
  );
}

export function enableGrpcIncrementalRollout(groupId: string, clusterName: string, maxCapacity: number) {
  return fetchWrapper(
    `/admin/nds/serverless/grpcIncrementalRollout/${groupId}/${clusterName}/enableMTM?maxCapacity=${maxCapacity}`,
    {
      method: 'PATCH',
    }
  );
}

export function disableGrpcIncrementalRollout(groupId: string, clusterName: string) {
  return fetchWrapper(`/admin/nds/serverless/grpcIncrementalRollout/${groupId}/${clusterName}/disableMTM`, {
    method: 'PATCH',
  });
}

export function disableTenantFromGrpcIncrementalRollout(groupId: string, clusterName: string, tenantId: string) {
  return fetchWrapper(
    `/admin/nds/serverless/grpcIncrementalRollout/${groupId}/${clusterName}/${tenantId}/disableTenant`,
    {
      method: 'PATCH',
    }
  );
}

export function excludeTenantFromGrpcIncrementalRollout(groupId, clusterName, tenantId) {
  return fetchWrapper(
    `/admin/nds/serverless/grpcIncrementalRollout/${groupId}/${clusterName}/${tenantId}/excludeTenant`,
    {
      method: 'PATCH',
    }
  );
}

export function removeTenantFromGrpcIncrementalRolloutExcluded(groupId, clusterName, tenantId) {
  return fetchWrapper(
    `/admin/nds/serverless/grpcIncrementalRollout/${groupId}/${clusterName}/${tenantId}/removeExcludedTenant`,
    {
      method: 'PATCH',
    }
  );
}

export function enableGrpcForAllActiveGrpcDisabledMTMsInPool(poolId, maxCapacity) {
  return fetchWrapper(
    `/admin/nds/serverless/grpcIncrementalRollout/${poolId}/enableGrpcForAllActiveGrpcDisabledMTMsInPool?maxCapacity=${maxCapacity}`,
    {
      method: 'PATCH',
    }
  );
}

export function disableGrpcForAllActiveGrpcEnabledMTMsInPool(poolId) {
  return fetchWrapper(
    `/admin/nds/serverless/grpcIncrementalRollout/${poolId}/disableGrpcForAllActiveGrpcEnabledMTMsInPool`,
    {
      method: 'PATCH',
    }
  );
}

export function setGrpcIncrementalRolloutNeedsUpdate(mtmGroupId, mtmClusterName) {
  return fetchWrapper(
    `/admin/nds/serverless/grpcIncrementalRollout/${mtmGroupId}/${mtmClusterName}/setGrpcNeedsUpdate`,
    {
      method: 'PATCH',
    }
  );
}

export function getProjectLogs(
  groupId: string,
  ts: Date,
  before: boolean,
  bound: Date | undefined,
  n: number,
  planId?: string
) {
  const params = { planId, ts: ts.getTime(), before, n, bound: bound ? bound.getTime() : undefined };
  return fetchWrapper(`/admin/nds/groups/${groupId}/appLogs?${queryString.stringify(params)}`, { method: 'GET' }).then(
    (resp) => resp.json()
  );
}

export function getHostLogInfo(groupId: string, hostname: string, logName: string, startDate: number, endDate: number) {
  return fetchWrapper(
    `/admin/nds/groups/${groupId}/logs/${hostname}/${logName}/info?startDate=${startDate}&endDate=${endDate}`,
    { method: 'GET' }
  ).then((resp) => resp.json());
}

export function retryServerlessRestoreJob(tenantUpgradeId) {
  return fetchWrapper(`/admin/nds/tenantUpgradesToServerless/${tenantUpgradeId}/retryRestoreJob`, {
    method: 'POST',
  }).then((resp) => resp.json());
}

export function getAllTenantsForServerlessMTM(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}/tenants`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getServerlessMTM(groupId, clusterName) {
  return fetchWrapper(`/admin/nds/mtm/serverless/clusters/${groupId}/${clusterName}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function updateSentinelInformation(newPassword: string, updatedIpAccess: string) {
  return fetchWrapper(`/admin/nds/serverless/updateSentinelInfo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      newPassword: newPassword,
      updatedIpAccess: updatedIpAccess,
    }),
  }).then((resp) => resp.json());
}

export function addRollingResync(
  groupId,
  clusterName,
  nodesRequestedToResync,
  orderedNodesRequestedToResync,
  initialSyncMethod,
  syncSourcesToExclude,
  bypassValidations,
  stepDownTiming,
  customStepDownStartDate,
  customStepDownEndDate,
  bypassOptimizations
) {
  return fetchWrapper(`/admin/nds/rollingResync/cluster`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      groupId,
      clusterName,
      nodesRequestedToResync,
      orderedNodesRequestedToResync,
      initialSyncMethod,
      syncSourcesToExclude,
      bypassValidations,
      stepDownTiming,
      customStepDownStartDate,
      customStepDownEndDate,
      bypassOptimizations,
    }),
  });
}

export function modifyStepDownWindowRequest(
  rollingResyncId,
  stepDownTiming,
  customStepDownStartDate,
  customStepDownEndDate
) {
  return fetchWrapper(`/admin/nds/rollingResync/stepDownConfig/${rollingResyncId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      stepDownTiming,
      customStepDownStartDate,
      customStepDownEndDate,
    }),
  });
}

export function cancelRollingResync(rollingResyncId) {
  return fetchWrapper(`/admin/nds/rollingResync/cancel/${rollingResyncId}`, {
    method: 'POST',
  });
}

export function getAllActiveRollingResyncs() {
  return fetchWrapper(`/admin/nds/rollingResync/allActive`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getRecentRollingResyncs(term, limit, skip) {
  return fetchWrapper(`/admin/nds/rollingResync/recent?term=${term}&limit=${limit}&skip=${skip}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getRecentRollingResyncCount(term): Promise<number> {
  return fetchWrapper(`/admin/nds/rollingResync/recent/count?term=${term}`, {
    method: 'GET',
  })
    .then((resp) => resp.json())
    .then((json) => json.count);
}

export function getAllAwsCapacityRequests() {
  return fetchWrapper(`/admin/nds/capacity/requests`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAwsCapacityRequest(groupId: string, requestId: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/capacity/requests/${requestId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function getAwsCapacityInfo(accountId: string, regionName: string, reservationId: string) {
  return fetchWrapper(
    `/admin/nds/aws/odcr/info?accountId=${accountId}&regionName=${regionName}&reservationId=${reservationId}`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function releaseCheckCapacityRequest(groupId: string, requestId: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/capacity/requests/${requestId}/release`, {
    method: 'PATCH',
  });
}

export function getAssistedCapacityReservationsForGroup(groupId: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/capacity/reservations`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function acceptAssistedCapacityReservationsForGroup(
  groupId: string,
  specs: Array<AssistedAWSInstanceCapacitySpec>
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/capacity/reservations/accept`, {
    method: 'POST',
    body: JSON.stringify(specs),
  }).then((resp) => resp.json());
}

export function retryAcceptAssistedCheckCapacityRequest(groupId: string, requestId: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/capacity/requests/${requestId}/accept`, {
    method: 'PATCH',
  });
}

export function submitCheckCapacityRequest(groupId: string, specs: Array<AWSInstanceUnboundCapacitySpec>) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/capacity/check`, {
    method: 'POST',
    body: JSON.stringify(specs),
  }).then((resp) => resp.json());
}

export function submitUpdateConfigServerInstanceSizeRequest(
  groupId: string,
  clusterName: string,
  instanceSize: string
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/configServerInstanceSize`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      instanceSize,
    }),
  }).then((resp) => resp.json());
}

export function submitUpdateConfigServerDiskSizeGBRequest(
  groupId: string,
  clusterName: string,
  configDiskSizeGB: number
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/configServerDiskSizeGB`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formParams({
      configDiskSizeGB,
    }),
  }).then((resp) => resp.json());
}

export function submitHoldCapacityRequest(
  groupId: string,
  holdDays: number,
  payload: {
    comment: string;
    specs: Array<AWSInstanceUnboundCapacitySpec>;
  }
) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/capacity/hold/${holdDays}`, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).then((resp) => resp.json());
}

interface ZoneMapping {
  zoneName: string;
  zoneId: string;
  enabled: boolean;
  subnetId: string;
}

export function getZoneMappings(groupId: string, containerId: string): Promise<Array<AWSSubnet>> {
  return (
    fetchWrapper(`/admin/nds/groups/${groupId}/containers/${containerId}/zoneMappings`, {
      method: 'GET',
    })
      .then((resp) => resp.json())
      // convert response to AWSSubnet structure to conosolidate to single data type
      .then(({ zones }) => (zones || []) as Array<ZoneMapping>)
      .then((zones) =>
        zones.map((z) => ({
          subnetId: z.subnetId,
          enabled: z.enabled,
          availabilityZone: z.zoneName,
          zoneId: z.zoneId,
        }))
      )
  );
}

export function updateSubnets(groupId: string, containerId: string, body: Array<AWSSubnet>): Promise<any> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/containers/${containerId}/subnets`, {
    method: 'PATCH',
    body: JSON.stringify(body),
  });
}

function getPaginatedQueryParams(filters: MTMClusterFilter, baseUrl: string) {
  if (filters.searchValue.length !== 0) baseUrl += `&searchValue=${filters.searchValue}`;
  if (filters.cloudProvider.length !== 0) baseUrl += `&cloudProvider=${filters.cloudProvider}`;
  if (filters.regionName.length !== 0) baseUrl += `&regionName=${filters.regionName}`;
  if (filters.instanceSize.length !== 0) baseUrl += `&instanceSize=${filters.instanceSize}`;
  return baseUrl;
}

export function getAutoScalingContext(groupId: string, clusterName: string) {
  return fetchWrapper(`/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/autoScalingContext`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export function setAutoScalingScalingCriteria(
  groupId: string,
  clusterName: string,
  nodeTypeFamily: string,
  body: string
) {
  return fetchWrapper(
    `/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/${nodeTypeFamily}/autoScalingContext`,
    {
      method: 'PATCH',
      body: body,
    }
  );
}

export function getAutoScalingMetrics(groupId: string, clusterName: string, date: number) {
  return fetchWrapper(
    `/admin/nds/groups/${groupId}/clusterDescriptions/${clusterName}/autoScalingContext/metrics?endDate=${date}`,
    {
      method: 'GET',
    }
  ).then((resp) => resp.json());
}

export function fetchSSHSessionIds(groupId: string, hostname: string, sshRequestId: string): Promise<Array<String>> {
  return fetchWrapper(`/admin/nds/groups/${groupId}/ssh-session-recordings/${hostname}/${sshRequestId}`, {
    method: 'GET',
  }).then((resp) => resp.json());
}

export { logIngestion, externalMaintenance, envoy, serverlessLoadBalancingDeployment };

export default {
  deletePlanExecutorJobPriorityConfigValue,
  savePlanExecutorJobPriorityConfigValue,
  updatePlanExecutorJobPriorityConfigValue,
  getPlanExecutorJobPriorityConfigValues,

  deletePlanExecutorJobPrioritySetting,
  addPlanExecutorJobPrioritySetting,
  updatePlanExecutorJobPrioritySetting,
  getPlanExecutorJobPrioritySetting,

  getDataProcessingRegions,
  setDataProcessingRegion,

  updateSubnets,
  getZoneMappings,
};
