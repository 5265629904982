export enum DeploymentType {
  FLEX,
  SERVERLESS,
  DEDICATED,
  SHARED,
}

export enum SharedTierTerm {
  SHARED = 'Shared',
  BASIC = 'Basic',
  FREE_AND_HOBBY = 'Free & Hobby',
}

export enum ClusterFilterKey {
  BASELINE = 'baseline',
  STARTER = 'starter',
  SINGLE = 'single',
  MULTI = 'multi',
  ADVANCED = 'advanced',
  SERVERLESS = 'serverless',
  FLEX = 'flex',
}

export interface ClusterBuilderFilterInterface {
  isGlobalConfigurationVisible: boolean;
  isMultiRegionConfigurationVisible: boolean;
  advancedOptions: {
    shardingVisible: boolean;
    otherOptionsVisible: boolean;
  };
  providerTemplateKey: string;
  deploymentType?: DeploymentType;
}

export type ClusterFilterMap = Record<ClusterFilterKey, ClusterBuilderFilterInterface>;
