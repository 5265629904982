import Backbone, { Collection, Model } from 'backbone';

import { DeploymentItem } from '@packages/types/nds/deployment';
import { LiveImportModel } from '@packages/types/nds/liveImport';
import { BackingCloudProvider, CloudProvider, InstanceSize } from '@packages/types/nds/provider';
import { RegionName } from '@packages/types/nds/region';
import { ReplicationSpecList } from '@packages/types/nds/replicationSpec';
import { ServerlessBackupOptions } from '@packages/types/nds/serverlessBackupOptions';
import { Tag } from '@packages/types/resourceTagging';
import { SearchDeployment } from '@packages/types/search/decoupled/deployment';

export enum ClusterType {
  REPLICASET = 'REPLICASET',
  SHARDED = 'SHARDED',
  GEOSHARDED = 'GEOSHARDED',
}

export enum State {
  SUCCESS = 'SUCCESS',
  IDLE = 'IDLE',
  CREATING = 'CREATING',
  UPDATING = 'UPDATING',
  DELETING = 'DELETING',
  DELETED = 'DELETED',
  REPAIRING = 'REPAIRING',
}

export enum InternalClusterRole {
  NONE = 'NONE',
  SHARED_MTM = 'SHARED_MTM',
  SERVERLESS_MTM = 'SERVERLESS_MTM',
  USS_MTM = 'USS_MTM',
  SERVERLESS_SENTINEL = 'SERVERLESS_SENTINEL',
}

export enum EncryptionAtRestProvider {
  NONE = 'NONE',
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
}

export const EncryptionAtRestProviderLabel = {
  [EncryptionAtRestProvider.AWS]: 'AWS KMS',
  [EncryptionAtRestProvider.AZURE]: 'Azure Key Vault',
  [EncryptionAtRestProvider.GCP]: 'Google Cloud KMS',
};

export enum RootCertType {
  DST = 'DST',
  ISRGROOTX1 = 'ISRGROOTX1',
}

export const ROOT_CERT_TYPES = [
  {
    label: 'DST Root CA X3 (Expires 9/21)',
    value: RootCertType.DST,
  },
  {
    label: 'ISRG Root X1',
    value: RootCertType.ISRGROOTX1,
  },
];

export enum VersionReleaseSystem {
  LTS = 'LTS',
  CONTINUOUS = 'CONTINUOUS',
}

export enum HostnameScheme {
  INTERNAL = 'INTERNAL',
  LEGACY = 'LEGACY',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  MESH = 'MESH',
}

export enum BiConnectorReadPreference {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ANALYTICS = 'analytics',
}

export enum DiskWarmingMode {
  FULLY_WARMED = 'FULLY_WARMED',
  VISIBLE_EARLIER = 'VISIBLE_EARLIER',
}

export enum ReplicaSetScalingStrategy {
  SEQUENTIAL = 'SEQUENTIAL',
  WORKLOAD_TYPE = 'WORKLOAD_TYPE',
  NODE_TYPE = 'NODE_TYPE',
}

export enum ConfigServerType {
  DEDICATED = 'DEDICATED',
  EMBEDDED = 'EMBEDDED',
}

export enum ConfigServerManagementMode {
  ATLAS_MANAGED = 'ATLAS_MANAGED',
  FIXED_TO_DEDICATED = 'FIXED_TO_DEDICATED',
  FIXED_TO_EMBEDDED = 'FIXED_TO_EMBEDDED',
}

export interface BiConnector {
  enabled: boolean;
  readPreference: BiConnectorReadPreference;
  publicHostname?: string;
  privateHostname?: string;
  privateLinkHostnamesMap?: Record<string, string>;
}

export interface ManagedNamespace {
  db: string;
  collection: string;
  customShardKey: string;
  isUniqueIndex?: boolean;
  isHashedIndex?: boolean;
  presplitHashedZones?: boolean;
  numInitialChunks?: number;
}

export interface GeoSharding {
  customZoneMapping: Record<string, string>;
  managedNamespaces: Array<ManagedNamespace>;
  selfManagedSharding: boolean;
}

export interface NDSLabel {
  key: string;
  value: string;
}

export interface ResurrectOptions {
  clusterUniqueId: string;
}

export interface ClusterDescription {
  name: string;
  groupId?: string;
  uniqueId?: string;
  createDate?: string;
  groupName?: string;
  mongoDBVersion: string;
  mongoDBMajorVersion: string;
  fixedMongoDBFCV?: string;
  fixedMongoDBFCVExpiration?: string;
  fixedMongoDBFCVPinnedDate?: string;
  clusterType: ClusterType;
  replicationSpecList: ReplicationSpecList;
  diskSizeGB: number;
  diskSizeGBLimit?: number;
  mongoDBUriHosts: Array<string>;
  privateMongoDBUriHosts?: Array<string>;
  privateSrvAddress?: string;
  privateLinkMongoDBUriHosts?: Record<string, Array<string>>;
  privateLinkSrvAddresses?: Record<string, string>;
  mongoDBUriHostsLastUpdateDate: string;
  backupEnabled: boolean;
  diskBackupEnabled: boolean;
  pitEnabled: boolean;
  state: State;
  deleteAfterDate?: string;
  needsMongoDBConfigPublishAfter?: string;
  tenantUpgrading?: boolean;
  tenantAccessRevokedForPause?: boolean;
  tenantBackupEnabled?: boolean;
  serverlessBackupOptions?: ServerlessBackupOptions;
  isPaused: boolean;
  isUnderCompaction: boolean;
  biConnector: BiConnector;
  lastUpdateDate?: string;
  srvAddress?: string;
  clusterTags: Array<string>;
  geoSharding: GeoSharding;
  encryptionAtRestProvider: EncryptionAtRestProvider;
  isNvme: boolean;
  internalClusterRole?: InternalClusterRole;
  labels: Array<NDSLabel>;
  isMonitoringPaused?: boolean;
  deploymentItemName?: string;
  deploymentClusterName?: string;
  forceReplicaSetReconfig?: boolean;
  resurrectRequested?: string;
  resurrectOptions?: ResurrectOptions;
  hostnameSubdomainLevel?: string;
  hostnameSchemeForAgents?: HostnameScheme;
  isCrossCloudCluster?: boolean;
  rootCertType: RootCertType;
  versionReleaseSystem: VersionReleaseSystem;
  endpointToLoadBalancedSRVConnectionURI?: Record<string, string>;
  isFastProvisioned: boolean;
  diskWarmingMode?: DiskWarmingMode;
  replicaSetScalingStrategy?: ReplicaSetScalingStrategy;
  isMongoDBVersionFixed: boolean;
  continuousDeliveryFCV?: string;
  '@provider'?: CloudProvider;
  terminationProtectionEnabled: boolean;
  encryptionAtRestValid?: boolean;
  instanceSize?: InstanceSize;
  numShards?: number;
  retainBackupsForDeleting?: boolean;
  needsSampleDataLoadAfter?: string;
  sampleDatasetToLoad?: string;
  configServerType?: ConfigServerType;
  configServerManagementMode?: ConfigServerManagementMode;
  employeeAccessGrant?: Record<string, string>;
  redactClientLogData?: boolean;
}

// Sparsely-typed Backbone models

export interface ClusterDescriptionModel extends Model<ClusterDescription> {
  id: string;

  set: <Key extends keyof ClusterDescription & string>(key: Key, value: ClusterDescription[Key]) => this;
  get: <Key extends keyof ClusterDescription & string>(key: Key) => ClusterDescription[Key];
  getAttributes: () => ClusterDescription;
  toJSON: () => ClusterDescription;
  clone: () => Model & ClusterDescriptionModel;

  getAnalyticsInstanceSize: () => InstanceSize;
  getCommandLineToolsUrl: (shouldShowRevampedCliToolsPage?: boolean) => string;
  getDataProcessingRegion: () => string;
  hasDataProcessingRegion: () => boolean;
  getDeploymentItemName: () => string;
  getEffectiveProviderFromReplicationSpecList: () => string;
  getEncryptionAtRestProviderReadable: () => string;
  getGrantedEmployeeAccessTimeRemainingMs: () => number;
  getName: () => string;
  getGroupId: () => string;
  getLastUpdateDate: () => string;
  getMongoDBMajorVersion: () => number;
  getManagedNamespacesAsMap: () => any;
  getNumZones: () => number;
  getRemainingLifetime: () => { days?: number; hours?: number; minutes?: number } | null;
  getTotalNodes: () => number;
  getUniqueId: () => string;
  hasAnyBackupEnabled: () => boolean;
  has1HourResolutionData: () => boolean;
  isBIConnectorEnabled: () => boolean;
  isCrossCloudCluster: () => boolean;
  isDeleting: () => boolean;
  isHeadBackupEnabled: () => boolean;
  isDiskBackupEnabled: () => boolean;
  isExpiring: () => boolean;
  isFastProvisioned: () => boolean;
  isFree: () => boolean;
  isGeoShardedCluster: () => boolean;
  isAtlasManagedSharding: () => boolean;
  hasGeoShardedCollections: () => boolean;
  isIdle: () => boolean;
  isMonitoringPaused: () => boolean;
  isMultiRegion: () => boolean;
  isPaused: () => boolean;
  setIsPaused: (isPaused: boolean) => void;
  isUnderCompaction: () => boolean;
  isPitEnabled: () => boolean;
  isServerless: () => boolean;
  isFlex: () => boolean;
  isShardedCluster: () => boolean;
  isTenant: () => boolean;
  isTenantAccessRevokedForPause: () => boolean;
  isTenantUpgrading: () => boolean;
  isAutoIndexEnabled: () => boolean;
  isAutoScaleDiskEnabled: () => boolean;
  isNvme: () => boolean;
  supportsLiveImport: () => boolean;
  getInstanceSize: () => InstanceSize;
  getBaseInstanceSizes: () => Array<InstanceSize>;
  getAnalyticsInstanceSizes: () => Array<InstanceSize>;
  getProvider: () => CloudProvider;
  getEncryptionAtRestValid: () => boolean;
  getNumShards: () => number;
  getClusterType: () => string;
  getReplicationSpecList: () => ReplicationSpecList;
  getCreateDate: () => Date;
  getDeleteAfterDate: () => Date;
  isCreating: () => boolean;
  isUpdating: () => boolean;
  getServerlessRegionConfig: () => any;
  getPrivateLinkSRVAddresses: () => any;
  getEndpointToLoadBalancedSRVConnectionURI: () => any;
  getRetainBackupsForDeleting: () => boolean;
  hasAnalyticsNodes: () => boolean;
  getRegions: () => Array<string>;
  isDedicatedConfigServer: () => boolean;
  getEmployeeAccessGrant: () => any;
  isDecomposedShardedCluster: () => boolean;
  isAnalyticsAsymmetric: () => boolean;
  getFixedMongoDBFCV: () => string;
  setFixedMongoDBFCV: (fixedMongoDBFCV: string) => void;
  getFixedMongoDBFCVExpiration: () => string;
  getVersionReleaseSystem: () => string;
  getFixedMongoDBFCVPinnedDate: () => string;
}

interface ClusterDetailAttributes {
  clusterDescription: ClusterDescriptionModel | null;
  searchDeployment: SearchDeployment | null;
  deploymentItem: DeploymentItem | null;
  openAlerts: Model;
  capacityWarnings: Model;
  liveImport: Model;
}

export interface ClusterDetailModel extends Model<ClusterDetailAttributes> {
  getClusterDescription: () => ClusterDescriptionModel;
  getDeploymentItem: () => DeploymentItem;
  getLiveImport: () => LiveImportModel;
  updateWithLiveImport: (imports: Backbone.Collection) => void;
  getSearchDeployment: () => SearchDeployment;
  setSearchDeployment: (searchDeployment: SearchDeployment) => void;
  getDerivedBiConnectorState: () => string;
  getOpenAlerts: () => Backbone.Collection;
  updateWithOpenAlerts: (alerts: Backbone.Collection) => void;
  setDeploymentItem: (item: DeploymentItem) => void;
}

export interface ClusterDescriptionCollection extends Array<ClusterDescriptionModel> {
  first: () => ClusterDescriptionModel | undefined;
  toJSON: () => Array<ClusterDescription>;
  any: (predicate: (cd: ClusterDescriptionModel) => boolean) => boolean;
}

export interface ClusterDetailCollection extends Collection<ClusterDetailModel> {
  getClusterDescriptions: () => ClusterDescriptionCollection;
  getClusterDetailByDeploymentItemId: (deploymentItemId: string) => ClusterDetailModel | null;
  updateWithClusterDescriptions: (clusterDescriptions: ClusterDescriptionCollection) => void;
  updateWithClusterDescription: (clusterDesciption: ClusterDescriptionModel) => void;
  updateWithSearchDeployments: (deployments: Array<SearchDeployment>) => void;
  updateWithOpenAlerts: (openAlerts: Backbone.Collection) => void;
  updateWithCapacityWarnings: (capacityWarnings: Backbone.Collection) => void;
  updateWithCurrentDeployment: (deployment: $TSFixMe) => void;
  updateWithLiveImports: (liveImports: $TSFixMe) => void;
  updateWithEncryptionAtRest: (encryptionAtRest: $TSFixMe) => void;
}

export interface ClusterTemplateParamsView {
  clusterName: string;
  automateSecurity: boolean;
  loadSampleDataset: boolean;
  tags: Array<Tag>;
  cloudProvider: BackingCloudProvider;
  regionKey: RegionName;
}

export interface ConfigServerTypeEstimate {
  configServerType: ConfigServerType | null;
  containsTimeSeries: boolean;
  containsQueryableEncryption: boolean;
}
